





















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import { MapSettings, Global } from '@/store';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import cloneDeep from 'lodash/cloneDeep';

@Component({
    components: {
        ConfirmDialog
    }
})

export default class UpdateFilterView extends Vue {
    @Prop() filter;
    @Prop() project;
    @Prop() name;
    @Prop() validateFilterName;

    @MapSettings.Action('updateMapFilter') updateMapFilter;
    @MapSettings.State('userMapFilters') userMapFilters;
    @Global.Mutation('setEditFilterMode') setEditFilterMode;

    updateDialog = false;

    updateView() {
        this.updateDialog = false;
       
        const filter = cloneDeep(this.filter);
        filter.name = this.name;
        if (!filter.groups.includes('all')){
            filter.circuit = [];
            filter.phase = [];
        }
        this.updateMapFilter({id: this.project.id, filter});
        this.setEditFilterMode(false);
        this.$emit('finish');
    }
}
