import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import Vue from 'vue';

import vuex from '..';
import Utils from '../../modules/Utils';
import API, { Types } from '../../modules/API';

export enum Type {
  NUMERIC = 'numeric'
}

export interface StreamHistoryItem {
  date: Date;
  value: string;
}

export interface Stream {
  objectType: Types;
  objectID: string;
  id: string;
  name: string;
  type: string;
  streamtype: string;
  triggers: any[];
  updated: any;
  value: any;
  history: {
    items: StreamHistoryItem[];
    prev: StreamHistoryItem | null;
  };
}

@Module({ namespaced: true })
export default class DeviceStrems extends VuexModule {
  list: Stream[] = [];
  faultStreams = [];
  deviceStreams = [];
  @Action load({ objectID, objectType }) {
    if (!objectID) return;
    if (!objectType) objectType = Types.DEVICES;

    return API.get(
      objectType,
      'streams',
      {
        fields: 'id,name,value,updated'
      },
      { itemID: objectID }
    ).then((res) => {
      if (res && res.results)
        res.results.forEach((stream) => {
          vuex.commit('DeviceStreams/add', {
            ...stream,
            objectType,
            objectID,
            type: Type.NUMERIC
          });

          vuex.commit('DeviceStreams/addHistory', {
            streamID: stream.id,
            item: {
              date: stream.updated ? new Date(stream.updated) : null,
              value: stream.value
            }
          });
        });
    });
  }

  @Action
  async loadDeviceStream(data) {
    const res = await API.get(Types.DEVICES, data.deviceId + '/streams/', {});
    const stream = res.results.find((s) => s.name === 'fault');
    if (stream) {
      stream.device_id = data.deviceId;
      vuex.commit('DeviceStreams/setFaultStream', stream);
      vuex.dispatch('DeviceStreams/loadHistory', {
        streamID: stream.id,
        objectType: Types.PROJECTS,
        objectID: data.projectId,
        period: data.period
      });
    }
  }
  @Action
  async fetchDeviceStream({ device, streams }) {
    try {
      const data = [];
      for (const val of streams) {
        const response = await API.get(
          Types.DEVICES,
          `${device.id}/streams/${val.id}/history/`,
          { since: '1months' },
          {}
        );
        data.push({
          history: response,
          ...val
        });
      }
      vuex.commit('DeviceStreams/setDeviceStreams', data);
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  async fetchAllStreamsForSingleDevice(id) {
    await API.get('devices', id + '/streams/', {}).then((res) => {
      if (res.results) {
        vuex.commit('DeviceStreams/setDeviceStreams', res.results);
      }
    });
  }

  @Action
  clearFaultStreams() {
    vuex.commit('DeviceStreams/setFaultStream', []);
  }

  @Mutation
  setDeviceStreams(data) {
    this.deviceStreams = [];
    if (data.length > 0) {
      this.deviceStreams = data;
    }
  }
  @Mutation
  setFaultStream(data) {
    this.faultStreams.push(data);
  }

  @Mutation
  setDeviceStreamValue(data) {
    const stream = this.deviceStreams.find(
      (stream) => stream.name === data.field
    );
    if (stream) stream.value = data.value;
  }

  @Action
  async loadHistory({ streamID, objectID, objectType, period, noend }) {
    if (!streamID || !objectID) return;
    if (!objectType) objectType = Types.DEVICES;

    let data = { since: '1days' } as any;
    if (period && period.start && period.end) {
      data = {
        from: period.start.getTime(),
        to: noend ? new Date().getTime() : period.end.getTime()
      };
    }

    const res = await API.get(objectType, `streams/${streamID}/history`, data, {
      itemID: objectID,
      dataMethod: 'formData'
    });
  }

  @Action editStream({ streamID, objectID, objectType, value }) {
    if (!streamID || !objectID) return;
    if (!objectType) objectType = Types.DEVICES;

    return API.put(
      objectType,
      `streams/${streamID}`,
      { content: value },
      { itemID: objectID }
    ).then(() => ({
      objectType,
      objectID,
      streamID,
      item: {
        date: new Date(),
        value
      }
    }));
  }

  @Mutation flush(data) {
    if (data && data.streamID) {
      const index = Utils.find(this.list, data.streamID);
      if (index !== -1) delete this.list[index];
    } else this.list = [];
  }

  @Mutation add(data: Stream) {
    if (!data || !data.id || Utils.find(this.list, data.id) !== -1) return;

    data.updated = data.updated ? new Date(data.updated) : null;
    data.history = { items: [], prev: null };
    this.list.push(data);
  }

  @Mutation saveHistory(data) {
    if (!data.history || !data.history.length) return;

    const index = Utils.find(this.list, data.streamID);
    if (index === -1)
      return setTimeout(
        () => vuex.commit('DeviceStreams/saveHistory', data),
        100
      );
    this.list[index].history = {} as any;

    this.list[index].history.prev = {
      date: this.list[index].updated,
      value: this.list[index].value
    };
    this.list[index].history.items = data.history;
    this.list[index].value = !data.history.length
      ? 0
      : data.history[data.history.length - 1].value;
    this.list[index].updated = !data.history.length
      ? new Date()
      : data.history[data.history.length - 1].date;
  }

  @Mutation addHistory(data) {
    const index = Utils.find(this.list, data.streamID);
    if (!data.item || !this.list[index].history.items) return;

    this.list[index].history.prev = {
      date: this.list[index].updated,
      value: this.list[index].value
    };
    this.list[index].history.items.push(data.item);
    this.list[index].value = data.item.value;
    this.list[index].updated = data.item.date;
  }
  @Mutation clearStreams() {
    this.deviceStreams = [];
  }
}
