import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

import vuex from '..';
import API, { Types } from '../../modules/API';
import cloneDeep from 'lodash/cloneDeep';

@Module({ namespaced: true })
export default class Companies extends VuexModule {
    companies_list = {};
    selected_company = '';

    @Mutation
    setCompaniesList(list){
        this.companies_list = cloneDeep(list);
    }

    @Mutation
    setSelectedCompany(company_id){
        this.selected_company = company_id;
    }

    @Action
    async fetchCompanyLogo(company_id){
        const image = {
            url: '@/assets/images/tondo_icons/default_header_logo.svg',
            color: 'rgba(0, 0, 0, 0.05)'
        };
    
        const company_logo = await API.get(Types.COMPANIES, `${company_id}/properties/`, { name: 'meta.logo' });
    
        const meta_logo =
          company_logo &&
          company_logo.results &&
          company_logo.results[0].value &&
          company_logo.results[0].value
            ? company_logo.results[0].value
            : '';
    
        if (meta_logo['url']) image.url = meta_logo['url'];
        if (meta_logo['color']) image.color = meta_logo['color'];

        vuex.commit('Companies/setCompanyImage', {company_id, image: meta_logo});
    }
}