









































































import Component from 'vue-class-component';
import { Props, Global, Reports } from '@/store';
import BarChart from '@/pages/mapView/components/MapTabs/information/BarChart.js';
import TabsInfoMixin from '../../devices/TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import moment from 'moment';
import PropUtils from '@/modules/PropUtils';
import Utils from '@/modules/Utils';
import { i18n } from '@/main';
import * as sunCalc from 'suncalc';
import API , { Types } from '@/modules/API';

@Component({
    components: {
        BarChart
    }
})
export default class CurveTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {

    @Props.State('list') projectProperties;
    @Global.State('timezone') projectTimezone;
    @Global.State('lang') lang;
    @Global.State('dusk_dawn_data') dusk_dawn_data;
    @Reports.Getter('commissionedFixtures_map') commissionedFixtures_map;
    @Reports.Getter('commissionedCabinets') commissionedCabinets;

    turnOn = '';
    turnOff = '';
    start_index = 0;
    end_index = 0;
    today_curve = null;
    loading = true;
    offset = 0;
    startType = null;
    endType = null;
    minDuskIndex = null;
    minDawnIndex = null;
    maxDuskIndex = null;
    maxDawnIndex = null;
    dawnPowerLevel = 0;
    duskPowerLevel = 0;
    duskDawnWindowColor = '#DCDCDC';
    duskDawnChartColor = '#FFA07A';
    defaultChartColor = '#ffeb3b';
    borderColor = '#FFFFFF';
    datasets = [];
    datacollection = {
        labels: ['12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM',
        '12:00 AM', '12:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM', '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM']
    };
    options = {};
    default_curve = false;
    cabinet_schedule = false;
    is_astronomical = false;
    is_active = false;

    async mounted() {
        this.loading = true;
        const has_cabinet_schedule = await this.checkHasCabinetSchedule();
        if (has_cabinet_schedule){
            await this.updateTimeByCabinetSchedule();
        }else {
            this.today_curve = this.getTodayCurve();
            if (this.today_curve){
                this.setChartOptions();
                this.setTurnOnOff(this.today_curve['start']['offset'], this.today_curve['end']['offset'], 'h:mm A');
                [this.datasets, this.startType, this.endType] =  [this.today_curve['datasets'], this.today_curve['start']['type'], this.today_curve['end']['type']];
                this.offset = this.getTimezoneOffset();
                this.datasets = this.getDatasetByOffset(this.datasets, false);
                if (this.startType === 'dusk'){
                    [this.duskPowerLevel, this.dawnPowerLevel] = [+this.today_curve['start']['power'], +this.today_curve['end']['power']];
                    this.setStartEndCurve();
                    this.setDuskDawnPowers(this.datasets);
                }
                this.chartInitialization();
            }
        }
       
        this.loading = false;
    }

    async checkHasCabinetSchedule(){
        let device_schedule = await this.fetchSingleProperty(this.device.id, 'device.schedule');
        device_schedule = device_schedule && device_schedule.value || {};
        return Object.entries(device_schedule).length &&
                device_schedule.hasOwnProperty('cabinet_schedule') &&
                device_schedule.cabinet_schedule
            ? true
            : false;
    }

    async updateTimeByCabinetSchedule(){
        const cabinet_id = this.commissionedFixtures_map.get(this.device.id)['meta.device'].cabinet_id;
        if (cabinet_id){
            let linked_cabinet = this.commissionedCabinets.filter((cabinet) => cabinet['meta.device'].cabinet_id === cabinet_id);
            if (linked_cabinet.length === 1){
                this.cabinet_schedule = true;
                linked_cabinet = linked_cabinet[0];
                let devices_schedule = await this.fetchSingleProperty(linked_cabinet['id'], 'meta.devices_schedule');
                devices_schedule = devices_schedule && devices_schedule.value || null;
                if (devices_schedule){
                    if (devices_schedule.active) this.is_active = true;
                    if (devices_schedule.is_astronomical){
                        this.is_astronomical = true;
                        this.setTurnOnOff(devices_schedule.dusk, devices_schedule.dawn, 'HH:mm');
                    }else {
                        this.turnOn = Utils.convertUtcTimeToTimezone(devices_schedule.on, this.projectTimezone, 'HH:mm');
                        this.turnOff = Utils.convertUtcTimeToTimezone(devices_schedule.off, this.projectTimezone, 'HH:mm');
                    }
                }
            }
        }
    }

    async fetchSingleProperty(device_id, property_name) {
        try {
            const response = await API.get(Types.DEVICES, `${device_id}/properties`, { name: property_name });
            return (response && response.results && response.results[0]) || null;
        } catch (e) {
            return null;
        }
    }

    setChartOptions(){
        this.options = {
            showLines: true,
            animation: false,
            dragData: false,
            scales: {
                xAxes: [{
                    ticks: {
                        autoSkip: false,
                        minRotation: 50,
                        fontSize: 11
                    }
                }],
                yAxes: [
                    {
                        ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 10
                        },
                        scaleLabel: {
                            display: true,
                            labelString: i18n.t('Dim Level')
                        }
                    }
                ]
            },
            offset: true,
            layout: {
                padding: 5
            },
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 0,
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem) => tooltipItem.yLabel
                }
            },
            dragDataRound: 0,
            onDragStart(e, element) {
                return false;
            },
            onClick(event) {
                return false;
            }
        };
    }

    setTurnOnOff(start_offset, end_offset, format){
        if (format.includes('A')) {
            this.turnOn = Utils.getAstronomicalTimeByMinutesOffset(start_offset, this.dusk_dawn_data.dusk_timestamp, this.dusk_dawn_data.dusk, this.projectTimezone, format);
            this.turnOff = Utils.getAstronomicalTimeByMinutesOffset(end_offset, this.dusk_dawn_data.dawn_timestamp, this.dusk_dawn_data.dawn, this.projectTimezone, format);
        }else {
            this.turnOn = Utils.getAstronomicalTime(start_offset, this.dusk_dawn_data.dusk_timestamp, this.dusk_dawn_data.dusk, this.projectTimezone, format);
            this.turnOff = Utils.getAstronomicalTime(end_offset, this.dusk_dawn_data.dawn_timestamp, this.dusk_dawn_data.dawn, this.projectTimezone, format);
        }
    }

    setStartEndCurve(){
        let [time, format] = this.splitString(this.turnOn, ' ');
        let [h, m] = this.splitString(time, ':');
        let minutes = +m < 30 ? '00' : '30';
        const start_hour = `${h}:${minutes} ${format}`;
        this.start_index = this.datacollection.labels.findIndex((hour) => hour === start_hour);

        [time, format] = this.splitString(this.turnOff, ' ');
        [h, m] = this.splitString(time, ':');
        minutes = +m < 30 ? '30' : '00';
        if (+m > 30){
            h = +h + 1;
            if (h === '11'){
                format = format === 'PM' ? 'AM' : 'PM';
            }
        }
        const end_hour = `${h}:${minutes} ${format}`;
        this.end_index = this.datacollection.labels.findIndex((hour) => hour === end_hour);
    }

    splitString(str, c){
        return str.split(c);
    }

    getTodayCurve(){
        const today = this.projectTimezone 
            ? moment().tz(this.projectTimezone).format('DD.MM.YYYY')
            : moment().format('DD.MM.YYYY');

        const schedule_curves = PropUtils.parseProperty('meta.schedule_curve', this.device);
        const today_curve = schedule_curves.curves && schedule_curves.curves[today];

        const curve_id = today_curve && today_curve.curve_id;
        if (curve_id && curve_id.includes('default')) {
            this.default_curve = true;
            return false;
        }
        let curves_property = curve_id && PropUtils.getProperty(this.projectProperties, 'dashboard.curves');
        curves_property = curves_property ? curves_property.value : null;
        const curve_data = Array.isArray(curves_property) && curves_property.filter((curve) => curve.id === curve_id);
        return curve_data && curve_data.length ? curve_data[0] : false; 
    }

    getTimezoneOffset() {
        try {
            const new_offset = moment().tz(this.projectTimezone).utcOffset();
            const remain = Math.abs(new_offset) % 60;
            let roundedRemain = remain >= 16 && remain <= 45 ? 0.5 : 0; 
            roundedRemain = new_offset < 0 ? -roundedRemain : roundedRemain;
            let offsetInHours = new_offset < 0 ? Math.ceil(new_offset / 60) : Math.floor(new_offset / 60);
            offsetInHours = remain >= 46 && remain <= 59 ? offsetInHours > 0 ? offsetInHours + 1 : offsetInHours - 1 : offsetInHours;
            return offsetInHours + roundedRemain;
        } catch (error) {
            console.log('error, while calculate offset', error);
            return 0;
        }
    }

    getDatasetByOffset(datasets, toUTC = false) {
        let moveToLeft = this.offset < 0;
        const steps = Math.abs(this.offset) * 2; // need to move 2 steps for getting one hour
        if (toUTC) moveToLeft = !moveToLeft;
        return this.moveDatasetTo(datasets, steps, moveToLeft);
    }

    moveDatasetTo(datasets, offset, moveToLeft) {
        return moveToLeft 
        ? this.moveToLeft(datasets, offset) 
        : this.moveToRight(datasets, offset);
    }

    moveToLeft(datasets, offset){
        return datasets.slice(offset).concat(datasets.slice(0, offset));
    }

    moveToRight(datasets, offset){
        const start = datasets.length - offset;
        return datasets.slice(start, datasets.length).concat(datasets.slice(0, start));
    }

    setDuskDawnPowers(datasets){
        let [start, end] = this.getStartEndIndex(datasets, -1);
        this.setPowers(this.datasets, 0, start, this.start_index);
        this.setPowers(this.datasets, this.duskPowerLevel, this.start_index, end + 1);

        [start, end] = this.getStartEndIndex(datasets, -2);
        this.setPowers(this.datasets, this.dawnPowerLevel, start, this.end_index);
        this.setPowers(this.datasets, 0, this.end_index, end);
    }

    getStartEndIndex(datasets, value){
        return [datasets.indexOf(value), datasets.lastIndexOf(value)];
    }

    setPowers(datasets, value, start, end){
        for (let i = start; i < end; i++)
            datasets[i] = value;
    }

    chartInitialization(){
        const color = this.startType === 'dusk' ? this.duskDawnChartColor : this.defaultChartColor;
        this.datacollection['datasets'] = [{
          data: [...this.datasets],
          backgroundColor: Array(48).fill(color),
          borderColor: Array(48).fill(this.borderColor),
          borderWidth: 0.2,
          categoryPercentage: 1.0,
          barPercentage: 1.0
        }];
    }
}
