export default {
  // TOKEN_GOOGLE: 'AIzaSyDBFQMWKZCIaCLget5nFcoHL5fjfl0WyU0',
  TOKEN_GOOGLE: 'AIzaSyAvSh7adPw6XbdBh1P32OjSNL5b5ldLci4',
  TOKEN_MAPBOX:
    'pk.eyJ1IjoibmlyZnIiLCJhIjoiY2pwMTBydjNtMmplazNrczNpYjYya3N0eSJ9.-TtAX7FNNq5bQMwnF8OduA',
  markerSize: 18,
  icons: {
    defaultMarker: require('@/assets/images/mapView_icons/default.png'),
    measle: require('@/assets/images/measle.png'),
    MarkerDefault: require('@/assets/images/mapView_icons/oval-lightgrey.svg'),
    MarkerPurple: require('@/assets/images/mapView_icons/oval-purple.svg'),
    radarOk: require('@/assets/images/mapView_icons/radar/radar_ok.png'),
    greyRedLine: require('@/assets/images/mapView_icons/grey-red-line.svg'),
    yellowRedLine: require('@/assets/images/mapView_icons/yellow-red-line.svg'),
    virtualCabinet: require('@/assets/images/mapView_icons/virtual_cabinet/virtual_cabinet.png'),
    regularCabinetOn: require('@/assets/images/mapView_icons/cabinet/cabinet_on.png'),
    regularCabinetOff: require('@/assets/images/mapView_icons/cabinet/cabinet_off.png'),
    regularCabinetFault: require('@/assets/images/mapView_icons/cabinet/cabinet_fault.png'),
    cabinetInInstallation: require('@/assets/images/mapView_icons/cabinet/cabinet_in_installation.png'),
    assetFault: require('@/assets/images/mapView_icons/asset_fault.svg'),
    assetOkOff: require('@/assets/images/mapView_icons/asset_ok_off.svg'),
    assetOkOn: require('@/assets/images/mapView_icons/asset_ok_on.svg'),
    weatherStation: require('@/assets/images/mapView_icons/weather_station/weather_station.png'),
    floodSensor: require('@/assets/images/mapView_icons/flood_sensor/flood_sensor.png'),
    floodSensorAlert: require('@/assets/images/mapView_icons/flood_sensor/flood_sensor_alert.png'),
    topSensorAlert: require('@/assets/images/mapView_icons/flood_sensor/top_sensor_alert.png'),
    bottomSensorAlert: require('@/assets/images/mapView_icons/flood_sensor/bottom_sensor_alert.png'),
    openBarrier: require('@/assets/images/mapView_icons/flood_sensor/open_barrier.png'),
    closedBarrier: require('@/assets/images/mapView_icons/flood_sensor/closed_barrier.png'),
    alertBarrier: require('@/assets/images/mapView_icons/flood_sensor/barrier_gif.gif'),
    sensorOk: require('@/assets/images/mapView_icons/sensor/sensor_ok.png'),
    cameraOk: require('@/assets/images/mapView_icons/camera/camera_ok.png'),
    cameraFault: require('@/assets/images/mapView_icons/camera/camera_fault.png'),
    purpleCluster: require('@/assets/images/cluster_icons/purple-square-rounded-24.png'),
    blueCluster: require('@/assets/images/cluster_icons/blue-square-rounded-24.png'),
    orangeCluster: require('@/assets/images/cluster_icons/orange-square-rounded-24.png'),
    pinkCluster: require('@/assets/images/cluster_icons/pink-square-rounded-24.png'),
    greenCluster: require('@/assets/images/cluster_icons/green-square-rounded-24.png'),
    redCluster: require('@/assets/images/cluster_icons/red-square-rounded-24.png'),
    lightblueCluster: require('@/assets/images/cluster_icons/lightblue-square-rounded-24.png'),
    yellowCluster: require('@/assets/images/cluster_icons/yellow-square-rounded-24.png'),
    greyCluster: require('@/assets/images/cluster_icons/grey-square-rounded-24.png'),
    darkGreenCluster: require('@/assets/images/cluster_icons/darkgreen-square-rounded-24.png'),
    lightOrangeCluster: require('@/assets/images/cluster_icons/lightorange-square-rounded-24.png'),
    lightPurpleCluster: require('@/assets/images/cluster_icons/lightpurple-square-rounded-24.png'),
    lightRedCluster: require('@/assets/images/cluster_icons/lightred-square-rounded-24.png'),
    turquoiseCluster: require('@/assets/images/cluster_icons/turquoise-square-rounded-24.png'),
    lightPinkCluster: require('@/assets/images/cluster_icons/lightpink-square-rounded-24.png'),
    motorcycle: require('@/assets/images/radar_tabs/motorcycle.png'),
    smartCarIcon: require('@/assets/images/radar_tabs/smart-car-icon.png'),
    carIcon: require('@/assets/images/radar_tabs/car-icon.png'),
    vanIcon: require('@/assets/images/radar_tabs/van-icon.png'),
    trialerTruckIcon: require('@/assets/images/radar_tabs/trailer-truck-icon.png'),
    regularInMaintenance: require('@/assets/images/mapView_icons/regular_tondo/regular_in_maintenance.png'),
    selected_regularInMaintenance: require('@/assets/images/mapView_icons/regular_tondo/selected_regular_in_maintenance.png'),
    regularInInstallation: require('@/assets/images/mapView_icons/regular_tondo/regular_in_installation.png'),
    selected_regularInInstallation: require('@/assets/images/mapView_icons/regular_tondo/selected_regular_in_installation.png'),
    regularInCare: require('@/assets/images/mapView_icons/regular_tondo/regular_fault_in_care.png'),
    selected_regularInCare: require('@/assets/images/mapView_icons/regular_tondo/selected_regular_fault_in_care.png'),
    regularOn: require('@/assets/images/mapView_icons/regular_tondo/regular_on.png'),
    virtualOn: require('@/assets/images/mapView_icons/virtual_tondo/virtual_on.png'),
    selected_regularOn: require('@/assets/images/mapView_icons/regular_tondo/selected_regular_on.png'),
    selected_virtualOn: require('@/assets/images/mapView_icons/virtual_tondo/selected_virtual_on.png'),
    regularOff: require('@/assets/images/mapView_icons/regular_tondo/regular_off.png'),
    virtualOff: require('@/assets/images/mapView_icons/virtual_tondo/virtual_off.png'),
    selected_regularOff: require('@/assets/images/mapView_icons/regular_tondo/selected_regular_off.png'),
    selected_virtualOff: require('@/assets/images/mapView_icons/virtual_tondo/selected_virtual_off.png'),
    securityOn: require('@/assets/images/mapView_icons/security/security_on.png'),
    selected_securityOn: require('@/assets/images/mapView_icons/security/selected_security_on.png'),
    securityOff: require('@/assets/images/mapView_icons/security/security_off.png'),
    selected_securityOff: require('@/assets/images/mapView_icons/security/selected_security_off.png'),
    undergroundOn: require('@/assets/images/mapView_icons/underground/underground_on.png'),
    selected_undergroundOn: require('@/assets/images/mapView_icons/underground/selected_underground_on.png'),
    undergroundOff: require('@/assets/images/mapView_icons/underground/underground_off.png'),
    selected_undergroundOff: require('@/assets/images/mapView_icons/underground/selected_underground_off.png'),
    highmastOn: require('@/assets/images/mapView_icons/highmast/highmast_on.png'),
    selected_highmastOn: require('@/assets/images/mapView_icons/highmast/selected_highmast_on.png'),
    highmastOff: require('@/assets/images/mapView_icons/highmast/highmast_off.png'),
    selected_highmastOff: require('@/assets/images/mapView_icons/highmast/selected_highmast_off.png'),
    regularCommFaultOn: require('@/assets/images/mapView_icons/regular_tondo/regular_comm_fault_on.png'),
    selected_regularCommFaultOn: require('@/assets/images/mapView_icons/regular_tondo/selected_regular_comm_fault_on.png'),
    regularCommFaultOff: require('@/assets/images/mapView_icons/regular_tondo/regular_comm_fault_off.png'),
    selected_regularCommFaultOff: require('@/assets/images/mapView_icons/regular_tondo/selected_regular_comm_fault_off.png'),
    securityCommFaultOn: require('@/assets/images/mapView_icons/security/security_comm_fault_on.png'),
    selected_securityCommFaultOn: require('@/assets/images/mapView_icons/security/selected_security_comm_fault_on.png'),
    securityCommFaultOff: require('@/assets/images/mapView_icons/security/security_comm_fault_off.png'),
    selected_securityCommFaultOff: require('@/assets/images/mapView_icons/security/selected_security_comm_fault_off.png'),
    undergroundCommFaultOn: require('@/assets/images/mapView_icons/underground/underground_comm_fault_on.png'),
    selected_undergroundCommFaultOn: require('@/assets/images/mapView_icons/underground/selected_underground_comm_fault_on.png'),
    undergroundCommFaultOff: require('@/assets/images/mapView_icons/underground/underground_comm_fault_off.png'),
    selected_undergroundCommFaultOff: require('@/assets/images/mapView_icons/underground/selected_underground_comm_fault_off.png'),
    highmastCommFaultOn: require('@/assets/images/mapView_icons/highmast/highmast_comm_fault_on.png'),
    selected_highmastCommFaultOn: require('@/assets/images/mapView_icons/highmast/selected_highmast_comm_fault_on.png'),
    highmastCommFaultOff: require('@/assets/images/mapView_icons/highmast/highmast_comm_fault_off.png'),
    selected_highmastCommFaultOff: require('@/assets/images/mapView_icons/highmast/selected_highmast_comm_fault_off.png'),
    regularBurntFault: require('@/assets/images/mapView_icons/regular_tondo/regular_burnt_fault.png'),
    selected_regularBurntFault: require('@/assets/images/mapView_icons/regular_tondo/selected_regular_burnt_fault.png'),
    securityBurntFault: require('@/assets/images/mapView_icons/security/security_burnt_fault.png'),
    selected_securityBurntFault: require('@/assets/images/mapView_icons/security/selected_security_burnt_fault.png'),
    undergroundBurntFault: require('@/assets/images/mapView_icons/underground/underground_burnt_fault.png'),
    selected_undergroundBurntFault: require('@/assets/images/mapView_icons/underground/selected_underground_burnt_fault.png'),
    highmastBurntFault: require('@/assets/images/mapView_icons/highmast/highmast_burnt_fault.png'),
    selected_highmastBurntFault: require('@/assets/images/mapView_icons/highmast/selected_highmast_burnt_fault.png'),
    regularVoltageFault: require('@/assets/images/mapView_icons/regular_tondo/regular_voltage_fault.png'),
    selected_regularVoltageFault: require('@/assets/images/mapView_icons/regular_tondo/selected_regular_voltage_fault.png'),
    regularElectricalFault: require('@/assets/images/mapView_icons/regular_tondo/regular_electrical_fault.png'),
    virtualElectricalFault: require('@/assets/images/mapView_icons/virtual_tondo/virtual_electrical_fault.png'),
    selected_regularElectricalFault: require('@/assets/images/mapView_icons/regular_tondo/selected_regular_electrical_fault.png'),
    securityVoltageFault: require('@/assets/images/mapView_icons/security/security_voltage_fault.png'),
    selected_securityVoltageFault: require('@/assets/images/mapView_icons/security/selected_security_voltage_fault.png'),
    securityElectricalFault: require('@/assets/images/mapView_icons/security/security_electrical_fault.png'),
    selected_securityElectricalFault: require('@/assets/images/mapView_icons/security/selected_security_electrical_fault.png'),
    undergroundVoltageFault: require('@/assets/images/mapView_icons/underground/underground_voltage_fault.png'),
    selected_undergroundVoltageFault: require('@/assets/images/mapView_icons/underground/selected_underground_voltage_fault.png'),
    undergroundElectricalFault: require('@/assets/images/mapView_icons/underground/underground_electrical_fault.png'),
    selected_undergroundElectricalFault: require('@/assets/images/mapView_icons/underground/selected_underground_electrical_fault.png'),
    highmastVoltageFault: require('@/assets/images/mapView_icons/highmast/highmast_voltage_fault.png'),
    selected_highmastVoltageFault: require('@/assets/images/mapView_icons/highmast/selected_highmast_voltage_fault.png'),
    highmastElectricalFault: require('@/assets/images/mapView_icons/highmast/highmast_electrical_fault.png'),
    selected_highmastElectricalFault: require('@/assets/images/mapView_icons/highmast/selected_highmast_electrical_fault.png'),
    waterMeter: require('@/assets/images/mapView_icons/water_meter/water_meter.png'),
    waterMeterFault: require('@/assets/images/mapView_icons/water_meter/water_meter_fault.png'),
    astronomicalClock: require('@/assets/images/mapView_icons/astro_on.svg'),
    noAstronomicalClock: require('@/assets/images/mapView_icons/astro_off.svg'),
    container: require('@/assets/images/mapView_icons/container/container.svg'),
    gpsCircle: require('@/assets/images/mapView_icons/green_circle.png'),
    gisCircle: require('@/assets/images/mapView_icons/red_circle.png'),
    appCircle: require('@/assets/images/mapView_icons/purple_circle.png')
  }
};
