







































import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import TabsInfoMixin from './TabsInfoMixin';
import { Global, Props, DeviceStreams } from '@/store';
import PropUtils from '@/modules/PropUtils';
import MotionTab from '../information/sensorTabs/MotionTab.vue';
import OverviewTab from '../information/sensorTabs/OverviewTab.vue';

@Component({
  components: {
      OverviewTab,
      MotionTab
  }
})

export default class SensorInfoTabs extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('lang') lang;
  @DeviceStreams.Mutation('setDeviceStreams') setDeviceStreams;
  @DeviceStreams.State('deviceStreams') deviceStreams;
  @DeviceStreams.Action('fetchAllStreamsForSingleDevice') fetchAllStreamsForSingleDevice;

  metaDevice = {};
  tab = 'tab-overview';
  loadingTabs = true;

  mounted(){
      this.deviceChanged();
  }

  setTabItem(tabIndex) {
    this.tab = tabIndex;
  }

  @Watch('device')
  deviceChanged() {
      this.loadingTabs = true;
      this.active = 'tab-overview';
      this.tab = 'tab-overview';
      this.clearData();
      this.metaDevice = this.device['meta.device'];
      this.loadingTabs = false;
  }

  clearData() {
    this.metaDevice = {};
  }

  get title(){
      return this.category ? `${this.$t(this.category)}: ${this.device.name}` : this.device.name;
  }
}
