<template>
    <v-dialog v-model="loading" persistent max-width="400" no-click-animation>
        <v-card class="rounded-card" style="background-color: white !important">
            <v-card-text class="title">
                <span class="title font-weight-bold">{{ message ? $t(message) : $t('Please stand by') }}...</span>
                <v-progress-linear indeterminate :query="lang !== 'en'" color="#292F7D" class="mb-0"></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({})
export default class LoadingDialog extends Vue{
    @Global.State('lang') lang;


    @Prop() loading;
    @Prop() message;
}

</script>

<style scoped>
    .title{
        font-family: 'Open Sans', 'Arial', monospace !important;
    }
</style>