<template>
    <v-dialog persistent v-model="dialog" max-width="500px">
      <v-card style="background-color: white !important">
        <v-card-title>
          <h2>{{ $t(message) }}{{colon ? ':' : '.'}}</h2>
          <h2 v-if="info && typeof info === 'string'">{{ $t(info) }}.</h2>
          <v-layout column v-else-if="info && Array.isArray(info)" class="mt-3">
            <h2  v-for="(item, index) in info" :key="index" :style="colon ? 'color: #812E81;' : ''">{{ `${index + 1}. ${$t(item)}` }}</h2>
          </v-layout>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="handleCloseClicked">{{ $t('CLOSE') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class MessageDialog extends Vue{
    @Prop() dialog;
    @Prop() message;
    @Prop() info;
    @Prop() colon;
    @Prop() list;

    handleCloseClicked(){
      this.$emit('dialogClosed');
    }
}

</script>