import './class-component-hooks';
import Vue from 'vue';
import 'core-js';
import './assets/stylesheets/app.styl';
import './assets/stylesheets/theme.styl';
import './assets/styles/main.scss';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import locales from '../locales/common';
import App from './App.vue';
import GeneralProblem from '@/views/GeneralProblem.vue';
import vuex from './store';
import router from './router';
import Vuetify from 'vuetify';
import VuetifyConfirm from 'vuetify-confirm';
import Notifications from 'vue-notification';
import './resizer';
import 'vuetify/src/stylus/main.styl';
import moment from 'moment-timezone';
import Utils from './modules/Utils';

Vue.use(Notifications);
Vue.use(VuetifyConfirm);
// @ts-ignore
const { lang } = vuex.state.Global;

Vue.use(Vuetify, {
  rtl: lang !== 'en'
});

Vue.use(VueI18Next);

Vue.config.productionTip = false;

Vue.config.errorHandler = (e, _vm, info) => {
  if (process.env.NODE_ENV === 'development') {
    console.error('[Global Error Handler]: Error in ' + info + ': ' + e);
  }
};

i18next.init({
  lng: lang,
  fallbackLng: 'en',
  resources: {
    en: { translation: locales.en },
    he: { translation: locales.he }
  }
});

Vue.prototype.$handler = () => {
  const difference_in_minutes = Utils.getIdleTimeInMinutes();
  
  if (difference_in_minutes >= 30) {
    vuex.commit('User/logout');
    vuex.commit('User/setIntervalActive', false);
  }else {
    localStorage.setItem('last_time_seen', moment().utcOffset(0).valueOf().toString());
  }
};

export const i18n = new VueI18Next(i18next);

const maintenance_json = async () => {
  try {
    const response = await fetch(`.well-known/maintenance.json`);
    const general_problem = await response.json();
    if (general_problem && general_problem.hasOwnProperty('killswitch') && general_problem.killswitch){
      new Vue({
        router,
        i18n,
        store: vuex,
        render: (h) => h(GeneralProblem)
      }).$mount('#app');
    }else {
      vuex.dispatch('Global/init').then(() => {
        new Vue({
          router,
          i18n,
          store: vuex,
          render: (h) => h(App)
        }).$mount('#app');
      });
    }
  }catch (e) {
    vuex.dispatch('Global/init').then(() => {
      new Vue({
        router,
        i18n,
        store: vuex,
        render: (h) => h(App)
      }).$mount('#app');
    });
  }
};

maintenance_json();
