










































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global, Reports, User, Props, Groups, MapSettings, DeviceStreams } from '@/store';
import DeleteFilterView from './DeleteFilterView.vue';
import CreateFilterView from './CreateFilterView.vue';
import UpdateFilterView from './UpdateFilterView.vue';
import PropUtils from '@/modules/PropUtils';

@Component({
  components: {
    UpdateFilterView,
    CreateFilterView,
    DeleteFilterView
  }
})
export default class MapFilter extends Vue {
  @Props.State('fixture_meta_device_options') fixture_meta_device_options;
  @User.State('project') project;
  @Reports.State('reportsList') reports;
  @Reports.Getter('devicesTypeList') devicesTypeList;
  @Reports.Getter('assetTypeList') assetTypeList;
  @Reports.Getter('deviceParentList') deviceParentList;
  @Groups.State('list') groupsList;
  @MapSettings.State('userMapFilters') userMapFilters;
  @MapSettings.Action('deleteMapFilter') deleteMapFilter;
  @DeviceStreams.State('deviceStreams') deviceStreams;
  @DeviceStreams.Mutation('setDeviceStreams') setDeviceStreams;
  @Global.State('isMobile') isMobile;
  @Global.Mutation('setEditFilterMode') setEditFilterMode;

  @Prop() clearDevice;

  selectedTypes = [];
  selectedAssetType = [];
  selectedAll = true;
  selectedDeviceClass = [];
  selectedDeviceGroup = [];
  selectedCircuit = [];
  selectedPhase = [];
  phases = ['R', 'S', 'T'];
  circuitNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  filter = {
    groups: null,
    classes: null,
    type: null,
    status: [],
    phase: null,
    circuit: null,
    name: '',
    map: null,
    assetType: null
  };

  currentFilterName = '';
  statusFilter = ['connected', 'disconnected'];

  get firstLoad() {
    return this.$route.path === '/filter/create';
  }

  get currentFilter() {
    return this.userMapFilters.find((view) => view.path === this.$route.path);
  }

  get groups() {
    const all = [{ text: 'All', value: 'all' }];

    return all.concat(this.groupsList.map((group) => ({
      text: group.name,
      value: group.id
    })));
  }

  mounted() {
    this.setInitialValue();
    this.$nextTick(() => this.focusToFilterName());
  }

  focusToFilterName(){
    if (this.$refs.filterName){
      this.$refs.filterName['focus']();
    }
  }

  validateFilterName(){
    return this.$refs.filterName 
      ? this.$refs.filterName['validate']()
      : true;
  }

  @Watch('devicesTypeList')
  setInitialValue(){
    this.setFieldsOptions();
    this.setFilterValue();
  }

  setFieldsOptions(){
    if (Object.keys(this.fixture_meta_device_options).length){
      const phase_options = this.fixture_meta_device_options.phase;
      this.phases = phase_options && phase_options.length ? phase_options : this.phases;
      const circuit_options = this.fixture_meta_device_options.circuit_number;
      this.circuitNumbers = circuit_options && circuit_options.length ? circuit_options : this.circuitNumbers;
      this.circuitNumbers.sort((c1, c2) => +c1 - +c2);
    }
  }

  // Prevent updates Filter to initial value after timeout
  // @Watch('reports')
  @Watch('groupsList')
  @Watch('userMapFilters')
  @Watch('$route')
  setFilterValue() {
    this.firstLoad
      ? this.setDefaultFilter()
      : this.setCustomFilter(this.currentFilter);
  }

  @Watch('selectedTypes')
  changeTypeSelection() {
    this.selectedAll = this.selectedTypes.length === this.devicesTypeList.length;
  }

  checkNameAlreadyExists() {
    return this.firstLoad
      ? this.userMapFilters.find((filter) => filter.name === this.currentFilterName)
      : this.userMapFilters.find((filter) => filter.name === this.currentFilterName && filter.path !== this.filter['path']);
  }

  setDefaultFilter() {
    this.currentFilterName = '';
    this.selectedDeviceClass = [];
    this.selectedDeviceGroup = [];
    this.selectedCircuit = this.circuitNumbers;
    this.selectedPhase = this.phases;
    this.groups.forEach((item) => this.selectedDeviceGroup.push(item.value));
    this.selectedAll = true;
    this.selectedAssetType = [...this.assetTypeList];
    this.selectAllTypes();
    this.statusFilter = ['connected', 'disconnected'];
    this.filter.status = this.statusFilter;
    this.filter.map = null;
    const newFilter = Object.assign(this.filter);
    newFilter.name = 'default';
  }

  setCustomFilter(filter) {
    this.currentFilterName = filter.name;
    this.selectedDeviceGroup = filter.groups;
    this.selectedDeviceClass = filter.classes;
    this.selectedTypes = filter.type;
    this.selectedAssetType = filter.assetType;
    this.statusFilter = filter.status;
    this.selectedPhase = filter.phase;
    this.selectedCircuit = filter.circuit;
    this.filter = Object.assign(filter);
    this.$emit('filter', filter);
  }

  getActiveDevicesLength() {
    const active = this.reports.filter((d) => d.active);
    return active.length;
  }

  closeModal() {
    this.setEditFilterMode(false);
    this.$emit('close', true);
  }

  selectAllTypes() {
    this.selectedTypes = [];
    if (this.selectedAll) {
      this.selectedTypes = [...this.devicesTypeList];
    }
    this.applyFilter();
  }

  applyFilter() {
    const data = {
      ...this.currentFilter,
      groups: this.selectedDeviceGroup,
      classes: this.selectedDeviceClass,
      type: this.selectedTypes,
      status: this.statusFilter,
      phase: this.selectedPhase,
      circuit: this.selectedCircuit,
      map: this.filter.map,
      assetType: this.selectedAssetType,
      name: this.currentFilterName
    };
    this.filter = Object.assign(data);
    this.$emit('filter', data);
  }

  setStatusFilter(status) {
    this.filter.status = status;
    this.applyFilter();
  }

  deleteFilter() {
    this.deleteMapFilter({ id: this.project.id, filter: this.filter });
    this.setDefaultFilter();
    this.$router.replace('/');
  }

  beforeDestroy() {
    this.clearDevice();
  }
}
