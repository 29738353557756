import mqtt from 'mqtt';
import store from 'store';
import vuex from '@/store';
import moment from 'moment';
import { i18n } from '@/main';

export enum StateTypes {
  OFFLINE,
  ONLINE
}

export enum EventTypes {
  ERROR,
  RECEIVE,
  PUBLISH,
  REDUCTION,
  SUBSCRIBE
}

export default class MQTT {
  public static instance = new MQTT();
  public state: StateTypes = StateTypes.OFFLINE;

  private mqtt: mqtt.MqttClient;
  private isDebug = false;
  private listeners = new Map<string, Map<string, (msg, match) => void>>();
  private statusListeners = new Set<{ type: EventTypes; f: (msg) => void }>();

  get countOfListeners() {
    return this.listeners.size;
  }

  init() {
    if (this.mqtt) {
      if (this.isDebug) console.log('[APP] MQTT reactivated!');
      return;
    }

    const user = store.get('user') || {};
    this.mqtt = mqtt.connect(process.env.VUE_APP_MQTT_SERVER, {
      protocol: 'wss',
      username: (vuex.state as any).User.user_id,
      password: user.apikey,
      reconnectPeriod: 5000
    });
    this.mqtt.on('connect', (res) => {
      if (res && res.cmd === 'connack' && this.state !== StateTypes.ONLINE) {
        console.log('[APP] MQTT connected!');
        this.setState(StateTypes.ONLINE);

        this.mqtt.on('message', (topic, msg) => {
          this.statusListeners.forEach((data) => {
            if (data.type === EventTypes.RECEIVE) data.f(msg.toString());
          });

          if (this.isDebug)
            console.log(
              `[APP] Received data on "${topic}" (${msg.toString()})`
            );
          for (const key of this.listeners.keys()) {
            if (new RegExp(key.replace('#', '*?')).test(topic)) {
              this.listeners.get(key).forEach((c) => c(msg, topic));
            }
          }
        });
      }
    });

    window.addEventListener('offline', () => this.setState(StateTypes.OFFLINE));
    window.addEventListener('online', () => this.setState(StateTypes.ONLINE));
  }

  setState(state: StateTypes) {
    this.state = state;
    this.statusListeners.forEach((data) => {
      if (data.type === EventTypes.REDUCTION) data.f(this.state);
    });
  }

  on(type: EventTypes, f: (msg) => void) {
    this.statusListeners.add({ type, f });
  }

  listen(topic, f: (msg, match) => void, id = new Date().getTime().toString()) {
    if (!this.listeners.has(topic)) {
      this.listeners.set(topic, new Map());
      const options: any = { qos: 1 };
      if (!this.mqtt) return; 
      this.mqtt.subscribe(topic, options, (err) => {
        if (err) {
          this.statusListeners.forEach((data) => {
            if (data.type === EventTypes.ERROR) data.f(err.toString());
          });

          this.listeners.delete(topic);
          console.error(err);
          return;
        }

        if (this.isDebug) console.log(`[APP] Subscribed to "${topic}"`);
        this.statusListeners.forEach((data) => {
          if (data.type === EventTypes.SUBSCRIBE) data.f(this.state);
        });
      });
    }

    if (this.isDebug && !this.listeners.get(topic).get(id)){
      console.log(`[APP] Added listener to "${topic}" by id "${id}"`);
    }
    this.listeners.get(topic).set(id, f);
    return true;
  }

  unlisten(topic, id = null) {
    if (this.listeners.has(topic)) {
      if (id === null) {
        this.listeners.get(topic).clear();
      } else {
        this.listeners.get(topic).delete(id);
      }
      if (this.listeners.get(topic).size === 0) {
        this.listeners.delete(topic);
        this.mqtt.unsubscribe(topic);
      }
      if (this.isDebug) {
        console.log(`[APP] Remove listener to "${topic}"`);
      }
    }
  }

  listenToProjectEvents() {
    this.init();
    this.listen(
      `cot-v4/project/${(vuex.state as any).User.project.id}/device_events`,
      (msg, match) => {
        try {
          const message = new TextDecoder('utf-8').decode(msg);
          const incomingData = JSON.parse(message);
          const action_type = incomingData['action_type'];
          if (action_type === 'event' && typeof message === 'string') {
            const content =
              (!isNaN(Number(incomingData['content'])) &&
                Number(incomingData['content'])) ||
              incomingData['content'];
            const stream_data = {
              device_id: incomingData['device_id'],
              field: incomingData['stream_name'],
              value: content
            };

            vuex.commit('Reports/setDeviceField', stream_data);
            vuex.commit('DeviceStreams/setDeviceStreamValue', stream_data);
          }
        } catch (error) {
          console.warn('Error while getting data from mqtt', error);
        }
      }
    );
  }

  publish(topic: string, value: any, options) {
    if ((vuex.state as any).Global.readonly_user) {
      if (value === '-1') return;
      vuex.dispatch('Global/throwNotify', {
        type: 'error',
        title: `${i18n.t('Error')}!`,
        text: i18n.t(`You're not authorized to perform this action`)
      });
      this.disconnect();
      this.init();
      return;
    }
    if (!options.qos || options.qos !== 1) options.qos = 1;
    this.statusListeners.forEach((data) => {
      if (data.type === EventTypes.PUBLISH) data.f(value.toString());
    });
    if (!this.mqtt) return;

    this.mqtt.publish(topic, value, options);
    if (this.isDebug) {
      console.log(`[APP] MQTT sent "${topic}" (${value})!`);
      console.log(`[APP] MQTT sent OPTIONS`, options);
    }
  }

  clearListeners(){
    Array.from(this.listeners.keys()).forEach((topic) => this.mqtt.unsubscribe(topic));
    this.listeners.clear();

    this.statusListeners.forEach((data) => {
      if (data.type === EventTypes.SUBSCRIBE) data.f(this.state);
    });
    console.log('[APP] CLEARED Listeners');
  }

  disconnect() {
    if (!this.mqtt) return;   
    this.clearListeners();
    this.setState(StateTypes.OFFLINE);
    this.mqtt.end();
    this.mqtt = null;
    console.log('[APP] MQTT disconnected!');
  }

  getSingleDeviceSubscribe(device_id, version_num){
    return `cot-v${version_num}/device/${device_id}/#`;
  }

  publishToSingleDevice(publish_data){
    if (!publish_data.version_num) return;
    let topic = `cot-v${publish_data.version_num}/user/${(vuex.state as any).User.user_id}/device/${publish_data.device_id}/`;
    if (publish_data.version_num === 4) {
      topic = topic + `stream/${publish_data.stream_id}/type/${publish_data.message_type}/fmt/${publish_data.format}`;
    }else {
      topic = topic + `${publish_data.message_type}/${publish_data.stream_id}/fmt/${publish_data.format}`;
    }
    this.publish(topic, publish_data.stream_value, publish_data.options);
  }

  getMqttMsgData(msg, match, version){
    const mqtt_msg_data = {
      device_id: '',
      stream_name: '',
      message_type: '', 
      format: '',
      message: new TextDecoder('utf-8').decode(msg),
      timestamp: null
    };

    mqtt_msg_data.device_id = match.split('/')[2];

    if (version === 3) {
      mqtt_msg_data.message_type = match.split('/')[3];
      mqtt_msg_data.stream_name = match.split('/')[4].split('.')[1];
      mqtt_msg_data.format =  match.split('/')[6];
    }else {
      mqtt_msg_data.message_type = match.split('/')[6];
      mqtt_msg_data.stream_name = match.split('/')[4];
      mqtt_msg_data.format =  match.split('/')[8];
    }

    let formatted_msg;
    try {
      if (mqtt_msg_data.format === 'json'){
        formatted_msg = JSON.parse(mqtt_msg_data.message);
        if (formatted_msg && typeof formatted_msg === 'object') {
          if (formatted_msg.hasOwnProperty('timestamp')) {
            mqtt_msg_data.timestamp = formatted_msg.timestamp * 1000;
          }
          if (formatted_msg.hasOwnProperty('content')){
            formatted_msg = formatted_msg.content;
            if (typeof formatted_msg === 'string'){
              formatted_msg = JSON.parse(formatted_msg);
            }
          }
        }
      }else if (/^[-,+]*\d+$/.test(mqtt_msg_data.message)){
        formatted_msg = Number(mqtt_msg_data.message);
      }else {
        formatted_msg = mqtt_msg_data.message;
      }
    }catch (e) {
      formatted_msg = mqtt_msg_data.format === 'json'
        ? typeof formatted_msg === 'string'
          ? formatted_msg
          : {}
        : '';
    }
    mqtt_msg_data.message = formatted_msg;
    if (!mqtt_msg_data.timestamp){
      mqtt_msg_data.timestamp = moment().utcOffset(0).valueOf();
    }

    return mqtt_msg_data;
  }
}
