






















































import Component from 'vue-class-component';
import TabsInfoMixin from '../../devices/TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import { Watch, Prop } from 'vue-property-decorator';
import { Props, Global } from '@/store';
import Utils from '@/modules/Utils';
import AreaAmchart from '@/components/AreaAmchart.vue';
import moment from 'moment';

@Component({
  components: {
    AreaAmchart
  }
})
export default class OverviewTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {  
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;

    @Prop() mqtt_msg_data;

    loading = true;
    noData = false;
    streamHistory = [];
    series = [];
    pickedPeriod = '1days';
    loading_stream_data = false;
    latest_datetime = null;

    selectedStream = 'air_pressure_hpa';
    streamsLatestValue = { 
      air_pressure_hpa: ['Barometric Pressure', '', 'hPa'],
      air_temperature: ['Air Temperature', '', '°C'],
      dew_point: ['Dew Point Temperature', '', '°C'],
      relative_humidity: ['Air Humidity', '', '% r. h.'],
      wind_direction: ['Wind Direction', '', '°'],
      wind_speed: ['Wind Speed', '', 'm/s']
    };

    async mounted() {
      this.loading = true;
      await this.fetchStreamsLatestValue();
      await this.fetchStreamHistory();
      this.loading = false;
      this.updateWindDirection();
    }

    get latest_values(){
      return Object.entries(this.streamsLatestValue);
    }

    get latest_update(){
      return this.latest_datetime && Utils.convertTimestamp(this.latest_datetime, 'DD.MM.YYYY HH:mm', this.projectTimezone) || 'N/A';
    }

    updateLatestDatetime(timestamp){
      if (timestamp && this.latest_datetime < timestamp){
        this.latest_datetime = timestamp;
      }
    }

    @Watch('streamsLatestValue.wind_direction', {deep: true, immediate: true})
    updateWindDirection(){
      this.$nextTick(() => {
        if (this.streamsLatestValue.wind_direction[1]) {
          const arrow = document.getElementById('arrow');
          if (arrow) {
            arrow.style.transform = 'rotate(' + this.streamsLatestValue.wind_direction[1] + 'deg)';
          }
        }
      });
    }

    async fetchStreamsLatestValue(){        
      this.streamsLatestValue.air_pressure_hpa[1] = await this.getLatestValue('air_pressure_hpa');
      this.streamsLatestValue.air_temperature[1] = await this.getLatestValue('air_temperature');
      this.streamsLatestValue.dew_point[1] = await this.getLatestValue('dew_point');
      this.streamsLatestValue.relative_humidity[1] = await this.getLatestValue('relative_humidity');
      this.streamsLatestValue.wind_direction[1] = await this.getLatestValue('wind_direction');
      this.streamsLatestValue.wind_speed[1] = await this.getLatestValue('wind_speed');
    }

    async getLatestValue(streamName) {
      const response = await Utils.fetchStreamLatestValue(this.device.id, streamName);
      if (!response) return 'N/A';

      if (response.timestamp) {
        this.updateLatestDatetime(response.timestamp);
      }

      return response.lastvalue === null || response.lastvalue === undefined 
        ? 'N/A'
        : response.lastvalue;
    }

    changeStreamChart(streamName){
      this.selectedStream = streamName;
    }

    get selectedStreamName(){
      return this.selectedStream 
        ? this.streamsLatestValue[this.selectedStream][0]
        : '';
    }

    get selectedStreamUnit(){
      return this.selectedStream 
        ? this.streamsLatestValue[this.selectedStream][2]
        : '';
    }

    get periods_list(){
      return [
        { text: this.$t('Last 24 hours'), value: '1days' },
        { text: this.$t('Last 48 Hours'), value: '2days' },
        { text: this.$t('Last Week'), value: '1weeks' },
        { text: this.$t('Last Month'), value: '1months' }
      ];
    }

    async updateSelectedPeriod(e) {
      let period = '1days';

      switch (e) {
        case 'Last 24 hours':
          period = '1days';
          break;
        case 'Last 48 Hours':
          period = '2days';
          break;
        case 'Last Week':
          period = '1weeks';
          break;
        case 'Last Month':
          period = '1months';
          break;
        default:
          period = '1days';
          break;
      }
      this.pickedPeriod = period;
      await this.fetchStreamHistory();
    }

    @Watch('selectedStream')
    async fetchStreamHistory(){
      this.noData = false;
      try {
        this.loading_stream_data = true;

        this.streamHistory = await Utils.fetchSingleStreamHistory({
          device: this.device,
          stream: { id: `${this.device.id}.${this.selectedStream}`},
          period: this.pickedPeriod
        });

        this.series = this.streamHistory.map(([timestamp, value]) => ({date: timestamp, value}));
  
        if (this.streamHistory.length === 0) {
          this.noData = true;
        }
        this.loading_stream_data = false;
      } catch (error) {
        console.log(error);
        this.loading_stream_data = false;
      }
    }

    @Watch('mqtt_msg_data')
    updateStreamLatestValue(){
      if (this.mqtt_msg_data.timestamp && this.latest_datetime < this.mqtt_msg_data.timestamp) {
        this.latest_datetime = this.mqtt_msg_data.timestamp;
      }

      const new_value = [...this.streamsLatestValue[this.mqtt_msg_data.stream_name]];
      new_value[1] = this.mqtt_msg_data.message;
      this.streamsLatestValue[this.mqtt_msg_data.stream_name] = new_value;
    }

    getNow(){
      return Utils.convertTimestamp(moment().utcOffset(0).valueOf(), 'DD.MM.YYYY HH:mm', this.projectTimezone);
    }
}
