var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"info-containers-styled-overview",staticStyle:{"height":"160px","padding":"15px"}},[_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.filterDevices.length))]),_c('v-flex',{class:_vm.lang === 'en' ? 'border-left' : 'border-right'},[_c('v-layout',{staticClass:"px-4",attrs:{"column":"","fill-height":""}},[_c('v-layout',[_c('img',{class:['icon-style', _vm.lang === 'en' ? 'mr-2' : 'ml-2'],attrs:{"src":require("@/assets/images/tondo_icons/project_color.svg")}}),_c('h2',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('PROJECT')))])]),_c('v-layout',{attrs:{"row":"","justify-space-between":"","align-end":""}},[_c('span',{staticClass:"font-weight-bold subheading",class:_vm.lang === 'en' ? 'mr-2' : 'ml-2'},[_vm._v(_vm._s(_vm.$t('Total')))]),_c('span',{staticClass:"font-weight-bold indigo--text subheading"},[_vm._v(_vm._s(_vm.projectDevicesFiltered.length))])])],1)],1),_vm._l((_vm.categoryMap.entries()),function(ref){
var type = ref[0];
var states = ref[1];
return _c('v-flex',{key:("" + type),staticClass:"px-3",class:_vm.lang === 'en' ? 'border-left' : 'border-right'},[_c('v-layout',{attrs:{"column":"","justify-space-between":"","fill-height":"","d-flex":"","wrap":""}},[_c('v-layout',{attrs:{"row":""}},[_c('img',{class:['icon-style', _vm.lang === 'en' ? 'mr-2' : 'ml-2'],attrs:{"src":_vm.getItemIcon(type)}}),_c('h2',{staticClass:"font-weight-bold",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(("" + type))))])]),_c('v-layout',{attrs:{"row":"","align-end":"","justify-space-between":""}},[_c('v-layout',{attrs:{"column":"","fill-height":""}},_vm._l((states),function(ref){
var state = ref[0];
var count = ref[1];
return _c('v-layout',{key:("" + state),attrs:{"align-center":""}},[_c('span',{staticClass:"font-weight-bold",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(state))+":")]),_c('span',{staticClass:"font-weight-bold indigo--text px-2"},[_vm._v(_vm._s(count))])])}),1),_c('v-layout',{attrs:{"column":"","fill-height":"","justify-space-around":"","align-end":""}},_vm._l((states),function(ref){
var state = ref[0];
var count = ref[1];
return _c('v-progress-linear',{key:(state + "-progress-bar"),staticStyle:{"margin":"0","border-radius":"9px"},style:(_vm.categoryMap.size <= 3 ? 'width: 200px' : 'width: 100px'),attrs:{"value":(count / _vm.categoryCounter.get(type)) * 100,"height":"12","color":"#7E57C2"}})}),1)],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }