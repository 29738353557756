

















import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import VueApexCharts from 'vue-apexcharts';
import Utils from '@/modules/Utils';

@Component({ 
    components: { 
        VueApexCharts 
    } 
})
export default class MotionTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {

    loading = true;
    streamHistory = [];
    chartOptions = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: true,
                export: {
                    csv: {
                        show: false,
                        filename: '',
                        columnDelimiter: ',',
                        headerCategory: 'time',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toString();
                        }
                    },
                    svg: {
                        filename: ''
                    },
                    png: {
                        filename: ''
                    }
                },
                autoSelected: 'zoom' 
            }  
        },
        stroke: {
            curve: 'stepline',
            width: 1
        },
        dataLabels: {
            enabled: false
        },
        title: {
            text: 'Motion Sensor Activity',
            align: 'center'
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false
            }
        },
        tooltip: {
            shared: false,
            x: {
                format: 'dd/MM/yy HH:mm'
            }
        }
    };
    series = [{
        name: 'Motion',
        data: null
    }];

    async mounted(){
        this.loading = true;
        await this.getGroupStateHistory();
        this.loading = false;
    }

    async getGroupStateHistory(){
        const data = {
            device: this.device,
            stream: { id: `${this.device.id}.group_state`},
            period: '1weeks'
        };

        const addedPoints = [];

        this.streamHistory = await Utils.fetchSingleStreamHistory(data);
        this.streamHistory.reverse();
        this.streamHistory.forEach((history, index) => {
            addedPoints.push(history);
            if (index < this.streamHistory.length - 1) addedPoints.push([history[0], this.streamHistory[index + 1][1]]);
        });

        this.series[0].data = addedPoints; 
    }
}
