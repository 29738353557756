import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

import vuex from '..';
import API, { Types } from '@/modules/API';
import get from 'lodash/get';

export interface StreamHistoryItem {
  date: Date;
  value: string;
}

export interface Stream {
  objectType: Types;
  objectID: string;
  id: string;
  name: string;
  type: string;
  streamtype: string;
  triggers: any[];
  updated: any;
  value: any;
  history: {
    items: StreamHistoryItem[];
    prev: StreamHistoryItem | null;
  };
}

@Module({ namespaced: true })
export default class ProjectStreams extends VuexModule {
  projectStreams: any = [];
  projectStreamsHistory: any = [];
  relativeStreamHistory: any = [];
  streamName: string = '';
  relativeValue: string = '';
  relativeUnit: string = '';
  absoluteStreamHistory: any = [];
  absoluteStreamName: string = '';
  absoluteStartDate: string = '';
  absoluteEndDate: string = '';
  next_activation_by_cabinet: any = new Map();
  digital_input_by_cabinet: any = new Map();

  @Action
  async fetchProjectStreams(data) {
    try {
      const body = {
        fields: 'id,name,value,updated'
      };
      const res = await API.get(
        Types.PROJECTS,
        data.projectId + '/streams',
        body,
        {}
      );
      if (res.results) {
        vuex.commit('ProjectStreams/setProjectSteams', res.results);
      }
    } catch (e) {
      vuex.commit(
        'Errors/setError',
        get(e, 'response.data.error.message', e)
      );
      vuex.commit('Errors/setShowError', true);
    }
  }

  @Action
  async fetchProjectStreamHistory(data) {
    let body;
    try {
      if (!data.period.from || !data.period.to) {
        body = {
          since: data.period
        };
      } else {
        body = {
          from: data.period.from,
          to: data.period.to
        };
      }

      const res = await API.get(
        Types.PROJECTS,
        data.projectId + `/streams/${data.streamId}/history`,
        body,
        {
          dataMethod: 'formData'
        }
      );
      if (res) {
        vuex.commit('ProjectStreams/setProjectSteamsHistory', res);
      }
    } catch (e) {
      vuex.commit(
        'Errors/setError',
        get(e, 'response.data.error.message', e)
      );
      vuex.commit('Errors/setShowError', true);
    }
  }

  @Action
  async getRelativeStreamHistory(parameters) {
    const body = {
      metrics: [
        {
          name: parameters.stream_name,
          tags: parameters.tags || {},
          aggregators: parameters.aggregators,
          group_by: [
            {
              name: 'tag',
              tags: ['device_id']
            }
          ]
        }
      ],
      start_relative: {
        value: parameters.value,
        unit: parameters.unit
      }
    };

    try {
      const data = await API.post(
        Types.COMPANIES,
        `${(vuex.state as any).User.project.company}/${Types.PROJECTS}/${
          (vuex.state as any).User.project.id
        }/data_query/`,
        body,
        {}
      );

      vuex.commit('ProjectStreams/setStreamName', parameters.stream_name);
      vuex.commit('ProjectStreams/setRelativeValue', parameters.value);
      vuex.commit('ProjectStreams/setRelativeUnit', parameters.unit);
      vuex.commit(
        'ProjectStreams/setRelativeStreamHistory',
        data.queries[0].results
      );
    } catch (e) {
      vuex.commit('ProjectStreams/setRelativeStreamHistory', []);
    }
  }

  @Action
  async geAbsoluteStreamHistory(parameters) {
    const body = {
      metrics: [
        {
          name: parameters.stream_name,
          tags: parameters.tags || {},
          aggregators: parameters.aggregators,
          group_by: [
            {
              name: 'tag',
              tags: ['device_id']
            }
          ]
        }
      ],
      start_absolute: parameters.start,
      end_absolute: parameters.end
    };

    try {
      const data = await API.post(
        Types.COMPANIES,
        `${(vuex.state as any).User.project.company}/${Types.PROJECTS}/${
          (vuex.state as any).User.project.id
        }/data_query/`,
        body,
        {}
      );

      vuex.commit(
        'ProjectStreams/setAbsoluteStreamName',
        parameters.stream_name
      );
      vuex.commit('ProjectStreams/setAbsoluteStartDate', parameters.start);
      vuex.commit('ProjectStreams/setAbsoluteEndDate', parameters.end);
      vuex.commit(
        'ProjectStreams/setAbsoluteStreamHistory',
        data.queries[0].results
      );
    } catch (e) {
      vuex.commit('ProjectStreams/setAbsoluteStreamHistory', []);
    }
  }

  @Action
  async fetchControlNextActivation() {
    const parameters = {
      stream_name: 'control_next_activation',
      aggregators: [],
      value: '1',
      unit: 'days'
    };

    try {
      await vuex.dispatch(
        'ProjectStreams/getRelativeStreamHistory',
        parameters
      );
      (vuex.state as any).ProjectStreams.relativeStreamHistory.forEach(
        (device) => {
          const device_id = device.tags.device_id[0];
          if (device.values.length) {
            const history = device.values.map(([timestamp, value]) => {
              const next_activation_value = [timestamp, null];
              try {
                const string_value = value.replace(/'/g, '"');
                next_activation_value[1] = JSON.parse(string_value);
              }catch (e){}
              return next_activation_value;
            });
            vuex.commit('ProjectStreams/setControlNextActivation', {
              device_id,
              history
            });
          }
        }
      );
    } catch (e) {
      // console.log('There was problem getting control_next_activation stream history');
    }
  }

  @Action
  async fetchDigitalInput() {
    const parameters = {
      stream_name: 'digital_input',
      aggregators: [],
      value: '1',
      unit: 'days'
    };

    try {
      await vuex.dispatch(
        'ProjectStreams/getRelativeStreamHistory',
        parameters
      );
      (vuex.state as any).ProjectStreams.relativeStreamHistory.forEach(
        (device) => {
          let inputs = false;
          if (device.values.length) {
            try {
              inputs = JSON.parse(
                device.values
                  .slice(-1)[0][1]
                  .replaceAll(`'`, `"`)
                  .replaceAll(`True`, `true`)
                  .replaceAll(`False`, `false`)
              );
            } catch (e) {
              console.log('Error while parsing digital input value');
            }
          }
          
          vuex.commit('ProjectStreams/setDigitalInput', {
            device_id: device.tags.device_id[0],
            inputs
          });
        }
      );
    } catch (e) {
      // console.log('There was problem getting digital_input stream history');
    }
  }

  @Mutation
  setControlNextActivation(next_activation_info) {
    this.next_activation_by_cabinet.set(next_activation_info.device_id, next_activation_info.history);
  }

  @Mutation
  setDigitalInput(digital_input_info) {
    this.digital_input_by_cabinet.set(
      digital_input_info.device_id,
      digital_input_info.inputs
    );
  }

  @Mutation
  setAbsoluteStreamName(stream_name) {
    this.absoluteStreamName = stream_name;
  }

  @Mutation
  setAbsoluteStartDate(start_date) {
    this.absoluteStartDate = start_date;
  }

  @Mutation
  setAbsoluteEndDate(end_date) {
    this.absoluteEndDate = end_date;
  }

  @Mutation
  setAbsoluteStreamHistory(stream_history) {
    this.absoluteStreamHistory = stream_history;
  }

  @Mutation
  setProjectSteams(data: any) {
    this.projectStreams = data;
  }

  @Mutation
  setProjectSteamsHistory(data: any) {
    this.projectStreamsHistory = data;
  }

  @Mutation
  setStreamName(stream_name) {
    this.streamName = stream_name;
  }

  @Mutation
  setRelativeValue(value) {
    this.relativeValue = value;
  }

  @Mutation
  setRelativeUnit(unit) {
    this.relativeUnit = unit;
  }

  @Mutation
  setRelativeStreamHistory(stream_history) {
    this.relativeStreamHistory = [...stream_history];
  }
}
