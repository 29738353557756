

























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Props, Reports, Global } from '@/store';
import SearchListItem from '@/components/SearchListItem.vue';
import VirtualList from 'vue-virtual-scroll-list';
import PropUtils from '@/modules/PropUtils';

@Component({
  components: {
    VirtualList,
    SearchListItem
  }
})
export default class DeviceSearchTab extends Vue {
    @Prop() project_devices;
    @Prop() project;
    @Prop() devicesList;
    @Prop() SINGLE_TAB;
    @Prop() OVERVIEW_TAB;
    @Prop() devices_list_data;
    @Prop() setSelectedTab;

    @Reports.Getter('commissioned_map') commissioned_map;
    @Reports.Getter('commissionedDevices') commissionedDevices;
    @Global.State('timezone') timezone;
    @Global.State('lang') lang;
    @Props.State('fixture_meta_device_options') fixture_meta_device_options;

    selectedDevice = '';
    searchType = 'name';
    cabinet_id_options = [];
    pole_number_options = [];
    selected_option = '';
    multiple_cabinet = '';
    multiple_circuit = '';
    multiple_pole = '';
    searchBtnClicked = false;
    search_list_item = SearchListItem;

    mounted(){
        this.setMetaDeviceSearch();
        this.clearData();
    }

    get options() {
        if (this.$refs.select_options) this.$refs.select_options['focus']();
        return this.searchType === 'poleNumber'
            ? this.pole_number_options
            : this.searchType === 'cabinetId'
            ? this.cabinet_id_options
            : [];
    }

    @Watch('searchType')
    clearData() {
        this.selected_option = '';
        this.multiple_cabinet = '';
        this.multiple_circuit = '';
        this.multiple_pole = '';
        this.searchBtnClicked = false;
        this.clearSelectedDevice();
        this.$emit('devicesListChanged', []);
    }

    @Watch('selected_option')
    updateDevicesList(){
        const new_list = this.selected_option ? this.debounceSearch() : [];
        this.$emit('devicesListChanged', new_list);
        this.searchBtnClicked = true;
    }

    clearSelectedDevice() {
        if (this.selectedDevice) this.selectedDevice = '';
    }

    checkSearchDisabled(){
        const value = (this.searchType !== 'custom' && !this.selected_option) 
        || (this.searchType === 'custom' && !this.multiple_cabinet && !this.multiple_circuit && !this.multiple_pole);
        return value;
    }

    setMetaDeviceSearch() {
        this.cabinet_id_options = this.fixture_meta_device_options.cabinet_id || [];
        this.pole_number_options = this.fixture_meta_device_options.pole_number || [];
    }

    setSelectedDevice(id) {
        this.selectedDevice = id;
    }

    debounceSearch() {
        this.clearSelectedDevice();
        let filtered_list_devices = [];
        if (this.selected_option){
            filtered_list_devices = this.searchType === 'name'
                ? this.filterByName()
                : this.searchType === 'cabinetId'
                ? this.filterByCabinetId()
                : this.filterByPoleNumber();
        }
       
        this.sortByCabinet(filtered_list_devices);
        return filtered_list_devices;
    }

    multipleSearch() {
        this.clearSelectedDevice();
        const search_by = [];

        if (this.multiple_cabinet) search_by.push({ name: 'cabinet_id', value: this.multiple_cabinet.toString() });
        if (this.multiple_circuit) search_by.push({ name: 'circuit_number', value: this.multiple_circuit.toString() });
        if (this.multiple_pole) search_by.push({ name: 'pole_number', value: this.multiple_pole.toString() });

        const filtered_list_devices = [];

        this.commissionedDevices.forEach((device) => {
            const metaDevice = this.commissioned_map.get(device.id)['meta.device'];
            const valid = !search_by.some((field) => {
                const field_value = metaDevice[field.name];
                return !field_value || field_value.toString() !== field.value;
            });
            if (valid) filtered_list_devices.push(this.devices_list_data.get(device.id));
        });

        this.$emit('devicesListChanged', filtered_list_devices);
        this.searchBtnClicked = true;
    }

    filterByCabinetId() {
        const selected = this.selected_option.toLowerCase();
        const filtered_list_devices = [];

        this.commissionedDevices.forEach((device) => {
            const cabinet_id = this.commissioned_map.get(device.id)['meta.device'].cabinet_id;
            const field_value = cabinet_id || '';
            const valid = field_value.toLowerCase() === selected || false;
            if (valid) filtered_list_devices.push(this.devices_list_data.get(device.id));
        });
        return filtered_list_devices;
    }

    filterByPoleNumber() {
        const selected = this.selected_option.toLowerCase();
        const filtered_list_devices = [];

        this.commissionedDevices.forEach((device) => {
            const pole_number = this.commissioned_map.get(device.id)['meta.device'].pole_number;
            const field_value = pole_number || '';
            const valid = field_value.toLowerCase() === selected || false;
            if (valid) filtered_list_devices.push(this.devices_list_data.get(device.id));
        });
        return filtered_list_devices;
    }

    filterByName() {
        const selected = this.selected_option.toLowerCase();
        const filtered_list_devices = [];
        this.commissionedDevices.forEach((device) => {
            const valid = device.name.toLowerCase().includes(selected);
            if (valid) filtered_list_devices.push(this.devices_list_data.get(device.id));
        });
        return filtered_list_devices;
    }

    sortByCabinet(devices) {
        devices.sort((device1, device2) => {
            const metaDevice1 = this.commissioned_map.get(device1.id)['meta.device'];
            const metaDevice2 = this.commissioned_map.get(device2.id)['meta.device'];
            const cabinet_id1 = metaDevice1.cabinet_id || '';
            const cabinet_id2 = metaDevice2.cabinet_id || '';
            const pole_number1 = metaDevice1.pole_number || '';
            const pole_number2 = metaDevice2.pole_number || '';

            if (cabinet_id1 === cabinet_id2) {
                return pole_number1 < pole_number2 ? -1 : 1;
            } else {
                return cabinet_id1 < cabinet_id2 ? -1 : 1;
            }
        });
    }

    panToMarker(device) {
        const marker = this.$root.$refs.GoogleMap['getMarkerById'](device.id);
        this.$root.$refs.GoogleMap['setBounds']([marker]);
        this.$root.$refs.GoogleMap['showCurrentInfoWindow'](marker);
    }

    beforeDestroy() {
        this.$root.$refs.GoogleMap['closeWindow']();
    }
}
