


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class TextFieldDialog extends Vue {
    @Prop() title;
    @Prop() buttonText;
    @Prop() textfieldLabel;
    @Prop() messages;

    dialog = true;
    text = '';
    clear = true;

    handleCloseClicked(){
        this.$emit('close');
    }

    handleActionClicked(){
        this.clear = false;
        this.$emit('action', this.text);
    }

    @Watch('text')
    clearMessages(){
        if (!this.clear) this.clear = true;
    }

    get textMessages(){
        return this.clear 
            ? ''
            : typeof this.messages === 'string'
            ? this.$t(this.messages)
            : this.messages.map((message) => this.$t(message));
    }
}

