






































































































































import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import { Global, Reports, Groups } from '@/store';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import API , { Types } from '@/modules/API';
import mapConfig from '@/pages/mapView/components/map/map.config';
// @ts-ignore
import VueQRCodeComponent from 'vue-qrcode-component';

@Component({
    components: {
        VueQRCodeComponent
    }
})

export default class OverviewTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
    @Global.State('lang') lang;
    @Groups.State('list') groups;

    // @ts-ignore
    @Prop() device;
    @Prop() metaDevice;
    @Prop() streamPower;
    @Prop() converted;
    @Prop() daliToPercentTable;
    @Prop() streamVersion;
    @Prop() group_ids;

    qrcode = '';
    size = 150;
    loading = true;
    relatedGroups = [];
    has_astronomical_clock = false;

    async created(){
        this.loading = true;
        this.generateQRcode();
        this.setRelatedGroups();
        this.has_astronomical_clock = PropUtils.hasAstronomicalClock(this.device.id);
        this.loading = false;
    }

    generateQRcode(){
        const hardwareidProp = PropUtils.parseProperty('device.hardwareid', this.device);
        if (hardwareidProp){
            const cpu_id = hardwareidProp.id ? hardwareidProp.id : '';
            const mac_id = hardwareidProp.mac ? hardwareidProp.mac : '';
            this.qrcode = cpu_id && mac_id ? `${cpu_id} ${mac_id}` : '';
        }else this.qrcode = '';
    }

    setRelatedGroups(){
        this.relatedGroups = this.groups.filter((group) => this.group_ids.includes(group.id)).map((group) => group.name);
    }

    get poleNumber() {
        return this.metaDevice && this.metaDevice.pole_number || 'N/A';
    }

    get cabinetId() {
        return this.metaDevice && this.metaDevice.cabinet_id || 'N/A';
    }

    get circuitNumber() {
        return this.metaDevice && this.metaDevice.circuit_number || 'N/A';
    }

    get dimmingLevel() {
        return Utils.getPercentValue(this.converted, this.daliToPercentTable, this.streamPower);
    }

    get connected_status(){
        return this.device.sys___connected === 0 
            ? 'Disconnected' 
            : this.device.sys___connected === 1
            ? 'Connected' 
            : 'Unknown';
    }

    get active_status() {
        return this.device.sys___active === 0 
            ? 'Inactive' 
            : this.device.sys___active === -1
            ? 'Maintenance'
            : this.device.sys___active === -3
            ? 'In Installation'
            : this.device.sys___active === -9
            ? 'Fault In Care'
            : this.device.sys___active === 1
            ? 'Active'
            : 'Unknown';
    }

    get clockIcon() {
        return this.has_astronomical_clock
            ? mapConfig.icons.astronomicalClock
            : mapConfig.icons.noAstronomicalClock;
    }

    get clockText() {
        return this.has_astronomical_clock
            ? 'Yes'
            : 'No';
    }

    get imsi() {
        return this.device['meta.imsi.imsi'] ? this.device['meta.imsi.imsi'] : 'N/A';
    }
}
