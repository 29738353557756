





























import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, Props, User, Reports } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import MetaDeviceFilter from './../pages/reports/components/MetaDeviceFilter.vue';
import cloneDeep from 'lodash/cloneDeep';

@Component({
    components: {
        MetaDeviceFilter
    }
})
export default class MultipleSearchFilter extends Vue {
    @Props.State('fixture_meta_device_options') fixture_meta_device_options;
    @Props.State('cabinet_meta_device_options') cabinet_meta_device_options;
    @Reports.Getter('commissionedFixtures') commissionedFixtures;
    @Global.State('lang') lang;
    @Reports.Getter('commissioned_map') commissioned_map;
    @Reports.Getter('commissionedCabinets') commissionedCabinets;
    @User.State('project') project;

    @Prop() deviceTypes;

    loading = true;
    message = 'No Data Available';
    selectedDeviceType = {text: '', value: ''};
    // commissioned_tondos = [];
    // commissioned_cabinets = [];
    // commissioned_devices = [];
    search_fields = [];
    filter_fields = {};
    deviceTypesList = [];
    noData = false;

    mounted(){
        this.loading = true;
        this.search_fields = [];
        this.setMetadataFieldsLists();
        // this.setCommissionedByType();
        // this.updateCommissionedDevices();

        this.loading = false;
    }

    setMetadataFieldsLists(){
        if (Object.keys(this.fixture_meta_device_options).length && this.deviceTypes.includes('tondo')){
            this.deviceTypesList.push({ text: 'Tondo Device', value: 'tondo'});
        }
        if (Object.keys(this.cabinet_meta_device_options).length && this.deviceTypes.includes('cabinet')){
            this.deviceTypesList.push({text: 'Cabinet Monitor', value: 'cabinet'});
        }

        this.noData = !this.deviceTypesList.length;
        if (!this.noData) this.selectedDeviceType = this.deviceTypesList[0];
    }

    // setCommissionedByType(){
    //     this.commissioned_tondos = this.commissionedFixtures.length ? this.getCommissionedDevices('tondo') : [];
    //     this.commissioned_cabinets = this.commissionedCabinets.length ? this.getCommissionedDevices('cabinet') : [];
    // }

    // getCommissionedDevices(type){
    //     return type === 'tondo' 
    //         ? [...this.commissioned_map.values()].filter((device) => Utils.hasTondoClass(device.class_name))
    //         : type === 'cabinet' 
    //         ? [...this.commissioned_map.values()].filter((device) => Utils.hasCabinetClass(device.class_name))
    //         : [];
    // }

    updateSearchFields(fields){
        this.search_fields = cloneDeep(fields);
        this.$emit('filterChanged', this.search_fields);
        this.$emit('close');
    }

    updateSelectedType(type){
        this.selectedDeviceType = type;
    }

    @Watch('selectedDeviceType')
    updateCommissionedDevices(){
        // this.commissioned_devices = this.selectedDeviceType.value === 'tondo'
        //     ? this.commissioned_tondos
        //     : this.commissioned_cabinets;

        this.clearTable();
    }

    clearTable(){
        this.search_fields = [];
    }
}

