













































import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import TabsInfoMixin from './TabsInfoMixin';
import { Global, DeviceStreams } from '@/store';
import Utils from '@/modules/Utils';
import API, { Types } from '@/modules/API';
import OverviewTab from '../information/waterMeterTabs/OverviewTab.vue';
import ConsumptionTab from '../information/waterMeterTabs/ConsumptionTab.vue';
import PropUtils from '@/modules/PropUtils';

@Component({
  components: {
    OverviewTab,
    ConsumptionTab
  }
})

export default class WaterMeterInfoTabs extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @DeviceStreams.Action('fetchAllStreamsForSingleDevice') fetchAllStreamsForSingleDevice;
  @DeviceStreams.State('deviceStreams') deviceStreams;
  @DeviceStreams.Mutation('setDeviceStreams') setDeviceStreams;

  metaDeviceData = {};
  loadingTabs = true;
  tab = 'tab-overview';
  waterFlowStreamModified = '';
  temperatureStream = '';
  batteryLevelStream = '';

  async mounted() {
    await this.deviceChanged();
  }

  setTabItem(tabIndex) {
    this.tab = tabIndex;
  }

  @Watch('device')
  async deviceChanged() {
    try {
      this.active = 'tab-overview';
      this.tab = 'tab-overview';
      this.loadingTabs = true;
      const metaDevice = await this.fetchSingleProperty('meta.device', 'json');
      PropUtils.reorderMetaDevice(metaDevice);
      this.metaDeviceData = metaDevice;
      await this.fetchStreams();
      this.loadingTabs = false;
    }
    catch (error) {
      console.log('ERROR while watch device:', error);
      this.loadingTabs = false;
    }
  }

  async fetchSingleProperty(property_name, format){
    const property = await API.get(Types.DEVICES, `${this.device.id}/properties/`, { name: property_name });
    return property && property.results && property.results[0] && property.results[0].value ? property.results[0].value : format === 'json' ? {} : '';
  }

  async fetchStreams() {
    await this.setDeviceStreams([]);
    await this.fetchAllStreamsForSingleDevice(this.device.id);
    const water_flow = Utils.getStream('water_flow_liters', this.deviceStreams);
    this.waterFlowStreamModified = await this.getStreamChangeTime(water_flow);
    const temperature = Utils.getStream('temperature', this.deviceStreams);
    this.temperatureStream = temperature && temperature.value !== null ? temperature.value : 'N/A';
    const battery_level = Utils.getStream('battery_level', this.deviceStreams);
    this.batteryLevelStream = battery_level && battery_level.value !== null ? battery_level.value : 'N/A'; 
  }

  async getStreamChangeTime(stream) {
    if (!stream) return 'N/A';
    const response = await API.get(Types.DEVICES, `${this.device.id}/streams/${stream.id}/latest/`);
    if (!response['timestamp']) return 'N/A';
    return Utils.convertTimestamp(response.timestamp, 'DD.MM.YYYY HH:mm', this.projectTimezone);
  }

  async beforeDestroy() {
    await this.setDeviceStreams([]);
  }
}
