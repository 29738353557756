import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import vuex from '..';
import findIndex from 'lodash/findIndex';
import API from '@/modules/API';
import PropUtils from '@/modules/PropUtils';
import get from 'lodash/get';
import store from 'store';
import { i18n } from '@/main';
import router from '../../router';

export interface Filter {
  name: string;
  groups?: any;
  classes?: any;
  type?: any;
  zoom?: any;
  status?: any;
  classname: string;
  path: string;
  menuTitle: string;
  meta: any;
  value: any;
  version: string;
  cluster: boolean;
}

@Module({ namespaced: true })
export default class MapSettings extends VuexModule {
  isLoading: boolean = false;
  userMapFilters: Filter[] = [];
  userClusters = new Map();
  mapStyle: string = 'streets-v11';
  deviceDrag: boolean = false;

  @Action
  updateMapFilter(data: any) {
    vuex.commit('MapSettings/updateFilter', data);
  }

  @Action
  setLoading(isLoading: boolean) {
    vuex.commit('MapSettings/setLoaderState', isLoading);
  }

  @Action
  async loadUserProperties(projectData: any) {
    if (projectData && projectData.id) {
      try {
        vuex.commit('MapSettings/setUserMapFilters', []);
        vuex.commit('MapSettings/clearUserClusters');      
        await vuex.dispatch('Props/load', { projectId: projectData.id });
        await vuex.dispatch('MapSettings/setUserProperties');

        if ((vuex.state as any).Props.list.length) {
          const properties = (vuex.state as any).Props.list;
          const operationContacts = PropUtils.getProperty(properties, 'meta.operation_contacts');
          const username = store.get('user').username;
          const isManager = operationContacts
            ? operationContacts.value.some((user) => user.toLowerCase() === username.toLowerCase())
            : false;
          vuex.commit('MapSettings/setUserMapFilters', PropUtils.getProperty(properties, 'dashboard.filters'));
          vuex.commit('User/setManager', isManager);
          vuex.commit('MapSettings/setProjectMapStyle', properties);
        }
      } catch (e) {
        vuex.commit(
          'Errors/setError',
          get(e, 'response.data.error.message', e)
        );
        vuex.commit('Errors/setShowError', true);
      }
    }
  }

  @Action
  async setUserProperties(){
    const state = vuex.state as any;

    await vuex.dispatch('User/fetchUserProperties');

    let default_map = '', update_user_settings = false;
    const user_settings = state.User.properties.find((property) => property.name === 'dashboard.settings');

    if (!user_settings || !user_settings.value) update_user_settings = true;

    if (user_settings && user_settings.value && user_settings.value.map){
      default_map = user_settings.value.map;
    }else {
      const default_map_prop = state.User.properties.find((property) => property.name === 'dashboard.map');
      if (default_map_prop && default_map_prop.value && default_map_prop.value.default_map){
        default_map = default_map_prop.value.default_map;
        update_user_settings = true;
      }
    }

    let default_lang = '';
    if (user_settings && user_settings.value && user_settings.value.lang){
      default_lang = user_settings.value.lang;
      vuex.commit('Global/setLang', user_settings.value.lang);
    }else {
      vuex.commit('Global/setProjectLang', state.Props.list);

      default_lang = state.Global.lang;
      update_user_settings = true;
    }

    if (default_map) {
      vuex.commit('User/setDefaultMap', default_map);
    }
    if (update_user_settings) {
      const user_settings_prop = {
        name: 'dashboard.settings',
        value: { lang: default_lang, map: default_map }
      }; 
      vuex.dispatch('User/updateUserProperty', user_settings_prop);
    }
  }

  @Action
  async updateFiltersProperty(data: any) {
    try {
      const filters = data.routes.filter((filter) => filter.name !== 'default');
      const res = await API.patch(
        '',
        `/projects/${data.projectId}/properties/`,
        filters,
        { query: { name: 'dashboard.filters' } }
      );
      if (res) {
        vuex.commit('MapSettings/setUserMapFilters', res);
        if (data.mode) {
          vuex.dispatch('Global/throwNotify', {
            type: 'success',
            title: `${i18n.t('Success')!}`,
            text:
              data.mode === 'create'
                ? i18n.t(`Filter successfully created`)
                : data.mode === 'update'
                ? i18n.t('Filter successfully updated')
                : i18n.t('Filter successfully deleted')
          });
          if (data.mode === 'create' && data.path) router.push(data.path);
        }
      }
    } catch (e) {
      if (data.mode) {
        vuex.dispatch('Global/throwNotify', {
          type: 'error',
          title: `${i18n.t('Error')!}`,
          text:
            data.mode === 'create'
              ? i18n.t(`Couldn't create filter`)
              : data.mode === 'update'
              ? i18n.t(`Couldn't update filter`)
              : i18n.t(`Couldn't delete filter`)
        });
      }
    }
  }

  @Action
  async saveMapStyleSetting(data: any) {
    try {
      const res = await API.patch('', '/properties/', data, {
        itemID: 1,
        query: {
          name: 'dashboard.map_style',
          version: '0'
        }
      });
      if (res) {
        vuex.commit('MapSettings/setMapStyle', data);
      }
    } catch (e) {
      vuex.commit(
        'Errors/setError',
        get(e, 'response.data.error.message', e)
      );
      vuex.commit('Errors/setShowError', true);
    }
  }

  @Action
  deleteMapFilter(data: any) {
    vuex.commit('MapSettings/removeFilter', data);
  }

  @Mutation
  clearUserClusters() {
    this.userClusters.clear();
  }

  @Mutation
  updateFilter(data: any) {
    const index = findIndex(
      this.userMapFilters,
      (item) => item.path === data.filter.path
    );
    index > -1
      ? this.userMapFilters.splice(index, 1, data.filter)
      : this.userMapFilters.push(data.filter);
    const mode = index > -1 ? 'update' : 'create';
    const path = mode === 'create' ? data.path : null;
    const filters_data = {
      projectId: data.id,
      routes: this.userMapFilters,
      mode
    };
    if (path) filters_data['path'] = path;
    vuex.dispatch('MapSettings/updateFiltersProperty', filters_data);
  }

  @Mutation
  removeFilter(data: any) {
    const ind = this.userMapFilters.findIndex(
      (item) => item.path === data.filter.path
    );
    if (ind > -1) {
      this.userMapFilters.splice(ind, 1);
      Array.from(this.userClusters.keys()).forEach((cluster) => {
        const index = this.userClusters.get(cluster).findIndex((item) => {
          return item.name === data.filter.name;
        });
        if (index > -1) {
          this.userClusters.get(cluster).splice(index, 1);
          if (this.userClusters.get(cluster).length === 0) {
            this.userClusters.delete(cluster);
          }
        }
      });
      vuex.dispatch('MapSettings/updateFiltersProperty', {
        projectId: data.id,
        routes: this.userMapFilters,
        mode: 'delete'
      });
    }
  }

  @Mutation
  setLoaderState(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  setUserMapFilters(data: any) {
    const filters = [];
    if (data && data.value) {
      data.value.forEach((filter, y) => {
        if (filter.name) {
          if (filter.name.includes('~')) {
            const start = filter.name.indexOf('~');
            const end = filter.name.indexOf('~', start + 1);
            const clusterName = filter.name.slice(start + 1, end);
            filter.path = '/custom' + (y + 1);
            filter.menuTitle = filter.name.slice(end + 1);
            filter.meta = {
              auth: true,
              company: true
            };
            filter.cluster = true;
            filters.push(filter);
            if (this.userClusters.has(clusterName)) {
              const index = this.userClusters
                .get(clusterName)
                .findIndex((item) => {
                  return item.name === filter.name;
                });
              if (index === -1) {
                this.userClusters.set(
                  clusterName,
                  [...this.userClusters.get(clusterName), filter].sort((a, b) =>
                    a.menuTitle.localeCompare(b.menuTitle)
                  )
                );
              } else {
                this.userClusters.get(clusterName).splice(index, 1, filter);
              }
            } else {
              this.userClusters.set(clusterName, [filter]);
            }
          } else {
            filter.path = '/custom' + (y + 1);
            filter.menuTitle = filter.name;
            filter.meta = {
              auth: true,
              company: true
            };
            filters.push(filter);
          }
        }
      });
    }
    this.userMapFilters = filters;
  }

  @Mutation setMapStyle(data) {
    this.mapStyle = data;
  }

  @Mutation
  setProjectMapStyle(properties) {
    const mapStyleProp = PropUtils.getProperty(
      properties,
      'dashboard.map_style'
    );

    if (mapStyleProp) {
      vuex.commit('MapSettings/setMapStyle', mapStyleProp.value);
    } else {
      vuex.commit('MapSettings/setMapStyle', 'streets-v11');
    }
  }

  @Mutation
  setDeviceRepositioning(dragend) {
    this.deviceDrag = dragend;
  }
}
