






























































































import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { Reports, Props, Global } from '@/store';
import Utils from '@/modules/Utils';
import API, { Types } from '@/modules/API';
import config from '../../../map/map.config';
import PropEditor from '@/components/PropEditor.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import GoogleMap from '@/pages/mapView/components/map/GMap.vue';
import PropUtils from '@/modules/PropUtils';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  components: {
    PropEditor,
    ConfirmDialog,
    GoogleMap
  }
})
export default class DeviceDataTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Reports.Getter('commissioned_map') commissioned_map;
  @Global.State('lang') lang;
  @Global.State('readonly_user') readonly_user;
  @Props.State('fixture_meta_device_options') fixture_meta_device_options;
  @Props.State('cabinet_meta_device_options') cabinet_meta_device_options;
  @Props.State('list') projectProperties;

  // @ts-ignore
  @Prop() device;
  @Prop() metaDeviceData;

  formFieldsListFixture = [];
  deviceData = {};
  estimated_address = false;
  confirmDialog = false;
  cabinet_id_options = [];
  search = '';
  new_cabinet_message = `I know that this ID doesn't exist in the system and I'm responsible for the consequences`;
  show_new_message = false;
  lengthRules = [
    (v) => !!v || this.$t('Field is required'),
    (v) => (v && v.length <= 50) || 'Max field length is 50 characters'
  ];

  devices_id_options = [];
  cabinets_id_options = [];
  optional_cabinet_id_options = [];
  prop_utils = PropUtils;

  mounted() {
    this.loadFields();
  }

  loadFields() {
    const form_meta_device = this.projectProperties.find((el) => el.name === 'dashboard.form_meta_device');
    this.formFieldsListFixture = form_meta_device ? form_meta_device['value']['fields'] : [];
    this.setCabinetIdOptions();
    this.deviceData = cloneDeep(this.metaDeviceData);

    if (this.formFieldsListFixture) {
      const address_field = this.formFieldsListFixture.find((el) => el.name === 'address');
      if (address_field){
        this.estimated_address = !this.metaDeviceData['address'] && this.metaDeviceData.estimated_address;
        this.deviceData['address'] = this.metaDeviceData['address'] || this.metaDeviceData.estimated_address || '';
      }
    }
  }

  setCabinetIdOptions(){
    this.devices_id_options = this.fixture_meta_device_options.cabinet_id || [];
    this.cabinets_id_options = this.cabinet_meta_device_options.cabinet_id || [];
    
    this.updateOptionalCabinetIds();
  }

  @Watch('search')
  updateCabinetID(){
    this.deviceData['cabinet_id'] = this.search;
    this.show_new_message = this.checkNewCabinetID() && this.mandatoryCheck('cabinet_id') === true;
  }

  checkNewCabinetID(){
    return !this.optional_cabinet_id_options.includes(this.search);
  }

  mandatoryCheck(field_name){
    return !!this.deviceData[field_name] || this.$t('Field Is Required');
  }

  numberRules(item) {
    return item['mandatory'] ?
      [
        (v) =>  !v ? this.$t('Field is required') :
          (!isNaN(parseFloat(v)) &&
            v >= item['range']['min'] &&
            v <= item['range']['max']) ||
          `${this.$t('Number has to be between')} ${item['range']['min']} ${this.$t('and')} ${item['range']['max']}`
      ] :
      [
        (v) => !v ||
          (!isNaN(parseFloat(v)) &&
            v >= item['range']['min'] &&
            v <= item['range']['max']) ||
          `${this.$t('Number has to be between')} ${item['range']['min']} ${this.$t('and')} ${item['range']['max']}`
      ];
  }

  getTextfieldLabel(field){
    return field.name === 'address' 
      ? `${this.$t(`${PropUtils.getFieldLowerToUpper(field.name)}`)}${this.estimated_address && `(${this.$t('Estimated')})` || ''}${field.mandatory && '*' || ''}`
      : `${this.$t(`${PropUtils.getFieldLowerToUpper(field.name)}`)}${field.mandatory && '*' || ''}`;
  }

  async setCurrentLocation() {
    const data: any = await Utils.getCurrentPosition();
    if (data && data.coords) {
      const uppercase_address = PropUtils.findMatchedUppercaseField(this.deviceData, 'address');

      try {
        const { latitude, longitude } = data.coords;
        let GEODATA;
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${Number(
            latitude
          )},${Number(longitude)}&key=${config.TOKEN_GOOGLE}`
        );
        if (response.ok) {
          const json = await response.json();
          if (json.error_message) {
            throw new Error(json.error_message);
          }
          GEODATA = json.results[0] && json.results[0].formatted_address;
          this.deviceData['address'] = GEODATA;
          if (uppercase_address) this.deviceData[uppercase_address] = GEODATA;
          this.$forceUpdate();
        } else {
          throw new Error();
        }
      } catch (error) {
        console.log('error in converting address', error);
        this.deviceData['address'] = 'Unknown location';
        if (uppercase_address) this.deviceData[uppercase_address] = 'Unknown location';
      }
    }
  }

  checkFormValidation(){
    try {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.confirmDialog = true;
      }else {
        this.$notify({
          type: 'error',
          title: 'Warning',
          text: 'Please fullfill all required fields'
        });
      }
    } catch (error) {
      console.log('ERROR WHILE VALIDATE DATA:', error);
    }
  }

  updateDeviceProperty() {
    const prop = {
      name: 'meta.device',
      objectID: this.device.id,
      objectType: Types.DEVICES
    };
    if (this.formFieldsListFixture.some((field) => field.name === 'cabinet_id') && typeof this.deviceData['cabinet_id'] !== 'string'){
      this.deviceData['cabinet_id'] = this.deviceData['cabinet_id'].value;
    }

    this.deviceData = PropUtils.getUpdatedUppercaseFields(this.deviceData);

    (this.$refs.propEditor as PropEditor).save(
      this.deviceData,
      prop,
      () => {
        if (this.show_new_message || !this.optional_cabinet_id_options.includes(this.deviceData['cabinet_id'])) {
          this.updateCurrentOptions();
          this.updateSearchProperty();
          this.show_new_message = false;
        }
        const device = this.commissioned_map.get(this.device.id);
        device['meta.device'] = this.deviceData;
        this.$emit('dataChanged', this.deviceData);
        this.$root.$refs.GoogleMap['showCurrentInfoWindow'](this.device);
      },
      'All changes have been saved successfully'
    );
    this.confirmDialog = false;
  }

  updateSearchProperty(){
    this.fixture_meta_device_options.cabinet_id.push(this.deviceData['cabinet_id']);
  }

  updateCurrentOptions(){
    this.devices_id_options.push(this.deviceData['cabinet_id']);
    this.updateOptionalCabinetIds();
  }

  updateOptionalCabinetIds(){
    this.optional_cabinet_id_options = [...new Set(this.devices_id_options.concat(this.cabinets_id_options))];
    this.optional_cabinet_id_options.sort((c1, c2) => c1.localeCompare(c2, undefined, {numeric: true, sensitivity: 'base'}));
  }
}
