















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class GeneralProblem extends Vue {

    generalProblemData = {};
    
    async mounted(){
        try {
            const response = await fetch(`.well-known/maintenance.json`);
            this.generalProblemData = await response.json();
        }catch (e){}
    }

    refreshPage(){
        window.location.reload();
    }
}
