












































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Reports, User, Props, Global } from '@/store';
import StyledOverviewInfo from './StyledOverviewInfo.vue';
import MultipleSearchTab from './MultipleSearchTab.vue';
import DeviceSearchTab from './DeviceSearchTab.vue';

@Component({
  components: {
    StyledOverviewInfo,
    MultipleSearchTab,
    DeviceSearchTab
  }
})
export default class ProjectInformation extends Vue {
  @Prop() info;
  @Prop() devices;
  @Prop() map_devices;
  @Prop() devices_list_data;
  @Prop() simple_map;

  @Props.State('list') projectProperties;
  @User.State('project') project;
  @Reports.State('reportsList') reports;
  @Reports.Getter('commissionedDevices') commissionedDevices;
  @Global.State('lang') lang;
  @Global.State('isMobile') isMobile;

  devicesList = [];
  active = null;
  tab = '';
  OVERVIEW_TAB = 'tab-overview';
  SINGLE_TAB = 'tab-single-search';
  MULTIPLE_TAB = 'tab-multiple-search';
  EVENTS_TAB = 'tab-events';

  created() {
    this.SINGLE_TAB = 'tab-single-search';
    this.MULTIPLE_TAB = 'tab-multiple-search';
    this.devicesList = [];
    this.$root.$refs.ProjectInformation = this;
    this.tab = this.OVERVIEW_TAB;
    this.setActiveEvents();
    this.updateSearchClicked();
  }

  mapDevicesChanged(tab_name, devices?) {
    const tab_devices = devices || this.commissionedDevices;
    if (this.tab !== tab_name) this.setSelectedTab(tab_name);
    this.$emit('searchChanged', tab_devices, this.tab);
    this.updateSearchClicked();
  }

  addCommissionedDevices(){
    this.$emit('searchChanged', this.commissionedDevices, this.tab);
  }

  setSelectedTab(tab_name) {
    this.tab = tab_name;
  }

  updateSearchClicked() {
    this.tab === this.OVERVIEW_TAB
      ? this.$emit('searchClicked', false)
      : this.$emit('searchClicked', true);
  }

  setDevicesList(devices) {
    this.devicesList = devices;
  }

  @Watch('devices')
  @Watch('project')
  changingProject() {
    if (this.project) this.active = this.OVERVIEW_TAB;
  }

  @Watch('$route', { immediate: true, deep: true })
  setActiveEvents() {
    const { project } = this.$route.params;
    if (project) {
      this.active = this.EVENTS_TAB;
    }
  }


}
