


























import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';

@Component({})
export default class DimmingDialog extends Vue {

  @Global.State('lang') lang;

  dialog = true;
  dimming_level = 40;

  handleDimmingChanged(){
    this.$emit('action', this.dimming_level);
    this.$emit('close');
  }

}
