








































































import Vue from 'vue';
import Component from 'vue-class-component';
import TabsInfoMixin from './TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import API, { Types } from '@/modules/API';
import MQTT from '@/modules/MQTT';
import { Watch } from 'vue-property-decorator';
import { Global, Props, DeviceStreams } from '@/store';
import Utils from '@/modules/Utils';
import VueApexCharts from 'vue-apexcharts';
import chartConfig from './chart.config';
import config from '../../map/map.config';

@Component({
  components: {
    apexchart: VueApexCharts
  }
})

export default class RadarTabs extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('lang') lang;
  @Global.State('mqtt_version_by_class') mqtt_version_by_class;
  @Props.State('list') projectProperties;
  @DeviceStreams.Action('fetchAllStreamsForSingleDevice') fetchAllStreamsForSingleDevice;
  @DeviceStreams.State('deviceStreams') deviceStreams;
  @DeviceStreams.Mutation('setDeviceStreams') setDeviceStreams;

  mqtt = MQTT.instance;
  chartOptionsTotalVehicles = chartConfig.chartOptionsTotalVehicles;
  totalVehiclesSeries = [0];
  countStream = 0;
  vehicleCountStreams = [
    { vehicleSize: '1', counter: 0, icon: config.icons.trialerTruckIcon },
    { vehicleSize: '2', counter: 0, icon: config.icons.vanIcon },
    { vehicleSize: '3', counter: 0, icon: config.icons.carIcon },
    { vehicleSize: '4', counter: 0, icon: config.icons.smartCarIcon },
    { vehicleSize: '5', counter: 0, icon: config.icons.motorcycle }
  ];
  liveDataInfo = [];
  loadingTabs = true;
  mqtt_subscribe_topic = '';
  mqtt_version = 3;

  async mounted() {
    await this.deviceChanged();
  }

  get vehicleMonitorHeaders() {
    return [
      { text: this.$t('Date'), value: 'date', class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'] },
      { text: this.$t('Direction'), value: 'direction', class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'] },
      { text: this.$t('AVG Speed'), value: 'averageSpeed', class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'] },
      { text: this.$t('Max Speed'), value: 'maxSpeed', class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'] },
      { text: this.$t('Classification'), value: 'classification', class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'] }
    ];
  }

  get vehicleCountHeaders() {
    return [
      { text: '', value: 'icon', class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'] },
      { text: this.$t('Vehicle Size'), value: 'vehicleSize', class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'] },
      { text: this.$t('Count'), value: 'counter', class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right'] }
    ];
  }

  get getGeoLocation() {
    return Utils.deviceLocation(this.device);
  }

  async fetchStreams() {
    this.setDeviceStreams([]);
    await this.fetchAllStreamsForSingleDevice(this.device.id);

    const countStream = Utils.getStream('count', this.deviceStreams);
    const count1Stream = Utils.getStream('count_1', this.deviceStreams);
    const count2Stream = Utils.getStream('count_2', this.deviceStreams);
    const count3Stream = Utils.getStream('count_3', this.deviceStreams);
    const count4Stream = Utils.getStream('count_4', this.deviceStreams);
    const count5Stream = Utils.getStream('count_5', this.deviceStreams);
    const logStream = Utils.getStream('log', this.deviceStreams);

    const logHistory = await API.get(
      Types.DEVICES,
      `${this.device.id}/streams/${logStream['id']}/history/`,
      {
        since: '1weeks'
      },
      {}
    );

    logHistory.forEach((el) => {
      this.parseLog(el[1]);
    });

    this.countStream = countStream ? countStream.value : 0;
    this.totalVehiclesSeries = [this.countStream * 100];
    Vue.set(this.vehicleCountStreams[0], 'counter', count1Stream ? count1Stream.value : 0);
    Vue.set(this.vehicleCountStreams[1], 'counter', count2Stream ? count2Stream.value : 0);
    Vue.set(this.vehicleCountStreams[2], 'counter', count3Stream ? count3Stream.value : 0);
    Vue.set(this.vehicleCountStreams[3], 'counter', count4Stream ? count4Stream.value : 0);
    Vue.set(this.vehicleCountStreams[4], 'counter', count5Stream ? count5Stream.value : 0);
  }

  @Watch('device')
  async deviceChanged() {
    this.setDeviceStreams([]);
    if (this.mqtt_subscribe_topic){
      this.mqtt.unlisten(this.mqtt_subscribe_topic);
    }
    this.mqtt_subscribe_topic = '';
    try {
      this.active = 'tab-overview';
      this.loadingTabs = true;
      this.clearData();
      if (this.mqtt_version_by_class.has(this.device.class_name)) {
        this.mqtt_version = this.mqtt_version_by_class.get(this.device.class_name).mqtt_version;
      }
      this.mqtt_subscribe_topic = this.mqtt.getSingleDeviceSubscribe(this.device.id, this.mqtt_version);
      await this.fetchStreams();
      this.initMqtt();
      this.loadingTabs = false;
    }
    catch (error) {
      console.log('ERROR while watch device:', error);
      this.loadingTabs = false;
    }
  }
  
  async fetchSingleProperty(property_name, format){
    try {
      const property = await API.get(Types.DEVICES, `${this.device.id}/properties/`, { name: property_name });
      return property && property.results && property.results[0] && property.results[0].value ? property.results[0].value : format === 'json' ? {} : '';
    }catch (e) {
      return format === 'json' ? {} : '';
    }
  }

  clearData() {
    this.totalVehiclesSeries = [0];
    this.countStream = 0;
    this.vehicleCountStreams = [
      { vehicleSize: '1', counter: 0, icon: config.icons.trialerTruckIcon },
      { vehicleSize: '2', counter: 0, icon: config.icons.vanIcon },
      { vehicleSize: '3', counter: 0, icon: config.icons.carIcon },
      { vehicleSize: '4', counter: 0, icon: config.icons.smartCarIcon },
      { vehicleSize: '5', counter: 0, icon: config.icons.motorcycle }
    ];
    this.liveDataInfo = [];
  }

  initMqtt() {
    this.mqtt.init();
    this.listenToMqtt();
  }

  listenToMqtt() {
    this.mqtt.listen(this.mqtt_subscribe_topic,
      (msg, match) => {
        const mqtt_msg_data = this.mqtt.getMqttMsgData(msg, match, this.mqtt_version);

        if (mqtt_msg_data.stream_name === 'count') {
          this.countStream = Number(mqtt_msg_data.message);
          this.totalVehiclesSeries = [this.countStream * 100];
        }else if (mqtt_msg_data.stream_name === 'count_1') {
          Vue.set(this.vehicleCountStreams[0], 'counter', mqtt_msg_data.message);
        }else if (mqtt_msg_data.stream_name === 'count_2') {
          Vue.set(this.vehicleCountStreams[1], 'counter', mqtt_msg_data.message);
        }else if (mqtt_msg_data.stream_name === 'count_3') {
          Vue.set(this.vehicleCountStreams[2], 'counter', mqtt_msg_data.message);
        }else if (mqtt_msg_data.stream_name === 'count_4') {
          Vue.set(this.vehicleCountStreams[3], 'counter', mqtt_msg_data.message);
        }else if (mqtt_msg_data.stream_name === 'count_5') {
          Vue.set(this.vehicleCountStreams[4], 'counter', mqtt_msg_data.message);
        }else if (mqtt_msg_data.stream_name === 'log') {
          this.parseLog(mqtt_msg_data.message);
        }
      }
    );
  }

  parseLog(value) {
    const info = {
      date: value.slice(13, 32),
      direction: value.slice(34, 38) === 'CLOS' ? 'CLOSING' : value.slice(34, 38),
      averageSpeed: value.slice(53, 56),
      maxSpeed: value.slice(47, 50),
      classification: value.slice(4, 5)
    };
    if (this.liveDataInfo.length === 10) {
      this.liveDataInfo.pop();
      this.liveDataInfo.unshift(info);
    }
    else {
      this.liveDataInfo.unshift(info);
    }
  }

  mqttGetMsg(format, message) {
    return format === 'minimal' ? Number(message) : Number(message['content']);
  }


  beforeDestroy() {
    this.setDeviceStreams([]);
    this.mqtt.unlisten(this.mqtt_subscribe_topic);
  }
}
