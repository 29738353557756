import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

import vuex from '@/store';
import Vue from 'vue';
import API, { Types } from '@/modules/API';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import * as sunCalc from 'suncalc';
import moment from 'moment';
import momentZ from 'moment-timezone';
import store from 'store';

export const alerts_with_header_1 = ['Cabinet down', 'RS485_error', '5% from cabinet', '3 in a row', 'Cabinet controller down'];
export const alerts_with_header_2 = ['Phase'];
export const alerts_with_header_3 = ['Circuit down'];
export const single_unit_probability_alert_types = ['daytime_light_fault'];
export const single_unit_alert_types = ['Single Unit', 'LED Fault', 'partial_control', 'installation', 'electrical_fault', 'disconnected_pole', 'communication_fault'];
export const event_abnormal = 'abnormal data';
export const event_consumption_during_day = 'consumption during the day';
export const event_consumption_anomalies = 'consumption anomalies';

@Module({ namespaced: true })
export default class Global extends VuexModule {
  isLoading = true;
  isMobile = false;
  notify = null;
  lang = 'en';
  theme = 'default';
  isSidebarOpen = true;
  contentStyles = {};
  timezone = '';
  allProjectsTimezone = '';
  // clearMapTimeout = false;
  electricalPowerWindow = null;
  hasElectricalPower = false;
  eventsPeriod = {
    start: moment()
      .subtract(1, 'days')
      .toDate(),
    end: new Date()
  };
  deviceClicked = '';
  mqtt_version_by_class = new Map();
  pageTitle = '';
  editFilterMode = false;
  sensorPage = false;
  waterMeterPage = false;
  overview_selected_tab = '';
  dusk_dawn_data = {};
  cabinet_connection_alerts = {};
  day_of_week = 0;
  show_not_allowed_user_dialog = false;
  readonly_user = false;
  selected_managed_area = '';

  @Action init() {
    return new Promise((resolve) => resolve('logged in'))
      .then(() => vuex.dispatch('User/init'));
  }

  @Mutation
  setDeviceClicked(device_id) {
    this.deviceClicked = device_id;
  }

  @Mutation
  setIsReadonlyUser(is_readonly) {
    this.readonly_user = is_readonly;
  }

  @Mutation
  setDayOfWeek(day) {
    this.day_of_week = day;
  }

  @Mutation
  setNotAllowedUserDialog(is_open){
    this.show_not_allowed_user_dialog = is_open;
  }

  @Mutation
  setOverviewSelectedTab(tab) {
    this.overview_selected_tab = tab;
  }

  @Mutation
  setSelectedManagedArea(managed_area) {
    this.selected_managed_area = managed_area;
  }

  // @Mutation
  // setClearMapTimeout(clear) {
  //   this.clearMapTimeout = clear;
  // }

  @Mutation
  setProjectSunTime(){
    const project_default_location = (vuex.state as any).Props.project_default_location;
    const project_timezone = (vuex.state as any).Global.timezone || (vuex.state as any).Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    try {
      const times = sunCalc.getTimes(new Date(), project_default_location.lat, project_default_location.lng);
      if (times) {
        const dusk_dawn_data = {
          dusk: null,
          dawn: null,
          dusk_timestamp: null,
          dawn_timestamp: null,
          sunset_start: null,
          sunset_start_timestamp: null,
          sunrise: null,
          sunrise_timestamp: null
        };

        dusk_dawn_data.dusk = momentZ(times.dusk).tz(project_timezone);
        dusk_dawn_data.dawn = momentZ(times.dawn).tz(project_timezone);
        dusk_dawn_data.dusk_timestamp = dusk_dawn_data.dusk.valueOf();
        dusk_dawn_data.dawn_timestamp = dusk_dawn_data.dawn.valueOf(); 
        dusk_dawn_data.sunset_start = momentZ(times.sunsetStart).tz(project_timezone);
        dusk_dawn_data.sunset_start_timestamp = dusk_dawn_data.sunset_start.valueOf();
        dusk_dawn_data.sunrise = momentZ(times.sunrise).tz(project_timezone);
        dusk_dawn_data.sunrise_timestamp = dusk_dawn_data.sunrise.valueOf();
        
        this.dusk_dawn_data = dusk_dawn_data;
      }
    } catch (error) {
      console.log('Error while converting Sun Data:', error);
    }
  }

  @Mutation
  setPageTitle(title) {
    this.pageTitle = title;
  }

  @Mutation
  setEditFilterMode(value) {
    this.editFilterMode = value;
  }

  @Mutation
  setElectricalPowerWindow(value) {
    this.electricalPowerWindow = value;
  }

  @Mutation
  seHasElectricalPower(value) {
    this.hasElectricalPower = value;
  }

  @Action
  async addTimezoneProperty(project) {
    let timezone = '';
    const query = {
      query: {
        name: 'project.timezone'
      }
    };
    timezone = momentZ.tz.guess(true); // true for ignoring the cache
    let error = false;
    try {
      const result = await API.post(
        Types.COMPANIES,
        `${project.company}/${Types.PROJECTS}/${project.id}/properties`,
        { timezone },
        query
      );
      error = false;
      console.log('Property project.timezone was created successfully.');
    } catch {
      error = true;
      console.log(
        'Property project.timezone couldnt be created due to some issues.'
      );
    }

    error
      ? vuex.commit('Global/setTimezone', '')
      : vuex.commit('Global/setTimezone', timezone);
  }

  @Action
  async loadDeviceClasses() {
    const user_module = (vuex.state as any).User;
    const company_id = user_module.project 
      ? user_module.project.company === 'allProjects'
        ? user_module.project.projects[0].company
        : user_module.project.company
      : null;

    if (company_id) {
      try {
        const result = await API.get(Types.COMPANIES, `${company_id}/deviceclasses`);
        const deviceClasses = result ? result.results : [];
        const cabinet_with_new_format = deviceClasses.find((device_class) => device_class.name === 'sbc_cabinet');

        if (cabinet_with_new_format !== undefined){
          const alerts_types = await PropUtils.fetchSingleProperty(Types.COMPANIES, 'alerts.enum', 'json', `${company_id}/${Types.DEVICE_CLASSES}/${cabinet_with_new_format.id}`);
          if (alerts_types && typeof alerts_types === 'object'){
            vuex.commit('Global/setCabinetConnectionAlerts', alerts_types);
          }
        }

        const classes = new Map();
        await Promise.all(deviceClasses.map( async (device_class) => {
          const mqtt_protocol = await PropUtils.fetchSingleProperty(Types.COMPANIES, 'platform.mqtt_protocol', 'json', `${company_id}/${Types.DEVICE_CLASSES}/${device_class.id}`);
          if (mqtt_protocol && typeof mqtt_protocol === 'object' && mqtt_protocol.hasOwnProperty('version')){
            device_class.mqtt_version = mqtt_protocol.version;
          }else {
            device_class.mqtt_version = 3;
          }
          classes.set(device_class.name, device_class);
        }));

        vuex.commit('Global/setMqttVersions', classes);
      } catch (e) {
        console.log('There was problem getting devicesclasses for this project');
      }
    }
  }

  @Mutation
  setCabinetConnectionAlerts(alerts_enum){
    this.cabinet_connection_alerts = alerts_enum;
  }

  @Action setMobile(flag) {
    vuex.commit('Global/setMobileState', flag);
  }

  @Action throwNotify(e) {
    vuex.commit('Global/setNotify', e);
    setTimeout(() => vuex.commit('Global/setNotify', null));
  }

  @Action 
  async changeLang(data) {
    const dashboard_settings = (vuex.state as any).User.properties.find((property) => property.name === 'dashboard.settings');
    if (dashboard_settings && dashboard_settings.value) {
      await vuex.dispatch('User/updateUserProperty', {
        name: 'dashboard.settings',
        value: { ...dashboard_settings.value, lang: data.lang }
      });
      vuex.commit('Global/setLang', data.lang);
    }
  }

  @Action changeTheme(theme) {
    vuex.commit('Global/setTheme', theme);
  }

  @Mutation
  setMqttVersions(version_by_class) {
    this.mqtt_version_by_class = version_by_class;
  }
  
  @Mutation setTheme(theme) {
    this.theme = theme;
  }

  @Mutation setTimezone(timezone) {
    this.timezone = timezone;
  }

  @Mutation setAllProjectsTimezone(timezone) {
    this.allProjectsTimezone = timezone;
  }

  @Mutation setLoading(flag) {
    this.isLoading = flag;
  }

  @Mutation setIsSidebarOpen(isOpen) {
    this.isSidebarOpen = isOpen;
  }

  @Mutation setContentStyles() {
    if (!(vuex.state as any).User.isAuth || !(vuex.state as any).User.project || window.location.pathname === '/projects' || window.location.pathname === '/companies') {
      Vue.set(this.contentStyles, 'padding-left', '0px');
      Vue.set(this.contentStyles, 'padding-right', '0px');
      return;
    }

    this.lang === 'en'
      ? Vue.set(this.contentStyles, 'padding-right', '0px')
      : Vue.set(this.contentStyles, 'padding-left', '0px');

    if (this.isSidebarOpen) {
      this.lang === 'en'
        ? Vue.set(this.contentStyles, 'padding-left', '300px')
        : Vue.set(this.contentStyles, 'padding-right', '300px');
    } else {
      this.lang === 'en'
        ? Vue.set(this.contentStyles, 'padding-left', '80px')
        : Vue.set(this.contentStyles, 'padding-right', '80px');
    }
  }

  @Mutation setMobileState(flag) {
    this.isMobile = flag;
  }

  @Mutation setNotify(e) {
    this.notify = e;
  }

  @Mutation setLang(lang) {
    this.lang = lang;
  }

  @Mutation setProjectLang(properties) {
    const user = store.get('user');
    const userLangProp = PropUtils.getProperty(properties, 'dashboard.users_language');
    const defaultLanguageProp = PropUtils.getProperty(properties, 'dashboard.language');
    userLangProp && userLangProp.value[user.username]
      ? vuex.commit('Global/setLang', userLangProp.value[user.username])
      : defaultLanguageProp
      ? vuex.commit('Global/setLang', defaultLanguageProp.value)
      : vuex.commit('Global/setLang', 'en');
  }

  @Action changePeriod(data) {
    vuex.commit('Global/setPeriod', data);
  }

  @Mutation setPeriod(period) {
    this.eventsPeriod = period;
  }
}
