


















































































import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({
    components: {}
})

export default class OverviewTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
    @Global.State('lang') lang;

    // @ts-ignore
    @Prop() device;
    @Prop() metaDevice;
    @Prop() waterFlowStreamModified;
    @Prop() temperatureStream;
    @Prop() batteryLevelStream;

    estimated_address = false;

    get connected_status(){
        return this.device.sys___connected === 0 ? 'Disconnected' : this.device.sys___connected === 1 ? 'Connected' : 'Unknown';
    }

    get active_status() {
        return this.device.sys___active === 0 ? 'Inactive' : this.device.sys___active === 1 ? 'Active' : 'Unknown';
    }

    get address(){
        let device_address = this.metaDevice && this.metaDevice.address;
        if (!device_address) {
            device_address = this.metaDevice.estimated_address || 'N/A';
            this.estimated_address = device_address !== 'N/A';
        } else {
            device_address = device_address;
            this.estimated_address = false;
        }
        return device_address;
    }
}
