

















































import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({
    components: {}
})

export default class OverviewTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
    @Global.State('lang') lang;

    @Prop() metaDevice;

    get model() {
        return this.metaDevice && this.metaDevice['Model'] ? this.metaDevice['Model'] : 'N/A';
    }

    get manufacturer() {
        return this.metaDevice && this.metaDevice['Manufacturer'] ? this.metaDevice['Manufacturer'] : 'N/A';
    }

    get interface_data() {
        return this.metaDevice && this.metaDevice['Interface'] ? this.metaDevice['Interface'] : 'N/A';
    }
}

