




























































import Component from 'vue-class-component';
import TabsInfoMixin from '../../devices/TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { Props, Global, DeviceStreams } from '@/store';
import API, { Types } from '@/modules/API';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import VueApexCharts from 'vue-apexcharts';
import PropUtils from '@/modules/PropUtils';
import DatePicker from '@/pages/reports/components/DatePicker.vue';

@Component({
  components: {
    VueApexCharts,
    DatePicker
  }
})
export default class ConsumptionTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;
    @Props.State('list') projectProperties;
    @DeviceStreams.State('deviceStreams') deviceStreams;

    pickedPeriod = '1weeks';
    loading = true;
    loading_stream_data = false;
    start_date = null;
    end_date = null;
    chartOptions = {
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                    customIcons: []
                },
                export: {
                    csv: {
                        show: false,
                        filename: 'Water Flow',
                        columnDelimiter: ',',
                        headerCategory: 'time',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toString();
                        }
                    },
                    svg: {
                        filename: 'Water Flow'
                    },
                    png: {
                        filename: 'Water Flow'
                    }
                },
                autoSelected: 'zoom' 
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (val) => {
                return val.toFixed(2);
            }
        },
        stroke: {
            curve: 'smooth'
        },
        markers: {
            size: 0
        },
        title: {
            text: '',
            align: 'center',
            style: {
                fontFamily: 'Open , Arial'
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            }
        },
        yaxis: {
            labels: {
                offsetX: -10,
                formatter: (val) => {
                    return val.toFixed(2);
                }
            },
            title: {
                text: ''
            },
            style: {
                fontFamily: 'Open Sans, Arial, monospace',
                fontSize: '16px'
            }
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false
            }
        },
        tooltip: {
            shared: false,
            x: {
                format: 'dd/MM/yy HH:mm'
            },
            y: {
                formatter: (val) => {
                    return val;
                },
                title: {
                    formatter: (seriesName) => seriesName
                }
            }
        }
    };
    series = [{
        name: 'Water Flow',
        data: []
    }];

    periods_range = new Map();
    streamHistory = [];
    noData = false;

    async mounted() {
        this.loading = true;
        this.chartOptions.title.text = this.$t('Water Flow');
        this.chartOptions.yaxis.title.text = this.$t('Liter');
        this.chartOptions.tooltip.y.title.formatter = (seriesName) => ` ${this.$t(seriesName)}: `;
        
        this.setPeriodsRange();
        await this.updateData();
        this.setDefaultDates();
        this.loading = false;
    }

    getMapHeight(){
        const map = document.getElementsByClassName('vue-map-container');
        return map[0]['style']['height'];
    }

    setDefaultDates() {
        this.setStartDate(momentTZ().tz(this.projectTimezone).format('YYYY-MM-DD'));
        this.setEndDate(momentTZ().tz(this.projectTimezone).format('YYYY-MM-DD'));
    }

    setStartDate(start) {
        this.start_date = start;
    }

    setEndDate(end) {
        this.end_date = end;
    }

    setPeriodsRange(){
        const today_timestamp = moment().unix() * 1000;
        this.periods_range.set('1days', {from: moment(today_timestamp).subtract(1, 'days').valueOf(), to: today_timestamp});
        this.periods_range.set('1weeks', {from: moment(today_timestamp).subtract(7, 'days').valueOf(), to: today_timestamp});
        this.periods_range.set('1months', {from: moment(today_timestamp).subtract(1, 'months').valueOf(), to: today_timestamp});
        this.periods_range.set('3months', {from: moment(today_timestamp).subtract(3, 'months').valueOf(), to: today_timestamp});
        this.periods_range.set('6months', {from: moment(today_timestamp).subtract(6, 'months').valueOf(), to: today_timestamp});
        this.periods_range.set('1years', {from: moment(today_timestamp).subtract(6, 'months').valueOf(), to: today_timestamp});
    }

    @Watch('start_date')
    @Watch('end_date')
    @Watch('pickedPeriod')
    async updateData() {
        try {
            this.loading_stream_data = true;
            this.noData = false;
            this.streamHistory = [];
            const stream = PropUtils.getDeviceStream('water_flow_liters', this.deviceStreams);
            if (stream) {
                const period = this.pickedPeriod === 'custom' ? this.getCustomPeriod() : {since: this.pickedPeriod};
                this.streamHistory = await API.get(Types.DEVICES, `${this.device.id}/streams/${this.device.id}.water_flow_liters/history`, period);
                if (this.streamHistory.length){
                    this.streamHistory.reverse();
                    this.series = [{ name: 'Water Flow', data: this.streamHistory }];
                }else this.noData = true;
            }else this.noData = true;
            this.loading_stream_data = false;
        } catch (error) {
            console.log(error);
            this.noData = true;
            this.loading_stream_data = false;
        }   
    }

    getCustomPeriod(){
        if (this.start_date > this.end_date) {
            const temp = this.start_date;
            this.start_date = this.end_date;
            this.end_date = temp;
        }

        const start = moment(`${this.start_date} 00:00:00`).valueOf();
        const end = moment(`${this.end_date} 23:59:59`).valueOf();
        return {
            from: start,
            to: end
        };
    }

//   async getStreamLatestValue(deviceId, streamName) {
//     const response = await API.get(Types.DEVICES, `${deviceId}/streams/${deviceId}.${streamName}/latest/`);
//     return !response.timestamp && !response.lastvalue ? '' : !response.timestamp ? `${response.lastvalue}` : `${response.lastvalue}, ${this.convertTimestampToString(response.timestamp)}`;
//   }

//   convertTimestampToString(timestamp){
//     const date = new Date(timestamp);
//     return Utils.getDateString(date);
//   }

    get periods_list(){
        return [
            { text: this.$t('Last 24 Hours'), value: '1days' },
            { text: this.$t('Last Week'), value: '1weeks' },
            { text: this.$t('Last Month'), value: '1months' },
            { text: this.$t('Last 3 Months'), value: '3months' },
            { text: this.$t('Last 6 Months'), value: '6months' },
            { text: this.$t('Last Year'), value: '1years' },
            { text: this.$t('Custom'), value: 'custom' }
        ];
    }

    getFormattedDate(datetime) {
        const utcSmodified = moment.utc(datetime).format();

        return datetime
            ? momentTZ.tz(utcSmodified, this.projectTimezone).format('DD.MM.YYYY HH:mm:ss')
            : '';
    }
}
