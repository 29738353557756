import Vue from 'vue';
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  MutationAction
} from 'vuex-module-decorators';

import API, { Types } from '../../modules/API';
import Utils from '../../modules/Utils';
import vuex from '..';
import { i18n } from '@/main';
import { cloneDeep } from 'lodash';

export interface Group {
  id: string;
  name: string;
  description: string;
  total_devices: number;
}

@Module({ namespaced: true })
export default class Groups extends VuexModule {
  list: Group[] = [];

  @Action 
  async load(data: any = {}) {
    if (data.project_id === 'allProjects') return;
    const params = {
      p1: `${Types.PROJECTS}`,
      p2: 'groups/',
      p3: {},
      p4: { itemID: data.project_id }
    };

    if (data.next) {
      const url = data.next.split('v3/') && data.next.split('v3/')[1] && data.next.split('v3/')[1].split('/?page') && data.next.split('v3/')[1].split('/?page')[0];
      params.p1 = '';
      params.p2 = url;
      params.p3['page'] = data.next.split('/?page=') && data.next.split('/?page=')[1];
      delete params.p4.itemID;
    }

    if (data.next && (!params.p2 || !params.p3['page'])) return;

    const res = await API.get(params.p1, params.p2, params.p3, params.p4);
    if (res) {
      if (res.results) {
        vuex.commit('Groups/addGroupsList', res.results);
      }
      if (res.next && res.next !== data.next) {
        await vuex.dispatch('Groups/load', {project_id: data.project_id, next: res.next});
      }
    }
  }

  @Action({ commit: 'add' }) create(data) {
    return API.post(
      '',
      `${Types.PROJECTS}/${data.projectID}/groups/`,
      {
        name: data.name || '',
        description: data.description || '',
        devices: data.devices || [],
        mapping: data.mapping || []
      },
      {
        itemID: data.projectID
      }
    )
      .then((res) => {
        if (res === 'Mixed streams type were assigned to stream group') {
          throw new Error(res);
        }
        vuex.dispatch('Global/throwNotify', {
          type: 'success',
          title: `${i18n.t('Success')!}`,
          text: i18n.t(`Group successfully created`)
        });
        vuex.commit('Groups/add_start', {id: res.id, name: res.name, description: res.description, total_devices: res.devices.length});

        return res;
      })
      .catch((e) => {
        vuex.dispatch('Global/throwNotify', {
          type: 'error',
          title: `${i18n.t('Error')!}`,
          text: i18n.t(e.message || `Couldn't create group`)
        });
      });
  }

  @MutationAction({ mutate: ['list'] }) edit({
    groupID,
    data,
    projectID,
    callback = ({ error }) => {}
  }) {
    // @ts-ignore
    const list = this.state.list,
      index = Utils.find(list, groupID);
    if (!groupID || index === -1) return;

    delete data['id'];
    delete data['actions'];

    return API.put('', `${Types.PROJECTS}/${projectID}/groups/` + groupID, data)
      .then((res) => {
        if (res.hasOwnProperty('id') && res.hasOwnProperty('devices')) {
          vuex.dispatch('Global/throwNotify', {
            type: 'success',
            title: `${i18n.t('Success')!}`,
            text: i18n.t(`Group successfully updated`)
          });

          vuex.dispatch('Schedules/runSchedulerInServer');

          Vue.set(list, index, { ...res, total_devices: res.devices.length });
          callback({ error: false });
          return { list };
        } else {
          vuex.dispatch('Global/throwNotify', {
            type: 'error',
            title: `${i18n.t('Error')!}`,
            text: i18n.t(`Couldn't update group`)
          });
          callback({ error: true });
          return { list };
        }
      })
      .catch(() => {
        vuex.dispatch('Global/throwNotify', {
          type: 'error',
          title: `${i18n.t('Error')!}`,
          text: i18n.t(`Couldn't update group`)
        });
        callback({ error: true });
        return { list };
      });
  }

  @MutationAction({ mutate: ['list'] }) remove(data) {
    // @ts-ignore
    const list = this.state.list,
      index = Utils.find(list, data.groupID);
    if (!data.groupID || index === -1) return;

    return API.delete(
      Types.PROJECTS,
      `${data.projectID}/groups/` + data.groupID + `/`,
      null
    )
      .then(() => {
        vuex.dispatch('Global/throwNotify', {
          type: 'success',
          title: `${i18n.t('Success')!}`,
          text: i18n.t(`Group successfully deleted`)
        });

        list.splice(index, 1);
        return { list };
      })
      .catch(() => {
        vuex.dispatch('Global/throwNotify', {
          type: 'error',
          title: `${i18n.t('Error')!}`,
          text: i18n.t(`Couldn't delete group`)
        });

        return { list };
      });
  }

  @Mutation flush() {
    this.list = [];
  }

  @Mutation
  addGroupsList(groups_list){
    if (!groups_list) return;

    const new_list = groups_list.filter((new_group) => new_group.id && !this.list.find((group) => group.id === new_group.id));
    this.list = cloneDeep(this.list).concat(new_list);
  }

  @Mutation add(data: Group) {
    if (!data || !data.id || this.list.find((group) => group.id === data.id)) return;
    this.list = cloneDeep(this.list).concat([data]);
  }

  @Mutation add_start(data: Group) {
    if (!data || !data.id || Utils.find(this.list, data.id) !== -1) return;
    this.list = [data, ...this.list];
  }
}
