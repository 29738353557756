












import Vue from 'vue';
import Component from 'vue-class-component';
import { User } from '@/store';

@Component({})
export default class Error404 extends Vue {
  @User.State('project') project;
}
