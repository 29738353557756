import Vue from 'vue';
import Vuex from 'vuex';
import { namespace } from 'vuex-class';
Vue.use(Vuex);

class VuexStore {
  private static store = new Vuex.Store({});

  static add(name) {
    VuexStore.get().registerModule(name, require(`./modules/${name}`).default);
    return namespace(name);
  }

  static get() {
    return VuexStore.store;
  }
}

export default VuexStore.get();
export const Global = VuexStore.add('Global');
export const Groups = VuexStore.add('Groups');
export const Companies = VuexStore.add('Companies');
export const Projects = VuexStore.add('Projects');
export const Reports = VuexStore.add('Reports');
export const ProjectStreams = VuexStore.add('ProjectStreams');
export const DeviceStreams = VuexStore.add('DeviceStreams');
export const Props = VuexStore.add('Props');
export const Users = VuexStore.add('Users');
export const User = VuexStore.add('User');
export const MapSettings = VuexStore.add('MapSettings');
export const Errors = VuexStore.add('Errors');
export const Schedules = VuexStore.add('Schedules');
