import Vue from 'vue';
import { Mixin } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { Reports, User } from '@/store';
import PropUtils from '@/modules/PropUtils';
import { i18n } from '@/main';

@Mixin
export default class MapMixin extends Vue {
  @Prop() device;

  @User.State('project') project;
  @Reports.State('reportsList') reports;

  active = 'tab-control';

  get category() {
    const type = this.device['meta.category.category'];
    if (type) {
      return type.toLocaleLowerCase();
    }
  }

  get deviceName() {
    return this.device
      ? this.device.name
      : '';
  }

  get parentName() {
    let parent;
    const hasParent = this.device.parent_id;
    if (hasParent) {
      parent =
        this.reports && this.reports.find((report) => report.id === hasParent);
    }
    if (parent) {
      return this.getCategory(parent) + ' ' + parent.name;
    }
  }

  getCategory(device) {
    const type = device['meta.category.category'];
    if (type) {
      return type.toLocaleLowerCase();
    }
  }

  getOnlineState(state) {
    if (state === 0) {
      return 'Offline';
    } else if (state === 1) {
      return 'Online';
    } else if (state === 99) {
      return 'Online hub';
    } else if (state === -7) {
      return 'Restarting';
    }
  }

  checkCoord(coord) {
    if (!coord && coord === '0.0') {
      return;
    }
    if (typeof coord === 'string') {
      return Number(coord).toFixed(3);
    } else if (typeof coord === 'number') {
      return coord.toFixed(3);
    } else {
      return coord;
    }
  }

  sideScroll(element, direction, speed, distance, step){
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (direction === 'left'){
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance){
        clearInterval(slideTimer);
      }
    }, speed);
  }

  dataToShow() {
    const metaDevice = this.device['meta.device'];
    return metaDevice.pole_number
      ? `${`, ${i18n.t('Pole Number')}: ` + metaDevice.pole_number}`
      : '';
  }
}
