<template>
    <v-card class="rounded-card" style="background-color: #e5eff1 !important">
        <v-card-text class="title">
            <div class="title font-weight-bold mt-2 text-xs-center">{{ $t('Loading devices, please wait') }}...</div>
            <v-layout justify-center style="margin-top: 2px">
                <img height="150" width="150" src="@/assets/images/loading_gif.gif"/>
            </v-layout>
        </v-card-text>
    </v-card>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({})
export default class GifLoading extends Vue{
    @Global.State('lang') lang;
}

</script>
<style scoped>
    .title{
        font-family: 'Open Sans', 'Arial', monospace !important;
    }
</style>