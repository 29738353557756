












import Component from 'vue-class-component';
import Vue from 'vue';

@Component({})

export default class Notification extends Vue {
}
