


































import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import TabsInfoMixin from '../../devices/TabsInfoMixin';
import { Global } from '@/store';

@Component({
  components: {}
})

export default class ControlTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    // @ts-ignore
    @Prop() device;
    @Prop() power_level;
    @Prop() power_msg_sent;
    @Prop() power_stream_id;
    @Prop() mqtt;
    @Prop() mqtt_version;

    barrier_status = 'Open';
    btn_text = 'Close';
    barrier_is_open = true;

    mounted(){
        this.setBarrierStatus();
    }

    @Watch('power_level')
    @Watch('power_msg_sent')
    setBarrierStatus(){
        this.barrier_status = this.power_level === 0 || this.power_level === null ? 'Open' : 'Closed';
        this.barrier_is_open = this.power_level === 0 || this.power_level === null ? true : false;
        this.btn_text = this.power_msg_sent ? 'Message Sent' : this.barrier_status === 'Open' ? 'CLOSE' : 'OPEN';
    }

    get LightingButtonColor(){
        return this.power_level > 0 ? 'green' : 'red';
    }

    sendPowerMsg(){
        const power_value = this.power_level === 0 || this.power_level === null ? 254 : 0;
        const mqtt_publish_data = {
            version_num: this.mqtt_version,
            device_id: this.device.id,
            stream_id: this.mqtt_version === 3 ? `${this.power_stream_id}` : 'power',
            message_type: 'cmd',
            format: 'minimal',
            stream_value: power_value.toString(),
            options: {
                retain: false,
                qos: 1
            }
        };
        this.mqtt.publishToSingleDevice(mqtt_publish_data);
    }

}
