












import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class ConfirmDialog extends Vue {
  @Prop() color;
  @Prop() text;
  @Prop() action;
  @Prop () persistent;
  @Prop () cancelText;

  dialog = true;

  hideModal() {
    this.$emit('close');
  }

  confirmAction() {
    this.$emit('action', true);
  }
}
