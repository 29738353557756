import Vue from 'vue';

Vue.directive('resizable', (el, binding) => {
    const direction = binding.modifiers.x ? 'x' : 'y';
    const resizer = document.createElement('div');
    resizer.className = `sl-resizer direction-${direction}`;
    el.className = 'sl-resizer-container';

    el.querySelectorAll('.sl-resizer').forEach((sl) => sl.remove());
    el.appendChild(resizer);

    const startResize = (e) => {
        if (direction === 'x') el.style.width = (e.clientX - el.offsetLeft + document.querySelector('#content .scroller').scrollLeft) + 'px';
        if (direction === 'y') el.style.height = (e.clientY - el.offsetTop + document.querySelector('#content .scroller').scrollTop) + 'px';
        el.dispatchEvent(new Event('resize'));
    };

    const stopResize = (e) => {
        window.removeEventListener('mousemove', startResize, false);
        window.removeEventListener('mouseup', stopResize, false);
    };

    resizer.addEventListener('mousedown', (e) => {
        window.addEventListener('mousemove', startResize, false);
        window.addEventListener('mouseup', stopResize, false);
    }, false);
});