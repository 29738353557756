














import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

@Component({
    components: {
        ConfirmDialog
    }
})

export default class DeleteFilterView extends Vue {
    @Prop() filter;

    deleteDialog = false;

    deleteView() {
        this.deleteDialog = false;
        this.$emit('delete');
    }

}
