<template>
    <div v-if="showError">
        <v-snackbar
            v-model="show"
            color="red"
            :timeout="0"
            auto-height
            top>
            {{error}}
            <v-btn flat dark
                   color="white"
                   @click.native="hideAlert">
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
  export default {
    name: 'GlobalSnackbar',
    computed: {
      error() {
        return this.$store.state.Errors.error;
      },
      showError() {
        return this.$store.state.Errors.showError;
      }
    },
    data() {
      return {
        show: true
      };
    },
    methods: {
      hideAlert() {
        this.show = false;
        this.$store.commit('Errors/setShowError', false);
      }
    }
  };
</script>
