



import Vue from 'vue';
import Component from 'vue-class-component';
import {Watch, Prop} from 'vue-property-decorator';
import AceEditor from 'vue2-ace-editor';
import 'brace/ext/language_tools';
import 'brace/theme/chrome';
import 'brace/mode/json';
import Utils from '@/modules/Utils';
import {Props} from '@/store';

@Component({
    components: {AceEditor}
})
export default class PropEditor extends Vue {
    @Props.Action('editProp') edit;
    @Prop() builtin;
    @Prop() plain;

    Utils = Utils;

    isOpened = false;
    type = '';
    prop = null;
    contents = {} as any;

    mounted() {
        this.onBuiltinChanged();
    }

    @Watch('isOpened') onVisibilityChanged() {
        if (!this.isOpened) {
            this.close();
        }
    }

    @Watch('builtin') onBuiltinChanged() {
        if (this.builtin)  {
            this.open(this.builtin);
        }
    }

    open(prop, fields?: string, type = 'text') {
        this.isOpened = true;
        this.prop = JSON.parse(JSON.stringify(prop));
        fields = (fields || '').indexOf(':') === -1 ? '' : fields.split(':')[1];

        if (fields) {
            this.type = type;
            (fields + '').replace(' ', '').split(',').forEach((field) => this.$set(this.contents, field, this.prop.value[field] || ''));
        } else {
            this.$set(this.contents, '_', this.prop.value || '');
            this.type = 'text';
        }

        for (const k in this.contents) {
            if (typeof this.contents[k] === 'object') {
                this.$set(this.contents, k, JSON.stringify(this.contents[k], null, '\t'));

            }
        }
    }

    close() {
        this.type = '';
        this.prop = null;
        this.contents = {};
        this.isOpened = false;
        this.$emit('close');
    }

    apply() {
        for (const k in this.contents) {
            if (k === '_') {
                this.prop.value = JSON.parse(this.contents[k]);
            } else {
                this.prop.value[k] = this.contents[k];
            }
        }

        if ((this.$listeners as any).apply) {
            this.$emit('apply', this.prop);
        } else {
            this.save(this.prop.value);
        }
        this.close();
    }

    save(value: any = '{}', prop = this.prop, callback?, msg?) {
        if (!prop) {
            return;
        }

        this.edit({
            _notify_msg: msg,
            name: prop.name,
            objectID: prop.objectID,
            objectType: prop.objectType,
            value: typeof value === 'string' ? JSON.parse(value) : value
        }).then(() => {
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    getDefaultPropEditorValue() {
        let value = this.prop.value || '';
        if (typeof value !== 'string') {
            value = JSON.stringify(value, null, '\t');
        }
        return value;
    }

    setDefaultPropEditorValue() {
        this.$set(this.contents, '_', this.getDefaultPropEditorValue());
    }

    setCoords(coords) {
        if (this.contents.lat !== undefined && this.contents.lng !== undefined) {
            this.$set(this.contents, 'lat', coords.lat);
            this.$set(this.contents, 'lng', coords.lng);
        }
    }
}
