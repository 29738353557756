








































import Component from 'vue-class-component';
import TabsInfoMixin from '../devices/TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { Global, DeviceStreams } from '@/store';
import moment from 'moment';
import PropUtils from '@/modules/PropUtils';
import Utils from '@/modules/Utils';
import VueApexCharts from 'vue-apexcharts';

@Component({
  components: {
    VueApexCharts
  }
})

export default class StreamChart extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @DeviceStreams.State('deviceStreams') deviceStreams;
  @Global.State('lang') lang;

  @Prop() streamName;
  @Prop() name;
  @Prop() unit;
  @Prop() pickedPeriod;
  @Prop() power_consumption_history;

  loading = false;
  noData = false;
  streamHistory = [];
  series = [{
    name: '',
    data: null
  }];
  
  sumPerPeriod = null;
  first_loading = true;

  chartOptions = {
    chart: {
      type: 'area',
      stacked: true,
      height: 350,
      toolbar: {
        show: true,
        export: {
          csv: {
            show: false,
            filename: '',
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toString();
            }
          },
          svg: {
            filename: ''
          },
          png: {
            filename: ''
          }
        },
        autoSelected: 'zoom' 
      }  
    },
    stroke: {
      curve: 'stepline'
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: '',
      align: 'center',
      style: {
        fontSize: '16px',
        fontFamily: 'Open Sans, Arial, monospace'
      }
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          return parseInt(val, 10) === val ? val : val.toFixed(3);
        }
      },
      title: {
        text: '',
        offsetX: -20,
        style: {
          fontSize: '16px',
          fontFamily: 'Open Sans, Arial, monospace'
        }
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
       datetimeUTC: false
      }
    },
    tooltip: {
      shared: false,
      x: {
        format: 'dd/MM/yy HH:mm'
      },
      y: {
        formatter: (val) => {
          return val.toFixed(3);
        }
      }
    }
  };

  async mounted() {
    this.series = [{
      name: this.$t(this.name),
      data: []
    }];
    this.updateChartOptions();
    await this.updateStreams();
    this.first_loading = false;
  }

  updateChartOptions(){
    this.chartOptions.title.text = this.$t(this.name);
    this.chartOptions.chart.type = this.streamName === 'power_consumption' ? 'area' : 'line';
    this.chartOptions.chart.toolbar.export.csv.filename = this.$t(this.name);
    this.chartOptions.yaxis.title.text = this.unit ? this.unit : '';
    this.chartOptions.stroke.curve = this.streamName === 'power_consumption' ? 'stepline' : 'smooth';
    this.chartOptions.chart.toolbar.export.csv.headerCategory = this.$t('Date');
  }

  @Watch('device')
  @Watch('streamName')
  @Watch('pickedPeriod')
  async updateStreams() {
    try {
      this.loading = true;
      let stream;
      
      if (this.streamName === 'power_consumption'){
        if (!this.first_loading){
          await this.$emit('powerConsumptionChanged');
        }else this.updatePowerConsumptionData();
      }else{
        stream = PropUtils.getDeviceStream(this.streamName, this.deviceStreams);
        if (stream) {
          this.streamHistory = await Utils.fetchSingleStreamHistory({
            device: this.device,
            stream,
            period: this.pickedPeriod
          });
          this.streamHistory.reverse();
          this.updateGraphData();
        }else this.noData = true;
        this.loading = false;
      }

    } catch (error) {
      console.log(error);
      this.noData = true;
      this.loading = false;
    }   
  }

  updateGraphData(){
    if (this.streamName === 'power_consumption'){
      const data = [];
      this.streamHistory.forEach((history, index) => {
        data.push(history);
        if (index < this.streamHistory.length - 1) data.push([history[0], this.streamHistory[index + 1][1]]);
      });
      this.series = [{ name: this.$t(this.name), data }];
    }
    else this.series = [{ name: this.$t(this.name), data: this.streamHistory }];
    this.updateChartOptions();

    this.noData = this.streamHistory.length ? false : true;
  }

  @Watch('power_consumption_history')
  updatePowerConsumptionData(){
    this.streamHistory = this.power_consumption_history;
    const reducer = (accumulator, currentValue) => accumulator + currentValue[1];
    const sum = this.streamHistory.reduce(reducer, 0);
    this.sumPerPeriod = (sum / 1000).toFixed(3) + ' kWh';
    this.updateGraphData();
    this.loading = false;
  }

  getTime(time) {
    return time 
      ? moment(time).format('L') + ' ' + moment(time).format('HH:mm:ss')
      : '';
  }
}
