import { VuexModule, Module, Mutation } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Errors extends VuexModule {
  error: string = '';
  showError: boolean = false;

  @Mutation
  setError(data: any) {
    this.error = data;
  }

  @Mutation
  setShowError(data: any) {
    // this.showError = data;
  }
}
