
















































































































































import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';
import Utils from '@/modules/Utils';
import API, { Types } from '@/modules/API';
import { setDeviceProperty } from '@/modules/ApiUsers';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class AdvancedTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('readonly_user') readonly_user;
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;

  // @ts-ignore
  @Prop() device;
  @Prop() mqtt;
  @Prop() propsList;
  @Prop() deviceStreams;
  @Prop() deviceFixture;
  @Prop() statusFromMQTT;
  @Prop() showTemp;
  @Prop() deviceTemp;
  @Prop() mqtt_version;

  dataToShowTondo = {};
  selectedAction = '';
  actionItemsList: any = {};
  hardwareidProp = null;
  scheduleProp = null;
  configProp = null;
  providersProp = null;
  actionsListProp = null;
  disconnectAlert = null;
  cellularStatus = null;
  allegroFlag = null;
  driverProp = null;
  language = null;
  ip_address = null;
  
  async mounted() {
    this.language = this.lang;
    await this.getStreams();
    this.loadProperties();
    await this.loadDataToShow();
  }

  async getStreams() {
    await API.get(
      Types.DEVICES,
      `${this.device.id}/properties`,
      { name: 'meta.driver' },
      {}
    ).then((res) => {
      if (
        res.results &&
        res.results.length === 1 &&
        res.results[0].value.hasOwnProperty('dali')
      ) {
        this.driverProp = res.results[0].value;
        this.allegroFlag = res.results[0].value.dali.toString();
      }
    });
    await API.get('devices', this.device.id + '/streams/', {}).then((res) => {
      if (res && res.hasOwnProperty('results')) {
        const cellular = res.results.find(
          (stream) => stream.name === 'cellular_status'
        );
        const disconnect = res.results.find(
          (stream) => stream.name === 'disconnect_alert'
        );
        if (disconnect) {
          this.disconnectAlert = disconnect.value;
        }
        if (cellular && cellular.value) {
          const tojson = cellular.value.replaceAll(`'`, `"`);
          const cellularS = JSON.parse(tojson);
          if (
            cellularS &&
            typeof cellularS === 'object' &&
            cellularS.hasOwnProperty('state')
          ) {
            this.cellularStatus = cellularS.state;
          } else {
            const cellularS2 = JSON.parse(cellularS);
            if (
              cellularS2 &&
              typeof cellularS2 === 'object' &&
              cellularS2.hasOwnProperty('state')
            ) {
              this.cellularStatus = cellularS2.state;
            }
          }
        }
      }
    });
  }

  setDisconnect() {
    API.post(
      '',
      `${Types.PROJECTS}/${this.project.id}/${Types.DEVICES}/${this.device.id}/streams/disconnect_alert/evt/`,
      { content: 2 },
      {},
      'v4'
    ).then(() => {
      this.disconnectAlert = 2;
    });
  }

  changeAllegroFlag(value) {
    const newDriverProp = {
      ...this.driverProp,
      dali: value === 'false' ? false : true
    };
    setDeviceProperty(this.device.id, 'meta.driver', newDriverProp).then(
      (res) => {
        this.dataToShowTondo['allegro_flag'] = value;
      }
    );
  }

  loadProperties() {
    const hardwareidProp = this.propsList.results.find((el) => el.name === 'device.hardwareid');
    this.hardwareidProp = hardwareidProp ? hardwareidProp.value : null;

    const scheduleProp = this.propsList.results.find((el) => el.name === 'device.schedule');
    this.scheduleProp = scheduleProp ? scheduleProp.value : null;

    const providersProp = this.propsList.results.find((el) => el.name === 'meta.provider_id');
    this.providersProp = providersProp ? providersProp.value : null;

    const configProp = this.propsList.results.find((el) => el.name === 'device.config');
    this.configProp = configProp ? configProp.value : null;

    const ip_address = this.propsList.results.find((el) => el.name === 'meta.ipaddress');
    this.ip_address = ip_address && ip_address.value && ip_address.value.ipaddress ? ip_address.value.ipaddress : null;

    const actionsListProp = this.propsList.results.find((el) => el.name === 'dashboard.stream_commands');
    this.actionsListProp = actionsListProp ? actionsListProp.value : null;

    if (this.actionsListProp && this.actionsListProp.command) {
      const obj = {};
      this.actionsListProp.command.forEach((el) => {
        obj[el['name']] = el['value'];
      });
      this.actionItemsList = obj;
    }
  }

  async loadDataToShow() {
    try {
      let streamActive = Utils.getStream('sys___active', this.deviceStreams);
      streamActive = streamActive ? streamActive.value : null;
      let streamConnected = Utils.getStream('sys___connected', this.deviceStreams);
      streamConnected = streamConnected ? streamConnected.value : null;
      const stremCellid = Utils.getStream('cellid', this.deviceStreams);
      const streamRssi = Utils.getStream('rssi', this.deviceStreams);
      const sysActiveChangeTime = await this.getStreamChangeTime('sys___active');
      const sysConnectedChangeTime = await this.getStreamChangeTime('sys___connected');

      this.dataToShowTondo = {
        ID: this.device['id'],
        Name: this.device['name'],
        ['Hardware id and mac']:
          typeof this.hardwareidProp === 'string'
            ? JSON.parse(this.hardwareidProp)
            : this.hardwareidProp,
        ['Driver type']: this.deviceFixture
          ? this.deviceFixture['type']
          : 'N/A',
        ['Providers']: this.providersProp || null,
        Location: Utils.deviceLocation(this.device),
        ['IP Address']: this.ip_address,
        ['Has Schedule']:
          this.scheduleProp &&
          this.scheduleProp.schedule &&
          this.scheduleProp.schedule.length > 0
            ? 'Yes'
            : 'No',
        Config: this.configProp
          ? typeof this.configProp === 'string'
            ? JSON.parse(this.configProp)
            : this.configProp
          : 'N/A',
        ['cellid']: stremCellid ? stremCellid['value'] : 'N/A',
        ['rssi']: streamRssi ? streamRssi['value'] : 'N/A',
        ['sys___connected']: streamConnected !== null
          ? streamConnected === 1
            ? `${this.$t('Connected')} ${this.$t('since')} ${sysConnectedChangeTime}`
            : streamConnected === 0 
            ? `${this.$t('Disconnected')} ${this.$t('since')} ${sysConnectedChangeTime}`
            : this.$t('N/A')
          : this.$t('N/A'),
        ['sys___active']: streamActive !== null
            ? streamActive === 1
              ? `${this.$t('Active')} ${this.$t('since')} ${sysActiveChangeTime}`
              : streamActive === 0 
              ? `${this.$t('Inactive')} ${this.$t('since')} ${sysActiveChangeTime}`
              : streamActive === -1 
              ? `${this.$t('Maintenance')} ${this.$t('since')} ${sysActiveChangeTime}`
              : streamActive === -3
              ? `${this.$t('In Installation')} ${this.$t('since')} ${sysActiveChangeTime}`
              : streamActive === -9
              ? `${this.$t('Fault In Care')} ${this.$t('since')} ${sysActiveChangeTime}`
              : this.$t('N/A')
            : this.$t('N/A'),
        disconnect_alert: this.disconnectAlert ? this.disconnectAlert : 'N/A',
        allegro_flag: this.allegroFlag ? this.allegroFlag : 'N/A',
        ['Celular status']:
          this.cellularStatus !== null ? this.cellularStatus : 'N/A'
      };
    } catch (error) {
      console.log('error while assign dataToShow values', error);
    }
  }

  async getStreamChangeTime(stream_name) {
    if (this.device[`${stream_name}__modified`]) {
      return Utils.convertTimestamp(this.device[`${stream_name}__modified`] * 1000, 'DD.MM.YYYY HH:mm', this.projectTimezone);
    }else {
      const stream_last_value = await Utils.fetchStreamLatestValue(this.device.id, stream_name);
      return stream_last_value && stream_last_value.timestamp 
        ? Utils.convertTimestamp(stream_last_value.timestamp , 'DD.MM.YYYY HH:mm', this.projectTimezone)
        : this.$t('N/A');
    }
  }

  get isActionSelected() {
    return this.selectedAction.length > 0;
  }

  // getDeviceTemperature() {
  //   this.publishMqttMessage('AT+m=AT+QTEMP', 'command');
  // }

  runSelectedAction() {
    if (!this.selectedAction) {
      console.log('action is not selected');
    } else {
      this.publishMqttMessage(
        this.actionItemsList[this.selectedAction],
        'command'
      );
    }
  }

  publishMqttMessage(message, streamName) {
    const mqtt_publish_data = {
      version_num: this.mqtt_version,
      device_id: this.device.id,
      stream_id: this.mqtt_version === 3 ? `${this.device.id}.${streamName}` : streamName,
      message_type: 'cmd',
      format: 'minimal',
      stream_value: message,
      options: {
        retain: false,
        qos: 1
      }
    };

    this.mqtt.publishToSingleDevice(mqtt_publish_data);
  }
  @Watch('lang')
  setLang() {
    this.language = this.lang;
  }
}
