










import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({
  components: {
    VueApexCharts
  }
})
export default class LineChart extends Vue {
    @Global.State('lang') lang;

    @Prop() series;
    @Prop() type;
    @Prop() chart_title;
    @Prop() yaxis_title;
    @Prop() yaxis_min_value;
    @Prop() yaxis_max_value;
    @Prop() tick_amount;
    @Prop() annotations;
    @Prop() height;
    @Prop() tooltip_formatter;
    @Prop() yaxis_formatter;

    loading = true;
    chartOptions = {
        chart: {
            type: 'area',
            stacked: true,
            height: 300,
            toolbar: {
                show: true,
                export: {
                    csv: {
                        show: false,
                        filename: '',
                        columnDelimiter: ',',
                        headerCategory: 'Date',
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toString();
                        }
                    },
                    svg: {
                        filename: ''
                    },
                    png: {
                        filename: ''
                    }
                    },
                    autoSelected: 'zoom' 
            }  
        },
        stroke: {
            curve: 'stepline'
        },
        dataLabels: {
            enabled: false
        },
        yaxis: {
            title: {
                text: '',
                offsetX: -10,
                style: {
                    fontSize: '14px',
                    fontFamily: 'Open Sans, Arial, monospace'
                }
            },
            labels: {
                offsetX: 0,
                style: {
                    fontFamily: 'Open Sans, Arial, monospace'
                },
                formatter: (val) => +val
            },
            min: 0
        },
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: false,
                style: {
                    fontFamily: 'Open Sans, Arial, monospace'
                }
            }
        },
        annotations: {},
        tooltip: {
            shared: false,
            x: {
                format: 'dd/MM/yy HH:mm:ss'
            },
            y: {
                formatter: (val) => +val
            }
        }
    };

    mounted() {
        this.loading = true;
        this.chartOptions.chart.toolbar.export.csv.filename = this.$t(this.chart_title);
        this.chartOptions.chart.toolbar.export.png.filename = this.$t(this.chart_title);
        this.chartOptions.chart.toolbar.export.svg.filename = this.$t(this.chart_title);
        this.chartOptions.yaxis.title.text = this.$t(this.yaxis_title);
        this.chartOptions.yaxis.title.text = this.$t(this.yaxis_title);
        this.chartOptions.annotations = this.annotations || {};
        if (this.height) this.chartOptions.chart.height = this.height;
        if (this.yaxis_min_value === 0 || this.yaxis_min_value) this.chartOptions.yaxis['min'] = this.yaxis_min_value;
        if (this.yaxis_max_value === 0 || this.yaxis_max_value) this.chartOptions.yaxis['max'] = this.yaxis_max_value;
        if (this.tick_amount) this.chartOptions.yaxis['tickAmount'] = this.tick_amount;
        if (this.tooltip_formatter) this.chartOptions.tooltip.y.formatter = this.tooltip_formatter;
        if (this.yaxis_formatter) this.chartOptions.yaxis.labels.formatter = this.yaxis_formatter;
        if (this.lang !== 'en') this.chartOptions.yaxis.labels.offsetX = -12;
        
        this.loading = false;
    }
}
