







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);
am4core.addLicense('CH351911686');

@Component({})
export default class FullViewReportPie extends Vue {
    @Global.State('lang') lang;

    @Prop() title;
    @Prop() series;
    @Prop() threshold_value;
    @Prop() unit;
    
    chart: am4charts.XYChart = null;

    mounted() {
        this.setChartData();
    }

    setChartData() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }

        // Create chart instance
        const chart = am4core.create('chartdiv', am4charts.XYChart);
        chart.rtl = this.lang !== 'en';

        // Add data
        chart.data = this.series;
        chart.dateFormatter.dateFormat = 'd/M/yyyy HH:mm';

        // Create axes
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 50;
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        const series = chart.series.push(new am4charts.StepLineSeries());
        series.dataFields.valueY = 'value';
        series.dataFields.dateX = 'date';
        series.strokeWidth = 2;
        series.stroke = am4core.color('#008ffb');
        series.minBulletDistance = 10;
        series.name = this.title;
        series.tooltipText = this.unit ? `{dateX}: [bold]{valueY} ${this.unit}[/]` : `{dateX}: [bold]{valueY}[/]`;
        series.tooltip.pointerOrientation = 'vertical';
        series.tooltip.background.cornerRadius = 20;
        series.tooltip.label.padding(12, 12, 12, 12);
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color('#008ffb');

        if (this.threshold_value) {
            // Create value axis range
            const range = valueAxis.axisRanges.create();
            range.value = this.threshold_value;
            range.grid.stroke = am4core.color('red');
            range.grid.strokeWidth = 2;
            range.grid.strokeOpacity = 1;
        }

        // Add scrollbar
        const scrollbarX = new am4core.Scrollbar();
        scrollbarX.marginBottom = 20;
        chart.scrollbarX = scrollbarX;

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = series;

        this.chart = chart;
    }


    beforeDestroy() {
        if (this.chart) {
            this.chart.dispose();
            this.chart = null;
        }
    }
}
