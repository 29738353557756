































































































import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({
    components: {}
})

export default class OverviewTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
    @Global.State('lang') lang;

    // @ts-ignore
    @Prop() device;
    @Prop() metaDevice;
    @Prop() water_level;
    @Prop() bottom_float_status;
    @Prop() top_float_status;   
    @Prop() water_height_trigger;

    estimated_address = false;

    get connected_status(){
        return this.device.sys___connected === 0 ? 'Disconnected' : this.device.sys___connected === 1 ? 'Connected' : 'Unknown';
    }

    get active_status() {
        return this.device.sys___active === 0 ? 'Inactive' : this.device.sys___active === 1 ? 'Active' : 'Unknown';
    }

    get address(){
        let device_address = this.metaDevice && this.metaDevice.address;
        if (!device_address) {
            device_address = this.metaDevice.estimated_address || 'N/A';
            this.estimated_address = device_address !== 'N/A';
        } else {
            device_address = device_address;
            this.estimated_address = false;
        }
        return device_address;
    }

    get top_float_height(){
        return this.metaDevice.hasOwnProperty('top_float_height') && this.metaDevice['top_float_height'] !== ''
            ? `${this.metaDevice['top_float_height']} ${this.$t('cm')}`
            : `${this.$t('N/A')}`;
    }

    get bottom_float_height(){
        return this.metaDevice.hasOwnProperty('bottom_float_height') && this.metaDevice['bottom_float_height'] !== '' 
            ? `${this.metaDevice['bottom_float_height']} ${this.$t('cm')}` 
            : `${this.$t('N/A')}`;
    }

    get threshold(){
        return this.metaDevice.hasOwnProperty('threshold') && this.metaDevice['threshold'] !== '' 
            ? `${this.metaDevice['threshold']} ${this.$t('cm')}`
            : `${this.$t('N/A')}`;
    }
}
