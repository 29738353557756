import moment from 'moment';
import { i18n } from '../../../../main';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import mapConfig from '@/pages/mapView/components/map/map.config';
import vuex from '@/store';
import { FloodSensor } from '@/modules/Types';

export interface Device {
  id: string;
  name: string;
  category: string;
  dmodified: string;
  sys_online: string;
}

export interface CabinetTime {
  on_time: string;
  off_time: string;
}

class DeviceFactory implements Device {
  id: string;
  name: string;
  category: string;
  metaDevice: object;
  sysConnected: string;
  address: string;
  estimated_address: boolean;
  location: string;
  dmodified: any;
  sys_online: string;
  online: string;
  fault: any;
  metaType: string;
  device: any;
  cabinetTime: CabinetTime;
  astronomicalClock: boolean;
  part_number: string;
  text_style: string;
  div_style: string;
  clock_style: string;

  constructor(device) {
    this.id = device.id;
    this.name =
      device.class_name === 'CONTAINER'
        ? `container ${device.container_id}`
        : device.name;
    const category = device['meta.category.category']
      ? device['meta.category.category']
      : '';
    this.category = category ? category.toLocaleLowerCase() : '';
    this.fault = device.fault > 0 ? 'Fault' : 'OK';
    this.online = device.sys___active === 0 ? 'Disconnected' : device.sys___active === 1 ? 'Connected' : 'N/A';
    this.metaType = device['meta.type.type'];
    this.dmodified = this.getTime(device.dmodified_ts * 1000);
    this.device = device;
    this.metaDevice = device['meta.device'];
    this.sysConnected =
      device.sys___connected === 0
        ? i18n.t('Disconnected')
        : device.sys___connected === 1
        ? i18n.t('Connected')
        : i18n.t('N/A');
    const device_address = this.metaDevice[Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']) ? 'cabinet_address' : 'address'];
    if (!device_address) {
      this.address = this.metaDevice['estimated_address'] || 'N/A';
      this.estimated_address = this.address !== 'N/A';
    } else {
      this.address = device_address;
      this.estimated_address = false;
    }
    this.location = `${device['meta.location'].lat.toFixed(3)}, ${device['meta.location'].lng.toFixed(3)}`;
    let cabinet_next_activation = (vuex.state as any).ProjectStreams.next_activation_by_cabinet.get(this.device.id);
    cabinet_next_activation = cabinet_next_activation && cabinet_next_activation.slice(-1)[0][1];
    this.cabinetTime = cabinet_next_activation || { on_time: '', off_time: '' };
    this.astronomicalClock = Utils.hasTondoClass(this.device.class_name) && PropUtils.hasAstronomicalClock(device.id);
    this.part_number = device['meta.part_number.part_number'];
    this.text_style = 'font-weight: 850; margin-top: 2px';
    this.clock_style = 'margin-top: 2px;';
    this.div_style = 'padding-right: 10px; padding-left: 10px; flex-wrap: wrap; font-family: Open Sans, Arial, monospace !important;';
  }

  getTime(time) {
    if (time) {
      return moment(time).format('L') + ' ' + moment(time).format('hh:mm:ss');
    } else {
      return '';
    }
  }

  getPopUpText() {
    switch (this.category) {
      case 'fixture':
        return this.getFixtureInformation();
      case 'motion sensor':
        return this.getMotionSensorInformation();
      case 'flood sensor':
        return this.getFloodSensorInformation();
      case 'barrier':
        return this.getFloodSensorInformation();
      case 'asset':
        return this.getAssetInformation();
      case 'video':
        return this.getVideoInformation();
      case 'cabinet':
        return this.getCabinetInformation();
      case 'hub':
        return this.getCabinetInformation();
      case 'water meter':
        return this.getWaterMeterInformation();
      case 'container':
        return this.getContainerInformation();
      default:
        return this.getCommonProperties() + ' </div>';
    }
  }

  getWaterMeterInformation(){
    const openDiv = `<div style="${this.div_style}">`;
    const id = `<b style="${this.text_style}">${i18n.t('ID')}: </b> ${this.id}<br/>`;
    const status = `<b style="${this.text_style}">${i18n.t('Status')}: </b> ${this.sysConnected}<br/>`;
    const address = `<b style="${this.text_style}">${i18n.t('Address')}:</b> ${
      this.address
    } ${this.estimated_address ? `(${i18n.t('estimated')})` : ''} <br/>`;
    const location = `<b style="${this.text_style}">${i18n.t(
      'Location'
    )}: </b> ${this.location}<br/>`;
    const name = `<span><b style="${this.text_style}">${i18n.t('Name')}:</b> ${
      this.name
    }</span><div>`;
    const closeDiv = '</div>';
    return openDiv + id + status + address + location + name + closeDiv;
  }

  getContainerInformation() {
    const openDiv = `<div style="${this.div_style}">`;
    const container_id = `<b style="${this.text_style}">${i18n.t(
      'Container ID'
    )}: </b> ${this.device.container_id}<br/>`;
    const poleNumber = `<b style="${this.text_style}">${i18n.t(
      'Pole Number'
    )}: </b> ${
      this.metaDevice ? this.metaDevice['pole_number'] || 'N/A' : 'N/A'
    }<br/>`;
    const cabinetId = `<b style="${this.text_style}">${i18n.t(
      'Cabinet ID'
    )}: </b> ${
      this.metaDevice ? this.metaDevice['cabinet_id'] || 'N/A' : 'N/A'
    }<br/>`;
    const address = `<b style="${this.text_style}">${i18n.t('Address')}: </b> ${
      this.address
    }<br/>`;
    const closeDiv = '</div>';
    return openDiv + container_id + poleNumber + cabinetId + address + closeDiv;
  }

  getCommonProperties() {
    let text;
    const openDiv = `<div style="${this.div_style}">`;
    const name = `<span><b style="${this.text_style}">${i18n.t('Name')}:</b> ${
      this.name
    }</span><div>`;
    const address = `<b style="${this.text_style}">${i18n.t('Address')}:</b> ${
      this.address
    } ${this.estimated_address ? `(${i18n.t('estimated')})` : ''} <br/>`;
    const location = `<b style="${this.text_style}">${i18n.t(
      'Location'
    )}: </b> ${this.location}<br/>`;
    const fault = this.fault
      ? `<b style="${this.text_style}">${i18n.t('Status')}:</b> `
      : '';
    const state = `<b>${
      this.online === 'Connected' ? i18n.t('Connected') : i18n.t('Disconnected')
    }</b> `;
    const time = `<b>${this.dmodified}</b><br/>`;

    text = openDiv + fault + state + time + address + location + name;

    return text;
  }

  checkCoord(coord) {
    if (!coord && coord === '0.0') {
      return;
    }
    if (typeof coord === 'string') {
      return Number(coord).toFixed(3);
    } else if (typeof coord === 'number') {
      return coord.toFixed(3);
    } else {
      return coord;
    }
  }

  getFixtureInformation() {
    let text = '';
    const lang = (vuex.state as any).Global.lang;

    const openDiv = `<div style="${this.div_style}">`;
    text = text + openDiv;
    const cabinetNumber = `<b style="${this.text_style}">${i18n.t(
      'Cabinet ID'
    )}: </b> ${
      this.metaDevice ? this.metaDevice['cabinet_id'] || i18n.t('N/A') : i18n.t('N/A')
    }<br/>`;
    text = text + cabinetNumber;
    const circuitNumber =
      this.metaDevice && this.metaDevice['circuit_number']
        ? `<b style="${this.text_style}">${i18n.t('Circuit Number')}: </b> ${
            this.metaDevice['circuit_number']
          }<br/>`
        : '';
    if (circuitNumber) text = text + circuitNumber;

    const poleNumber = `<b style="${this.text_style}">${i18n.t(
      'Pole Number'
    )}: </b> ${
      this.metaDevice ? this.metaDevice['pole_number'] || i18n.t('N/A') : i18n.t('N/A')
    }<br/>`;

    const fixtureIcon = `<b style="${this.text_style}">${i18n.t(
      'Fixture Icon'
    )}: </b> ${
      this.metaDevice
        ? i18n.t(this.metaDevice['fixture_icon']) || i18n.t('Regular')
        : i18n.t('Regular')
    }<br/>`;

    const part_number = this.part_number
      ? `<b style="${this.text_style}">${i18n.t('Part Number')}: </b> ${
          this.part_number
        }<br/>`
      : '';
    const status = `<b style="${this.text_style}">${i18n.t('Status')}: </b> ${
      this.sysConnected
    }<br/>`;
    const address = `<b style="${this.text_style}">${i18n.t('Address')}: </b> ${
      this.address
    } ${this.estimated_address ? `(${i18n.t('estimated')})` : ''}<br/>`;
    const location = `<b style="${this.text_style}">${i18n.t(
      'Location'
    )}: </b> ${this.location}<br/>`;
    const name = `<b style="${this.text_style}">${i18n.t('Name')}: </b> ${
      this.name
    }<br/>`;
    let scan_number = '';
    if (this.metaDevice['scan_numbering']) {
      scan_number = `<b style="${this.text_style}">${i18n.t(
        'Scan Number'
      )}: </b>`;
      if (this.metaDevice['total_scan_number']) {
        scan_number =
          scan_number +
          `${this.metaDevice['scan_numbering']} ${i18n.t('out of')} ${
            this.metaDevice['total_scan_number']
          }<br/>`;
      } else {
        scan_number = scan_number + `${this.metaDevice['scan_numbering']}<br/>`;
      }
    }

    let img,
      clock_text = '';
    if (this.astronomicalClock) {
      img = mapConfig.icons['astronomicalClock'];
      clock_text = `${i18n.t('Yes')}`;
    } else {
      img = mapConfig.icons['noAstronomicalClock'];
      clock_text = `${i18n.t('No')}`;
    }
    const isAstronomical = `<div class="d-flex"><b style="${
      this.text_style
    }">${i18n.t('Astronomical Clock')}: </b><div style="${this.clock_style}${
      lang === 'en' ? 'margin-left: 3px;' : 'margin-right: 3px;'
    }">${clock_text}</div>`;
    const clockIcon = `<img width="20" height="20" style="${
      lang === 'en' ? 'margin-left: 10px;' : 'margin-right: 8px;'
    }" src="${img}"/></div>`;
    const closeDiv = '</div>';
    // const clockIcon = `<div style='position: absolute; top: 0px; left: 120px;'>${this.astronomicalClock ? `<i style='color: #2196f3;' class='material-icons'>check</i>` : `<i class='material-icons' style='color: #f44336;'>close</i>`}</div></div><br/>`;
    text =
      text +
      poleNumber +
      part_number +
      status +
      address +
      location +
      name +
      fixtureIcon +
      scan_number +
      isAstronomical +
      clockIcon +
      closeDiv;
    return text;
  }

  getMotionSensorInformation() {
    const openDiv = `<div style="${this.div_style}">`;
    const closeDiv = '</div>';

    const name = `<b style="${this.text_style}">${i18n.t('Name')}:</b> ${
      this.name
    }<br/>`;
    const address = `<b style="${this.text_style}">${i18n.t('Address')}:</b> ${
      this.address
    } ${this.estimated_address ? `(${i18n.t('estimated')})` : ''} <br/>`;
    const location = `<b style="${this.text_style}">${i18n.t(
      'Location'
    )}: </b> ${this.location}<br/>`;
    return openDiv + name + address + location + closeDiv;
  }

  getFloodSensorInformation() {
    const openDiv = `<div style="${this.div_style}">`;
    const closeDiv = '</div>';

    const name = `<b style="${this.text_style}">${i18n.t('Name')}:</b> ${
      this.name
    }<br/>`;
    const address = `<b style="${this.text_style}">${i18n.t('Address')}:</b> ${
      this.address
    } ${this.estimated_address ? `(${i18n.t('estimated')})` : ''} <br/>`;
    const location = `<b style="${this.text_style}">${i18n.t(
      'Location'
    )}: </b> ${this.location}<br/>`;
    let height = this.metaDevice.hasOwnProperty('sensor_height') && this.metaDevice['sensor_height'] !== '' && this.device['water_mark'] !== null
      ? (+this.metaDevice['sensor_height'] - +this.device['water_mark']) / 10
      : null;
    height = height && height < 0 ? 0 : height;
    const water_height = `<b style="${this.text_style}">${i18n.t('Water Level')}:</b> ${
      height >= 0 ? `${height} ${i18n.t('cm')}` : i18n.t('N/A')
    }<br/>`;

    const bottom_height = this.metaDevice.hasOwnProperty('bottom_float_height') && this.metaDevice['bottom_float_height'] !== '' 
      ? `${this.metaDevice['bottom_float_height']} ${i18n.t('cm')}` 
      : i18n.t('N/A');
    const top_height = this.metaDevice.hasOwnProperty('top_float_height') && this.metaDevice['top_float_height'] !== '' 
      ? `${this.metaDevice['top_float_height']} ${i18n.t('cm')}` 
      : i18n.t('N/A');
    const bottom_status = +this.device[FloodSensor.BOTTOM_FLOAT_TRIGGER] === 1 ? `${i18n.t('Wet')}` : `${i18n.t('Dry')}`;
    const top_status = +this.device[FloodSensor.TOP_FLOAT_TRIGGER] === 1 ? `${i18n.t('Wet')}` : `${i18n.t('Dry')}`;
    const bottom_float = `<b style="${this.text_style}">${i18n.t('Bottom Float')} ${bottom_height === 'N/A' ? '' : `${i18n.t('at')} ${bottom_height}`}:</b> ${
      bottom_status
    }<br/>`;
    const top_float = `<b style="${this.text_style}">${i18n.t('Top Float')} ${top_height === 'N/A' ? '' : `${i18n.t('at')} ${top_height}`}:</b> ${
      top_status
    }<br/>`;
    return openDiv + 
      name + 
      address + 
      location + 
      water_height + 
      bottom_float + 
      top_float + 
      closeDiv;
  }


  getAssetInformation() {
    let text;
    const type = this.metaType
      ? `<b style="${this.text_style}">${i18n.t('Type')}:</b> ${
          this.metaType
        }<br/>`
      : '';
    text = this.getCommonProperties() + type + ' </div>';
    return text;
  }

  getCabinetInformation() {
    let moreInfo = '';
    if (this.cabinetTime.on_time !== '' && this.cabinetTime.off_time !== '') {
      const onTime = `<b style="${this.text_style}">${i18n.t('On Time')}:</b> ${
        this.cabinetTime.on_time
      }<br/>`;
      const offTime = `<b style="${this.text_style}">${i18n.t(
        'Off Time'
      )}:</b> ${this.cabinetTime.off_time}<br/>`;
      moreInfo = onTime + offTime;
    }
    const cabinetNumber = `<span style="padding: 0 10px; font-family: 'Open Sans', 'Arial', monospace !important;"><b style="${
      this.text_style
    }">${i18n.t('Cabinet ID')}: </b> ${
      this.metaDevice ? this.metaDevice['cabinet_id'] || i18n.t('N/A') : i18n.t('N/A')
    }</span><br/>`;
    const popupText =
      cabinetNumber + this.getCommonProperties() + moreInfo + ' </div>';
    return popupText;
  }

  getVideoInformation() {
    let text;
    text = this.getCommonProperties() + '</div>';
    return text;
  }
}

export function createDevice(device: any) {
  return new DeviceFactory(device);
}
