





























































import Component from 'vue-class-component';
import Vue from 'vue';
import { Global, Reports } from '@/store';
import { Watch, Prop } from 'vue-property-decorator';
import Utils from '@/modules/Utils';

@Component({})
export default class Legend extends Vue {
  @Global.State('lang') lang;

  @Prop() map_devices;
  @Prop() advancedMap;
  @Prop() simpleMap;

  get legendTypes() {
    const types = {
      fixture: { 
        title: 'Regular Fixture', 
        url: require('@/assets/images/mapView_icons/regular_tondo/regular_on.svg'), 
        open: false,
        items: [{
          text: 'On/Off',
          url: [require('@/assets/images/mapView_icons/regular_tondo/regular_on.svg'), require('@/assets/images/mapView_icons/regular_tondo/regular_off.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Partial Communication',
          url: [require('@/assets/images/mapView_icons/regular_tondo/regular_comm_fault_on.svg'), require('@/assets/images/mapView_icons/regular_tondo/regular_comm_fault_off.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Lack Of Voltage',
          url: [require('@/assets/images/mapView_icons/regular_tondo/regular_voltage_fault.svg')]
        },
        {
          text: 'Electrical Fault',
          url: [require('@/assets/images/mapView_icons/regular_tondo/regular_electrical_fault.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Burnt Lamp',
          url: [require('@/assets/images/mapView_icons/regular_tondo/regular_burnt_fault.svg')]
        },
        {
          text: 'In Installation',
          url: [require('@/assets/images/mapView_icons/regular_tondo/regular_in_installation.svg')]
        },
        {
          text: 'Maintenance',
          url: [require('@/assets/images/mapView_icons/regular_tondo/regular_in_maintenance.svg')]
        },
        {
          text: 'Fault In Care',
          url: [require('@/assets/images/mapView_icons/regular_tondo/regular_fault_in_care.svg')]
        }]
      },
      virtual_fixture: {
        title: 'Virtual Fixture', 
        url: require('@/assets/images/mapView_icons/virtual_tondo/virtual_on.svg'), 
        open: false,
        items: [{
          text: 'On/Off',
          url: [require('@/assets/images/mapView_icons/virtual_tondo/virtual_on.svg'), require('@/assets/images/mapView_icons/virtual_tondo/virtual_off.svg')]
        },
        {
          text: 'Electrical Fault',
          url: [require('@/assets/images/mapView_icons/virtual_tondo/virtual_electrical_fault.svg')]
        }]
      },
      underground: { 
        title: 'Underground Fixture',
        url: require('@/assets/images/mapView_icons/underground/underground_on.svg'), 
        open: false,
        items: [{
          text: 'On/Off',
          url: [require('@/assets/images/mapView_icons/underground/underground_on.svg'), require('@/assets/images/mapView_icons/underground/underground_off.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Partial Communication',
          url: [require('@/assets/images/mapView_icons/underground/underground_comm_fault_on.svg'), require('@/assets/images/mapView_icons/underground/underground_comm_fault_off.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Lack Of Voltage',
          url: [require('@/assets/images/mapView_icons/underground/underground_voltage_fault.svg')]
        },
        {
          text: 'Electrical Fault',
          url: [require('@/assets/images/mapView_icons/underground/underground_electrical_fault.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Burnt Lamp',
          url: [require('@/assets/images/mapView_icons/underground/underground_burnt_fault.svg')]
        }]
      },
      highmast: { 
        title: 'Highmast Fixture', 
        url: require('@/assets/images/mapView_icons/highmast/highmast_on.svg'), 
        open: false,
        items: [{
          text: 'On/Off',
          url: [require('@/assets/images/mapView_icons/highmast/highmast_on.svg'), require('@/assets/images/mapView_icons/highmast/highmast_off.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Partial Communication',
          url: [require('@/assets/images/mapView_icons/highmast/highmast_comm_fault_on.svg'), require('@/assets/images/mapView_icons/highmast/highmast_comm_fault_off.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Lack Of Voltage',
          url: [require('@/assets/images/mapView_icons/highmast/highmast_voltage_fault.svg')]
        },
        {
          text: 'Electrical Fault',
          url: [require('@/assets/images/mapView_icons/highmast/highmast_electrical_fault.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Burnt Lamp',
          url: [require('@/assets/images/mapView_icons/highmast/highmast_burnt_fault.svg')]
        }]
      },
      security: { 
        title: 'Security Fixture', 
        url: require('@/assets/images/mapView_icons/security/security_on.svg'), 
        open: false,
        items: [{
          text: 'On/Off',
          url: [require('@/assets/images/mapView_icons/security/security_on.svg'), require('@/assets/images/mapView_icons/security/security_off.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Partial Communication',
          url: [require('@/assets/images/mapView_icons/security/security_comm_fault_on.svg'), require('@/assets/images/mapView_icons/security/security_comm_fault_off.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Lack Of Voltage',
          url: [require('@/assets/images/mapView_icons/security/security_voltage_fault.svg')]
        },
        {
          text: 'Electrical Fault',
          url: [require('@/assets/images/mapView_icons/security/security_electrical_fault.svg')]
        },
        {
          show: this.is_advanced,
          text: 'Burnt Lamp',
          url: [require('@/assets/images/mapView_icons/security/security_burnt_fault.svg')]
        }]
      },
      cabinet: { 
        title: 'Regular Cabinet', 
        url: require('@/assets/images/mapView_icons/cabinet/cabinet_on.svg'), 
        open: false,
        items: [{
          text: 'On/Off',
          url: [require('@/assets/images/mapView_icons/cabinet/cabinet_on.svg'), require('@/assets/images/mapView_icons/cabinet/cabinet_off.svg')]
        },
        {
          text: 'Fault',
          url: [require('@/assets/images/mapView_icons/cabinet/cabinet_fault.svg')]
        },
        {
          text: 'In Installation',
          url: [require('@/assets/images/mapView_icons/cabinet/cabinet_in_installation.svg')]
        }]
      },
      virtual_cabinet: { title: 'Virtual Cabinet', url: require('@/assets/images/mapView_icons/virtual_cabinet/virtual_cabinet.svg'), open: false, items: []},
      motion_sensor: { 
        title: 'Motion Sensor', 
        url: require('@/assets/images/mapView_icons/sensor/sensor_ok.svg'), 
        open: false,
        items: [
          {
            text: 'Fault',
            url: [require('@/assets/images/mapView_icons/sensor/sensor_fault.svg')]
          }
        ]
      },
      flood_sensor: { 
        title: 'Flood Sensor', 
        url: require('@/assets/images/mapView_icons/flood_sensor/flood_sensor.svg'), 
        open: false,
        items: [
          {
            text: 'Sensor Alert',
            url: [require('@/assets/images/mapView_icons/flood_sensor/flood_sensor_alert.svg')]
          },
          {
            text: 'Top Float Alert',
            url: [require('@/assets/images/mapView_icons/flood_sensor/top_sensor_alert.svg')]
          },
          {
            text: 'Bottom Float Alert',
            url: [require('@/assets/images/mapView_icons/flood_sensor/bottom_sensor_alert.svg')]
          },
          {
            text: 'Barrier Open',
            url: [require('@/assets/images/mapView_icons/flood_sensor/open_barrier.svg')]
          },
          {
            text: 'Barrier Closed',
            url: [require('@/assets/images/mapView_icons/flood_sensor/barrier_gif.gif')]
          }
        ]
      },
      water_meter: {
        title: 'Water Meter', 
        url: require('@/assets/images/mapView_icons/water_meter/water_meter.svg'),
        open: false,
        items: [
          {
            text: 'Water Meter',
            url: [require('@/assets/images/mapView_icons/water_meter/water_meter.svg')]
          },
          {
            text: 'Fault',
            url: [require('@/assets/images/mapView_icons/water_meter/water_meter_fault.svg')]
          }
        ]
      },
      camera: { 
        title: 'Camera', 
        url: require('@/assets/images/mapView_icons/camera/camera_ok.svg'), 
        open: false,
        items: [{
          text: 'Fault',
          url: [require('@/assets/images/mapView_icons/camera/camera_fault.svg')]
        }]
      },
      radar: { title: 'Radar', url: require('@/assets/images/mapView_icons/radar/radar_ok.svg'), open: false, items: []},
      // container: { title: 'Container', url: require('@/assets/images/mapView_icons/container/container.svg'), open: false, items: []},
      weather_station: { title: 'Weather Station', url: require('@/assets/images/mapView_icons/weather_station/weather_station.png'), open: false, items: []}
    };

    if (!this.map_devices.some((device) => Utils.hasMotionSensorClass(device.class_name))) delete types.motion_sensor;
    if (!this.map_devices.some((device) => Utils.hasFloodSensorClass(device.class_name, device['meta.category.category']))) delete types.flood_sensor;
    if (!this.map_devices.some((device) => Utils.hasWaterMeterClass(device.class_name))) delete types.water_meter;
    if (!this.map_devices.some((device) => Utils.hasCameraClass(device.class_name))) delete types.camera;
    if (!this.map_devices.some((device) => Utils.hasRadarClass(device.class_name))) delete types.radar;
    if (!this.map_devices.some((device) => Utils.hasWeatherStationClass(device.class_name))) delete types.weather_station;

    if (!this.map_devices.some((device) => Utils.hasTondoClass(device.class_name))) {
      delete types.fixture;
      delete types.virtual_fixture;
      delete types.underground;
      delete types.highmast;
      delete types.security;
    }else {
      if (!this.map_devices.some((device) => Utils.isRegularFixture(device['meta.device'].fixture_icon))) {
        delete types.fixture;
      }
      if (!this.map_devices.some((device) => Utils.isVirtualFixture(device['meta.device'].fixture_icon))) {
        delete types.virtual_fixture;
      }
      if (!this.map_devices.some((device) => Utils.isUndergroundFixture(device['meta.device'].fixture_icon))) {
        delete types.underground;
      }
      if (!this.map_devices.some((device) => Utils.isHighmastFixture(device['meta.device'].fixture_icon))) {
        delete types.highmast;
      }
      if (!this.map_devices.some((device) => Utils.isSecurityFixture(device['meta.device'].fixture_icon))) {
        delete types.security;
      }
    }

    if (!this.map_devices.some((device) => Utils.hasCabinetClass(device.class_name))) {
      delete types.cabinet;
    }
    if (!this.map_devices.some((device) => Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']))) {
      delete types.virtual_cabinet;
    }

    if (Object.keys(types).length === 1) {
      Object.values(types)[0].open = true;
    }

    return types;
  }

  get is_advanced(){
    return this.simpleMap && this.advancedMap || !this.simpleMap;
  }

  onMenuToggle(isOpen){
    if (!isOpen) Object.entries(this.legendTypes).forEach(([type, data]) => data.open = false);
  }
}
