import moment from 'moment';
import Utils from '@/modules/Utils';

export default {
  chartOptionsVoltage: {
    L1: {
      chart: {
        animations: {
          speed: 1500
        },
        type: 'radialBar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          track: {
            background: '#333',
            startAngle: -135,
            endAngle: 135
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              fontSize: '30px',
              fontFamily: 'AssistantRegular',
              show: true,
              formatter: (val) => {
                return ((val * 260) / 100).toFixed(2);
              }
            }
          }
        }
      },
      fill: {
        colors: ['#70B6FC'],
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          gradientToColors: ['#3d61ff'],
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'butt'
      },
      labels: ['L1'],
      title: {
        text: 'L1',
        align: 'center'
      }
    },
    L2: {},
    L3: {}
  },

  chartOptionsCurrent: {
    L1: {
      chart: {
        animations: {
          speed: 1500
        },
        type: 'radialBar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          track: {
            background: '#333',
            startAngle: -135,
            endAngle: 135
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              fontSize: '30px',
              fontFamily: 'AssistantRegular',
              show: true,
              formatter: (val) => {
                return val.toFixed(2);
              }
            }
          }
        }
      },
      fill: {
        colors: ['#3d61ff'],
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          gradientToColors: ['#3d61ff'],
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'butt'
      },
      labels: ['L1'],
      title: {
        text: 'L1',
        align: 'center'
      }
    },
    L2: {},
    L3: {}
  },

  chartOptionsPowerFactor: {
    chart: {
      animations: {
        speed: 1500
      },
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    colors: ['#3d61ff', '#75838E', '#d6002e'],
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '15%',
        distributed: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => {
        return val;
      },
      offsetY: -30,
      style: {
        fontSize: '15px',
        fontFamily: 'AssistantRegular',
        colors: ['#3d61ff', '#75838E', '#d6002e']
      }
    },
    xaxis: {
      categories: ['L1', 'L2', 'L3'],
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      },
      tooltip: {
        enabled: true
      },
      labels: {
        style: {
          colors: ['#3d61ff', '#75838E', '#d6002e'],
          fontSize: '14px',
          fontWeight: 'bold'
        }
      }
    },
    yaxis: {
      max: 1,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        formatter: (val) => {
          return val;
        }
      }
    },
    title: {
      text: 'Power Factor',
      floating: true,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  },
  chartOptionsTemp: {
    chart: {
      animations: {
        speed: 1500
      },
      height: 350,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: '#333',
          startAngle: -135,
          endAngle: 135
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            fontSize: '20px',
            fontFamily: 'AssistantBold',
            show: true,
            formatter: (val) => {
              return ((val * 80) / 100).toFixed(1) + ' C';
            }
          }
        }
      }
    },
    fill: {
      colors: ['#FF8900'],
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        gradientToColors: ['#FFDAAA'],
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'butt'
    },
    title: {
      text: 'Temperature',
      floating: true,
      align: 'center',
      style: {
        color: '#444',
        fontFamily: 'AssistantBold'
      }
    }
  },
  chartOptionsHumidity: {
    chart: {
      animations: {
        speed: 1500
      },
      height: 350,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: '#333',
          startAngle: -135,
          endAngle: 135
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            fontSize: '20px',
            fontFamily: 'AssistantBold',
            show: true,
            formatter: (val) => {
              return val.toFixed(1) + ' %';
            }
          }
        }
      }
    },
    fill: {
      colors: ['#70B6FC'],
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        gradientToColors: ['#3d61ff'],
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'butt'
    },
    title: {
      text: 'Humidity',
      floating: true,
      align: 'center',
      style: {
        color: '#444',
        fontFamily: 'AssistantBold'
      }
    }
  },
  chartOptionsTotalVehicles: {
    chart: {
      type: 'radialBar'
    },
    colors: ['#20E647'],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '70%',
          background: '#293450'
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15
          }
        },
        dataLabels: {
          name: {
            offsetY: -10,
            color: '#fff',
            fontSize: '13px'
          },
          value: {
            color: '#fff',
            fontSize: '30px',
            show: true,
            formatter: (val) => {
              return val / 100;
            }
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        gradientToColors: ['#87D4F9'],
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ['Total vehicles']
  },
  chartOptionsBillingTab: {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        export: {
          csv: {
            filename: 'Billing Report'
          },
          svg: {
            filename: 'Billing Report'
          },
          png: {
            filename: 'Billing Report'
          }
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '10%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      formatter: (val) => Utils.numberWithCommas(val),
      style: {
        fontSize: '12px',
        colors: ['#304758']
      }
    },
    xaxis: {
      categories: ['Peak', 'High', 'Low'],
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      },
      tooltip: {
        enabled: true
      }
    },
    yaxis: {
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      },
      labels: {
        show: true
      },
      title: {
        text: 'Wh',
        rotate: -90,
        offsetX: 0,
        offsetY: 0
      }
    },
    tooltip: {
      enabled: true,
      y: {
        show: true,
        formatter: (val) => {
          return Utils.numberWithCommas(val) + ' W/h';
        }
      }
    }
  },
  chartOptionsEnergy: {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: []
        },
        export: {
          csv: {
            show: false,
            filename: 'Energy',
            columnDelimiter: ',',
            headerCategory: 'time',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return moment(timestamp).format('DD.MM.YYYY HH:mm');
            }
          },
          svg: {
            filename: 'Energy'
          },
          png: {
            filename: 'Energy'
          }
        },
        autoSelected: 'zoom'
      }
    },
    colors: ['#70B6FC', '#FF0000'],
    stroke: {
      curve: 'smooth',
      width: 3
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'V1',
      align: 'center'
    },
    markers: {
      hover: {
        sizeOffset: 4
      }
    },
    yaxis: {
      title: {
        text: 'Volts'
      },
      tickAmount: 5,
      opposite: true
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false
      }
    },
    tooltip: {
      shared: false,
      x: {
        format: 'dd/MM/yy HH:mm'
      }
    }
  }
};
