<template>
  <v-layout class="info-containers-styled-overview" style="height: 160px; padding: 15px">
    <span style="display: none">{{ filterDevices.length }}</span>
    <v-flex :class="lang === 'en' ? 'border-left' : 'border-right'">
      <v-layout column fill-height class="px-4">
        <v-layout>
          <img src="@/assets/images/tondo_icons/project_color.svg" :class="['icon-style', lang === 'en' ? 'mr-2' : 'ml-2']"/>
          <h2 class="font-weight-bold">{{ $t('PROJECT') }}</h2>
        </v-layout>
        <v-layout row justify-space-between align-end>
          <span class="font-weight-bold subheading" :class="lang === 'en' ? 'mr-2' : 'ml-2'">{{ $t('Total') }}</span>
          <span class="font-weight-bold indigo--text subheading">{{ projectDevicesFiltered.length }}</span>
        </v-layout>
      </v-layout>
    </v-flex>
    <v-flex
      v-for="[type, states] in categoryMap.entries()"
      :key="`${type}`"
      :class="lang === 'en' ? 'border-left' : 'border-right'" 
      class="px-3"
    >
      <v-layout column justify-space-between fill-height d-flex wrap>
        <v-layout row>
          <img :src="getItemIcon(type)" :class="['icon-style', lang === 'en' ? 'mr-2' : 'ml-2']"/>
          <h2 class="font-weight-bold" style="white-space: nowrap">{{ $t(`${type}`) }}</h2>
        </v-layout>
        <v-layout row align-end justify-space-between>
          <v-layout column fill-height>
            <v-layout v-for="[state, count] in states" :key="`${state}`" align-center>
              <span class="font-weight-bold" style="white-space: nowrap;">{{ $t(state) }}:</span>
              <span class="font-weight-bold indigo--text px-2">{{ count }}</span>
            </v-layout>
          </v-layout>
          <v-layout column fill-height justify-space-around align-end>
            <v-progress-linear
              v-for="[state, count] in states"
              :key="`${state}-progress-bar`"
              :value="(count / categoryCounter.get(type)) * 100"
              height="12"
              color="#7E57C2"
              style="margin: 0; border-radius: 9px;"
              :style="categoryMap.size <= 3 ? 'width: 200px' : 'width: 100px'"
            >
            </v-progress-linear>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';

export default {
  name: 'StyledOverviewInfo',
  props: {
    project: {
      type: Object
    },
    filterDevices: {
      type: Array,
      default: () => []
    },
    projectDevices: {
      type: Array,
      default: () => []
    },
    lang: {
      type: String,
      default: 'en'
    },
    simple_map: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      categoryMap: new Map(),
      categoryCounter: new Map(),
      without_maintenance: ['water meter', 'flood sensor', 'motion sensor', 'barrier']
    };
  },
  mounted() {
    if (this.filterDevices.length) this.setInfoByCategory();
  },
  computed: {
    cabinets_id(){
      return this.$store.getters['Reports/commissionedCabinets']
        .map((cabinet) => cabinet['meta.device'].cabinet_id)
        .filter((cabinet_id) => cabinet_id);
    },
    projectDevicesFiltered() {
      return this.$store.getters['Reports/commissionedDevices'];
    },
    hasElectricalPower() {
      return this.$store.state.Global.hasElectricalPower;
    },
    commissioned(){
      return this.$store.getters['Reports/commissioned_map'];
    }
  },
  watch: {
    filterDevices() {
      this.setInfoByCategory();
    },
    simple_map() {
      this.setInfoByCategory();
    }
  },
  methods: {
    setInfoByCategory(){
      this.categoryMap = new Map();
      this.filterDevices
        .filter((device) => this.commissioned.has(device.id))
        .map((device) => this.setCategoryMap(device));

      let ordered = [];
      const types = [];

      if (this.categoryMap.has('CABINET')){
        ordered.push(['CABINET', this.categoryMap.get('CABINET')]);
        types.push('CABINET');
      }
      if (this.categoryMap.has('FIXTURE')) {
        if (this.categoryMap.get('FIXTURE').has('Electrical Fault')) this.categoryMap.get('FIXTURE').delete('Electrical Fault');
        ordered.push(['FIXTURE', this.categoryMap.get('FIXTURE')]);
        types.push('FIXTURE');
      }

      if (types.length){
        ordered = ordered.concat([...this.categoryMap.entries()].filter(([type, value]) => !types.includes(type)));
        this.categoryMap = new Map(ordered);
      }
    },
    setCategoryMap(report) {
      let cat = report['meta.category.category'];
      if (cat === 'HUB') cat = 'CABINET';
      
      if (cat && !this.categoryMap.has(cat)) {
        this.categoryCounter.set(cat, 1);
        if (cat.toLowerCase() === 'fixture') {
          if (report['sys___active'] === null) {
            this.categoryMap.set(cat, new Map([['Active', 0], ['Electrical Fault', 1], ['In Installation', 0], ['Fault In Care', 0], ['Maintenance', 0]]));
          }else if (report['sys___active'] === 0) {
            if (!this.hasElectricalPower || (report.hasOwnProperty('disconnect_alert') && +report.disconnect_alert === 1))
              this.categoryMap.set(cat, new Map([['Active', 0], ['Electrical Fault', 1], ['In Installation', 0], ['Fault In Care', 0], ['Maintenance', 0]]));
            else if (this.hasElectricalPower && report.hasOwnProperty('disconnect_alert') && +report.disconnect_alert !== 1)
              this.categoryMap.set(cat, new Map([['Active', 1], ['Electrical Fault', 0], ['In Installation', 0], ['Fault In Care', 0], ['Maintenance', 0]]));
          } else if (report['sys___active'] === -1)
            this.categoryMap.set(cat, new Map([['Active', 0], ['Electrical Fault', 0], ['In Installation', 0], ['Fault In Care', 0], ['Maintenance', 1]]));
          else if (report['sys___active'] === -3 || this.simple_map && !this.cabinets_id.includes(report['meta.device'].cabinet_id))
            this.categoryMap.set(cat, new Map([['Active', 0], ['Electrical Fault', 0], ['In Installation', 1], ['Fault In Care', 0], ['Maintenance', 0]]));
          else if (report['sys___active'] === -9)
            this.categoryMap.set(cat, new Map([['Active', 0], ['Electrical Fault', 0], ['In Installation', 0], ['Fault In Care', 1], ['Maintenance', 0]]));
          else
            this.categoryMap.set(cat, new Map([['Active', 1], ['Electrical Fault', 0], ['In Installation', 0], ['Fault In Care', 0], ['Maintenance', 0]]));
        }else if (Utils.hasCabinetClass(report.class_name) || Utils.hasVirtualCabinetClass(report.class_name, report['meta.device'])) {
          if (Utils.hasVirtualCabinetClass(report.class_name, report['meta.device'])) {
            this.categoryMap.set(cat, new Map([['Active', 0], ['Inactive', 0], ['Maintenance', 0], ['Virtual', 1]]));
          }else if (report['sys___active'] === 0 || report['sys___active'] === null)
            this.categoryMap.set(cat, new Map([['Active', 0], ['Inactive', 1], ['Maintenance', 0], ['Virtual', 0]]));
          else if (report['sys___active'] === -1)
            this.categoryMap.set(cat, new Map([['Active', 0], ['Inactive', 0], ['Maintenance', 1], ['Virtual', 0]]));
          else
            this.categoryMap.set(cat, new Map([['Active', 1], ['Inactive', 0], ['Maintenance', 0], ['Virtual', 0]]));  
        }else if (this.without_maintenance.includes(cat.toLowerCase())) {
          if (report['sys___active'] === 0 || report['sys___active'] === null)
            this.categoryMap.set(cat, new Map([['Active', 0], ['Inactive', 1]]));
          else
            this.categoryMap.set(cat, new Map([['Active', 1], ['Inactive', 0]]));
        }else {
          if (report['sys___active'] === 0 || report['sys___active'] === null)
            this.categoryMap.set(cat, new Map([['Active', 0], ['Inactive', 1], ['Maintenance', 0]]));
          else if (report['sys___active'] === -1)
            this.categoryMap.set(cat, new Map([['Active', 0], ['Inactive', 0], ['Maintenance', 1]]));
          else
            this.categoryMap.set(cat, new Map([['Active', 1], ['Inactive', 0], ['Maintenance', 0]]));
        }
      } else if (cat) {
        this.categoryCounter.set(cat, this.categoryCounter.get(cat) + 1);
        if (Utils.hasVirtualCabinetClass(report.class_name, report['meta.device'])) {
          this.categoryMap.get(cat).set('Virtual', this.categoryMap.get(cat).get('Virtual') + 1);
        }else if (report['sys___active'] === 0 || report['sys___active'] === null) {
          if (cat.toLowerCase() !== 'fixture'){
            this.categoryMap.get(cat).set('Inactive', this.categoryMap.get(cat).get('Inactive') + 1);
          }else if (report['sys___active'] === null || !this.hasElectricalPower || (report.hasOwnProperty('disconnect_alert') && +report.disconnect_alert === 1)){
            this.categoryMap.get(cat).set('Electrical Fault', this.categoryMap.get(cat).get('Electrical Fault') + 1);
          }else if (this.hasElectricalPower && report.hasOwnProperty('disconnect_alert') && +report.disconnect_alert !== 1) {
            this.categoryMap.get(cat).set('Active', this.categoryMap.get(cat).get('Active') + 1);
          }
        } else if (report['sys___active'] === -1){
          if (!this.without_maintenance.includes(cat.toLowerCase()))
            this.categoryMap.get(cat).set('Maintenance', this.categoryMap.get(cat).get('Maintenance') + 1);
        } else if (cat.toLowerCase() === 'fixture' && report['sys___active'] === -9){
          this.categoryMap.get(cat).set('Fault In Care', this.categoryMap.get(cat).get('Fault In Care') + 1);
        } else if (cat.toLowerCase() === 'fixture' && (report['sys___active'] === -3 || this.simple_map && !this.cabinets_id.includes(report['meta.device'].cabinet_id))){
          this.categoryMap.get(cat).set('In Installation', this.categoryMap.get(cat).get('In Installation') + 1);
        } else
          this.categoryMap.get(cat).set('Active', this.categoryMap.get(cat).get('Active') + 1);
      }
    },
    getItemIcon(item) {
      let src;
      switch (item) {
        case 'CABINET':
          src = require('@/assets/images/tondo_icons/cabinet_color.svg');
          break;
        case 'FIXTURE':
          src = require('@/assets/images/tondo_icons/fixture_color.svg');
          break;
        case 'VIDEO':
          src = require('@/assets/images/tondo_icons/camera_color.svg');
          break;
        case 'ASSET':
          src = require('@/assets/images/tondo_icons/asset_color.svg');
          break;
        case 'MOTION SENSOR':
          src = require('@/assets/images/tondo_icons/motion_sensor_color.svg');
          break;
        case 'FLOOD SENSOR':
          src = require('@/assets/images/tondo_icons/flood_sensor_color.svg');
          break;
        case 'BARRIER':
          src = require('@/assets/images/tondo_icons/barrier_color.svg');
          break;
        case 'RADAR':
          src = require('@/assets/images/tondo_icons/radar_color.svg');
          break;
        case 'WATER METER':
          src = require('@/assets/images/tondo_icons/water_meter_color.svg');
          break;
        case 'WEATHER STATION':
          src = require('@/assets/images/tondo_icons/weather_station.png');
          break;
      }
      return src;
    }
  }
};
</script>

<style scoped>
.border-right {
  border-right: 3px solid #cfd8dc;
  border-radius: 3px;
}

.border-left {
  border-left: 3px solid #cfd8dc;
  border-radius: 3px;
}

.info-containers-styled-overview {
  overflow: auto;
  background-color: #F6F8FB;
}

.icon-style {
  height: 35px;
  width: 35px;
}

.progress-bar-div {
  white-space: nowrap;
  width: 55%;
  max-width: 55%;
  min-width: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0;
  margin: 0;
}

@media (max-width: 995px) and (orientation: landscape) {
  .progress-bar-div {
    width: 35%;
  }

  .maintenance-span-aside-pbar-text {
    width: 100%;
  }
}

.progress-bar-div-maintenance {
  width: 35%;
}

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #292f7d;
    border-radius: 5px;
  }
</style>