









































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Watch } from 'vue-property-decorator';
import API from '@/modules/API';
import TabsInfoMixin from '../../devices/TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import { Reports, Global } from '@/store';
import Utils from '@/modules/Utils';

@Component({})
export default class ControlSliderTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  // @ts-ignore
  @Prop() device;
  @Prop() mqtt;
  @Prop() streamDimLevel;
  @Prop() streamPowerObject;
  @Prop() powerMsgSent;
  @Prop() updateDim;
  // isDimLevelUpdated prop is for btns loading and disabled state, uncomment and add when he asks.
  @Prop() isDimLevelUpdated;
  @Prop() isDeviceTypeRelay: boolean;
  @Prop() converted;
  @Prop() daliToPercentTable;
  @Prop() percentToDaliTable;
  @Prop() mqtt_version;

  // @ts-ignore
  @Reports.State('reportsList') reports;
  @Global.State('readonly_user') readonly_user;

  sliderValue = 0;

  async mounted() {
    this.changeDim();
  }

  get LightingButtonColor(){
    return this.streamDimLevel > 0 ? 'red' : 'green';
  }

  get LightingButtonText(){
    return this.powerMsgSent ? this.$t('Message Sent') : this.streamDimLevel > 0 ? this.$t('Turn Off') : this.$t('Turn On');
  }

  @Watch('streamDimLevel')
  changeDim() {
    this.sliderValue = this.getPercentValue();
  }

  getPercentValue() {
    return Utils.getPercentValue(this.converted, this.daliToPercentTable, this.streamDimLevel);
  }

  async getStream(id) {
    if (!id) return null;
    const res = await API.get('devices', id + '/streams/', {});
    return res.results.find((r) => r.name === 'flushLoraBroadcast');
  }

  getParent() {
    const hasParent = this.device.parent_id;
    return hasParent 
      ? this.reports.find((report) => report.id === hasParent) 
      : null;
  }

  turnOnOff() {
    const power = this.streamDimLevel > 0 ? 0 : 100;
    this.setOverride(power);
  }

  async setOverride(power) {
    const streamValue = this.converted ? this.percentToDaliTable[power] : Math.round((power * 254) / 100);
    this.updateDim();

    const mqtt_publish_data = {
      version_num: this.mqtt_version,
      device_id: this.device.id,
      stream_id: this.mqtt_version === 3 ? this.streamPowerObject.id : 'power',
      message_type: 'cmd',
      format: 'minimal',
      stream_value: streamValue.toString(),
      options: {
        retain: false,
        qos: 1
      }
    };

    this.mqtt.publishToSingleDevice(mqtt_publish_data);
    
    const parent = this.getParent();
    if (parent) {
      const stream = await this.getStream(parent.id);
      if (stream) {
        const mqtt_parent_publish_data = {
          version_num: this.mqtt_version,
          device_id: parent.id,
          stream_id: this.mqtt_version === 3 ? stream.id : 'flushLoraBroadcast',
          message_type: 'cmd',
          format: 'minimal',
          stream_value: '1',
          options: {
            retain: false,
            qos: 1
          }
        };
        this.mqtt.publishToSingleDevice(mqtt_parent_publish_data);
      }
    }
  }
}
