

































import Component from 'vue-class-component';
import TabsInfoMixin from './TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import { Global } from '@/store';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';

@Component({
  components: {}
})

export default class VideoInfo extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('lang') lang;

  loadingTabs = false;

  get getGeoLocation() {
    return Utils.deviceLocation(this.device);
  }

  getLinkUrl(device) {
    const json = PropUtils.parseProperty('meta.stream', device);
    return json.link_url;
  }
}
