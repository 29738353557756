









































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Utils from '@/modules/Utils';
import { Prop, Watch } from 'vue-property-decorator';
import { Props, User, ProjectStreams, Reports, Global } from '@/store';
import moment from 'moment';
import SearchListItem from '@/components/SearchListItem.vue';
import VirtualList from 'vue-virtual-scroll-list';
import PropUtils from '@/modules/PropUtils';

@Component({
  components: {
    VirtualList,
    SearchListItem
  }
})
export default class MultipleSearchTab extends Vue {
  @User.State('isManager') isManager;
  @ProjectStreams.Action('getRelativeStreamHistory') getRelativeStreamHistory;
  @ProjectStreams.State('relativeStreamHistory') relativeStreamHistory;
  @ProjectStreams.State('streamName') streamName;
  @ProjectStreams.State('relativeValue') relativeValue;
  @ProjectStreams.State('relativeUnit') relativeUnit;
  @Reports.Getter('commissionedFixtures_map') commissionedFixtures_map;
  @Reports.Getter('commissioned_map') commissioned_map;
  @Reports.Getter('commissionedDevices') commissionedDevices;
  @Reports.Getter('commissionedCabinets') commissionedCabinets;
  @Global.State('timezone') timezone;
  @Global.State('lang') lang;
  @Props.State('cabinet_meta_device_options') cabinet_meta_device_options;
  @Props.State('fixture_meta_device_options') fixture_meta_device_options;

  @Prop() project_devices;
  @Prop() project;
  @Prop() devicesList;
  @Prop() MULTIPLE_TAB;
  @Prop() OVERVIEW_TAB;
  @Prop() devices_list_data;
  @Prop() addCommissionedDevices;

  selectedDeviceType = null;
  deviceTypes = [];
  selectedValue = {text: '', value: ''};
  searchValues = [];
  searchField = {name: '', value: '', options: []};
  searchFields = [];
  selectedDevice = '';
  selectedDeviceObject = {};
  fields_list = [];
  commissioned_tondos = [];
  commissioned_cabinets = [];
  loadingData = false;
  loadingInstalled = false;
  searchLoading = false;
  searchClicked = false;
  search = '';
  noData = false;
  loading = true;
  search_list_item = SearchListItem;

  mounted() {
    this.loading = true;
    this.setMetadataFieldsLists();
    if (!this.noData) this.initValues();
    this.loading = false;
  }

  setMetadataFieldsLists() {
    if (this.commissionedFixtures_map.size > 0 && Object.keys(this.fixture_meta_device_options).length) this.deviceTypes.push({ text: 'Tondo Device', value: 'tondo' });
    if (this.commissionedCabinets.length && Object.keys(this.cabinet_meta_device_options).length) this.deviceTypes.push({ text: 'Cabinet Monitor', value: 'cabinet' });

    this.noData = this.deviceTypes.length ? false : true;
  }

  get deviceTypesList() {
    return this.deviceTypes.map((type) => ({
      ...type,
      text: this.$t(type.text)
    }));
  }

  getCommissionedDevices(type) {
    return type === 'tondo'
      ? this.commissionedDevices.filter((device) => Utils.hasTondoClass(device.class_name))
      : this.commissionedDevices.filter((device) => Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']));
  }

  clearSelectedDevice(){
    this.selectedDevice = '';
    this.selectedDeviceObject = {};
  }

  panToMarker(device) {
    const marker = this.$root.$refs.GoogleMap['getMarkerById'](device.id);
    this.$root.$refs.GoogleMap['setBounds']([marker]);
    this.$root.$refs.GoogleMap['showCurrentInfoWindow'](marker);
    this.setSelectedDevice(device);
  }

  setSelectedDevice(device) {
    this.selectedDevice = device.id;
    this.selectedDeviceObject = device;
  }

  initValues() {
    this.$emit('devicesListChanged', []);
    this.selectedDeviceType = this.deviceTypesList[0];
    this.searchFields = this.fixture_meta_device_options;
    let value = '';
    if (Object.keys(this.searchFields).length){
      value = Object.keys(this.searchFields)[0];
    }

    this.searchField = {name: this.$t(PropUtils.getFieldLowerToUpper(value)), value, options: value && this.fixture_meta_device_options[value] || []};

    this.clearSelectedDevice();
    this.searchValues = this.searchField.options.map((option) => ({text: this.$t(option), value: option}));
    this.selectedValue = this.searchValues.length ? this.searchValues[0] : {text: '', value: ''};
    this.commissioned_tondos = this.getCommissionedDevices('tondo');
    this.commissioned_cabinets = this.getCommissionedDevices('cabinet');
  }

  @Watch('searchField')
  initializeData() {
    this.clearDevices();
    this.updateSearchValues();
    this.search = '';
  }

  @Watch('lang')
  updateSearchValues() {
    this.searchValues = this.searchField.name && this.searchField.options.map((option) => ({text: this.$t(option), value: option}));
    this.selectedValue = this.searchValues.length ? this.searchValues[0] : {text: '', value: ''};
  }

  @Watch('selectedValue')
  clearDevices() {
    if (this.devicesList.length) {
      this.$emit('devicesListChanged', []);
      this.$emit('searchChanged', this.MULTIPLE_TAB, []);
    }
    if (this.selectedDevice) this.clearSelectedDevice();
    this.searchClicked = false;
  }

  @Watch('selectedDeviceType')
  changeMetadataList() {
    if (this.selectedDeviceType.value === 'tondo') {
      this.searchFields = this.fixture_meta_device_options;
    } else if (this.selectedDeviceType.value === 'cabinet') {
      this.searchFields = this.cabinet_meta_device_options;
    }
    let value = '';
    if (Object.keys(this.searchFields).length){
      value = Object.keys(this.searchFields)[0];
    }

    this.searchField = {name: this.$t(PropUtils.getFieldLowerToUpper(value)), value, options: value && this.fixture_meta_device_options[value] || []};
    this.search = '';
  }

  updateDevicesList() {
    this.loadingData = true;
    this.clearDevices();
    this.searchClicked = true;

    const [filtered_devices, filtered_list_devices] = !this.selectedValue.value
      ? [[], []]
      : this.selectedDeviceType.value === 'tondo'
      ? this.generateNewList(this.commissioned_tondos)
      : this.generateNewList(this.commissioned_cabinets);

    this.$emit('devicesListChanged', filtered_list_devices);
    this.$emit('searchChanged', this.MULTIPLE_TAB, filtered_devices);

    this.loadingData = false;
  }

  generateNewList(devices) {
    const filtered_list_devices = [];

    const filtered_devices = devices.filter((device) => {
      const value = this.commissioned_map.get(device.id)['meta.device'][this.searchField.value] || '';
      const valid = value ? value.toString() === this.selectedValue.value : false;
      if (valid) filtered_list_devices.push(this.devices_list_data.get(device.id));
      return valid;
    });
    return [filtered_devices, filtered_list_devices];
  }

  get searchList() {
    return PropUtils.getUppercaseFieldsList(this.searchFields);
  }

  getMetaDeviceField(device_id, field) {
    const value = this.commissioned_map.get(device_id)['meta.device'][field];
    return value ? ` ${this.$t(value)}` : '';
  }

  async installedClicked() {
    this.loadingInstalled = true;
    this.clearDevices();
    this.searchClicked = true;

    const parameters = {
      stream_name: 'power_consumption_hourly',
      aggregators: [],
      value: '1',
      unit: 'weeks'
    };
    if (
      this.relativeValue !== parameters.value ||
      this.relativeUnit !== parameters.unit ||
      this.streamName !== parameters.stream_name ||
      !this.relativeStreamHistory.length
    ) {
      await this.getRelativeStreamHistory(parameters);
    }

    const [installedDevices, filtered_list_devices] = this.getInstalledDevices();
    this.$emit('devicesListChanged', filtered_list_devices);
    this.$emit('searchChanged', this.MULTIPLE_TAB, installedDevices);
    this.loadingInstalled = false;
  }

  getInstalledDevices() {
    const installedDevices = [];
    const filtered_list_devices = [];
    const today_timestamp = moment().unix() * 1000;
    const today_string = Utils.convertTimestamp(
      today_timestamp,
      'YYYY-MM-DD',
      this.timezone
    );
    const before_week = Utils.removeFromTimestamp_string(
      today_timestamp,
      'weeks',
      1,
      'YYYY-MM-DD',
      this.timezone
    );

    this.relativeStreamHistory.forEach((device) => {
      const device_id = device.tags.device_id[0];
      if (this.commissionedFixtures_map.has(device_id)) {
        const original_device = this.commissionedFixtures_map.get(device_id);
        const commissionedDate = Utils.convertCommissionDate(original_device['meta.commission'].date, 'YYYY-MM-DD');
        if (
          commissionedDate &&
          moment(commissionedDate).isBetween(before_week, today_string, undefined, '[]')
        ) {
          const streamData = device.values;
          if (
            streamData.some(([date, value]) =>
              moment(
                Utils.convertTimestamp(date, 'YYYY-MM-DD', this.timezone)
              ).isBetween(before_week, today_string, undefined, '[]')
            )
          ) {
            installedDevices.push(original_device);
            filtered_list_devices.push(this.devices_list_data.get(device_id));
          }
        }
      }
    });

    return [installedDevices, filtered_list_devices];
  }

  beforeDestroy() {
    this.$root.$refs.GoogleMap['closeWindow']();
    this.addCommissionedDevices();
  }
}
