import Vue from 'vue';
import {
  VuexModule,
  Module,
  Mutation,
  Action
} from 'vuex-module-decorators';

import API, { Types } from '../../modules/API';
import Utils from '../../modules/Utils';
import vuex from '..';
import store from 'store';
import { cloneDeep } from 'lodash';

export interface User {
  id: string;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  projects: number[];
  // multi_factor_auth: boolean;
  // multi_factor_auth_method: string;
}

@Module({ namespaced: true })
export default class Users extends VuexModule {
  users_by_email = null;

  @Action async fetchAllUsers() {
    if (!(vuex.state as any).Projects.list || 
      (vuex.state as any).Projects.list.length !== (vuex.state as any).Projects.projects_count || 
      (vuex.state as any).Users.users_by_email
    ){
      return;
    }

    const users_by_email = {};

    const user = store.get('user');
    const response = await API.get(Types.COMPANIES, `${user.company}/users/listusers/`);
    if (response) {
      const users = response;
      users.forEach((user) => users_by_email[user.email] = {...user});
    }
    vuex.commit('Users/setAllUsers', users_by_email);
  }

  @Mutation
  setAllUsers(users){
    this.users_by_email = cloneDeep(users);
  }

  @Action create(data) {
    if (!data.password || !data.email) return;

    return API.post(Types.COMPANIES, `users/register/`, data)
      .then((res) => {
        vuex.commit('Users/add', {
          id: res.id,
          email: data.email,
          username: data.email,
          permissions_level: 'User'
        });

        vuex.dispatch('Global/throwNotify', {
          type: 'success',
          title: 'Success!',
          text: `User successfully created`
        });
      })
      .catch((err) => {
        vuex.dispatch('Global/throwNotify', {
          type: 'error',
          title: 'Error!',
          text: `User did not created. ${err}`
        });
      });
  }

  @Mutation flush() {
    this.users_by_email = {};
  }

  @Mutation add(user_data: User) {
    if (!user_data || !user_data.id) return;

    const new_list = {[user_data.email]: user_data};
    this.users_by_email = {...new_list, ...this.users_by_email};
  }

  @Mutation
  editProfile(user) {
    if (!user) return;

    this.users_by_email[user.email].first_name = user.first_name;
    this.users_by_email[user.email].last_name = user.last_name;
    this.users_by_email[user.email].phone_number = user.phonenumber;
  }

  @Mutation
  editRole(user) {
    if (!user || !user.email || !this.users_by_email[user.email]) return;
    this.users_by_email[user.email].role = user.role;
  }

  @Mutation deleteUser(user_email) {
    delete this.users_by_email[user_email];
    this.users_by_email = cloneDeep(this.users_by_email);
  }
}