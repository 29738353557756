



























import Vue from 'vue';
import Component from 'vue-class-component';
import ProjectInformation from '@/components/ProjectInformation.vue';
import MapView from '@/pages/mapView/MapView.vue';
import { User } from '@/store';

@Component({ 
  components: {
    ProjectInformation,
    MapView
  }
})

export default class SearchMenu extends Vue {
    
    @User.State('project') project;

    SINGLE_TAB = 'tab-single-search';
    MULTIPLE_TAB = 'tab-multiple-search';

    searchItemClicked(tab_name){
        if ((this.$root.$refs.MapView as MapView).fullMap) {
            (this.$root.$refs.MapView as MapView).setIsFullMap(false);
        }
        this.$nextTick(() => {
            if ((this.$root.$refs.ProjectInformation as ProjectInformation).tab !== tab_name){
                (this.$root.$refs.MapView as MapView).clearDevice();
                tab_name === this.SINGLE_TAB 
                    ? (this.$root.$refs.ProjectInformation as ProjectInformation).mapDevicesChanged(tab_name)
                    : (this.$root.$refs.ProjectInformation as ProjectInformation).mapDevicesChanged(tab_name, []);
            }
        });
    }
}
