




































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class DatePicker extends Vue {
  @Global.State('lang') lang;

  @Prop() date;
  @Prop() disabled;
  @Prop() max;

  isDatePickerOpen = false;
  selected_date = '';
  computedDate = '';

  mounted() {
    const regDate = this.date;
    this.computedDate =
      regDate.substring(8, 10) +
      '-' +
      regDate.substring(5, 7) +
      '-' +
      regDate.substring(0, 4);
    this.selected_date = this.date;
  }

  @Watch('date')
  updateSelectedDate() {
    this.selected_date = this.date;
    const regDate = this.date;
    this.computedDate =
      regDate.substring(8, 10) +
      '-' +
      regDate.substring(5, 7) +
      '-' +
      regDate.substring(0, 4);
  }

  onDateChanged() {
    this.isDatePickerOpen = false;
    this.$emit('dateChanged', this.selected_date);
  }
}
