<template>
    <v-layout class="mb-3" style="cursor: pointer" @click="panToMarker(source.device)">
      <v-flex shrink>
        <v-layout column wrap>
          <v-img height="20" width="20" :src="source.icon" contain/>
        </v-layout>
      </v-flex>
      <v-flex shrink>
        <v-layout column class="mx-2" align-start>
          <span class="subheading">{{ source.text }}</span>
        </v-layout>
      </v-flex>
    </v-layout>
</template>

<script>
  export default {
    name: 'item-component',
    props: {
      index: { // index of current item
        type: Number
      },
      source: { // here is: {uid: 'unique_1', text: 'abc'}
        type: Object,
        default() {
          return {};
        }
      },
      panToMarker: {
        type: Function
      }
    }
  };
</script>