

























































import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import TabsInfoMixin from './TabsInfoMixin';
import { Global, DeviceStreams } from '@/store';
import Utils from '@/modules/Utils';
import API, { Types } from '@/modules/API';
import OverviewTab from '../information/floodSensorTabs/OverviewTab.vue';
import WaterLevelTab from '../information/floodSensorTabs/WaterLevelTab.vue';
import ControlTab from '../information/floodSensorTabs/ControlTab.vue';
import MQTT from '@/modules/MQTT';
import PropUtils from '@/modules/PropUtils';
import moment from 'moment';
import { FloodSensor } from '@/modules/Types';

@Component({
  components: {
    OverviewTab,
    WaterLevelTab,
    ControlTab
  }
})

export default class FloodSensorInfoTabs extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @Global.State('mqtt_version_by_class') mqtt_version_by_class;
  @DeviceStreams.Action('fetchAllStreamsForSingleDevice') fetchAllStreamsForSingleDevice;
  @DeviceStreams.State('deviceStreams') deviceStreams;
  @DeviceStreams.Mutation('setDeviceStreams') setDeviceStreams;

  metaDeviceData = {};
  loadingTabs = true;
  tab = 'tab-overview';
  water_level = 'N/A';
  water_height_trigger = 'No';
  top_float_status = 'N/A';
  bottom_float_status = 'N/A';
  power_level = 0;
  mqtt = MQTT.instance;
  power_msg_sent = false;
  power_stream_id = '';
  propsList = [];
  mqtt_subscribe_topic = '';
  mqtt_version = 3;
  utils = Utils;
  sensor_height = null;

  async mounted() {
    await this.deviceChanged();
  }

  setTabItem(tabIndex) {
    this.tab = tabIndex;
  }

  initMqtt() {
    this.mqtt.init();
    this.listenToMqtt();
  }

  listenToMqtt() {
    this.mqtt.listen(this.mqtt_subscribe_topic,
      async (msg, match) => {
        const mqtt_msg_data = this.mqtt.getMqttMsgData(msg, match, this.mqtt_version);
        if (mqtt_msg_data.stream_name === 'power') {
          this.checkPowerMsg(mqtt_msg_data.message, mqtt_msg_data.message_type);
        }else if (mqtt_msg_data.message_type === 'evt'){
          if (mqtt_msg_data.stream_name === 'water_mark'){
            this.updateWaterLevel({content: mqtt_msg_data.message, timestamp: mqtt_msg_data.timestamp}, false);
          }else if (mqtt_msg_data.stream_name === FloodSensor.ULRASONIC_SENSOR_TRIGGER) {
            this.updateWaterHeightTriggered(mqtt_msg_data.message);
          }else if (mqtt_msg_data.stream_name === FloodSensor.BOTTOM_FLOAT_TRIGGER){
            this.updateBottomFloatStatus({content: mqtt_msg_data.message, timestamp: mqtt_msg_data.timestamp}, false);
          }else if (mqtt_msg_data.stream_name === FloodSensor.TOP_FLOAT_TRIGGER){
            this.updateTopFloatStatus({content: mqtt_msg_data.message, timestamp: mqtt_msg_data.timestamp}, false);
          }
        }
      }
    );
  }

  checkPowerMsg(message, message_type){
    if (message_type === 'evt'){
      this.power_level = message;
      this.power_msg_sent = false;
    }else {
      this.power_msg_sent = true;
    }
  }

  @Watch('device')
  async deviceChanged() {
    this.setDeviceStreams([]);
    if (this.mqtt_subscribe_topic){
      this.mqtt.unlisten(this.mqtt_subscribe_topic);
    }
    this.mqtt_subscribe_topic = '';
    try {
      this.active = 'tab-overview';
      this.tab = 'tab-overview';
      this.loadingTabs = true;
      const metaDevice = await PropUtils.fetchSingleProperty(Types.DEVICES, 'meta.device', 'json', this.device.id);
      PropUtils.reorderMetaDevice(metaDevice);
      this.metaDeviceData = metaDevice;
      this.sensor_height = this.metaDeviceData.hasOwnProperty('sensor_height') && this.metaDeviceData['sensor_height'] !== ''
        ? +this.metaDeviceData['sensor_height']
        : null;
      if (this.mqtt_version_by_class.has(this.device.class_name)) {
        this.mqtt_version = this.mqtt_version_by_class.get(this.device.class_name).mqtt_version;
      }
      this.mqtt_subscribe_topic = this.mqtt.getSingleDeviceSubscribe(this.device.id, this.mqtt_version);
      
      await this.fetchStreams();
      this.initMqtt();
      this.loadingTabs = false;
    }
    catch (error) {
      console.log('ERROR while watch device:', error);
      this.loadingTabs = false;
    }
  }
  
  async fetchStreams() {
    this.setDeviceStreams([]);
    await this.fetchAllStreamsForSingleDevice(this.device.id);

    const water_level = this.getStream('water_mark', this.deviceStreams);
    if (water_level && water_level.value !== null) {
      this.updateWaterLevel(water_level, true);
    }

    const water_height_trigger = this.getStream(FloodSensor.ULRASONIC_SENSOR_TRIGGER, this.deviceStreams);
    if (water_height_trigger && water_height_trigger.value !== null) {
      this.updateWaterHeightTriggered(water_height_trigger.value);
    }

    const bottom_float = Utils.getStream(FloodSensor.BOTTOM_FLOAT_TRIGGER, this.deviceStreams);
    if (bottom_float && bottom_float.value !== null) {
      this.updateBottomFloatStatus(bottom_float, true);
    }

    const top_float = Utils.getStream(FloodSensor.TOP_FLOAT_TRIGGER, this.deviceStreams);
    if (top_float && top_float.value !== null) {
      this.updateTopFloatStatus(top_float, true);
    }

    const power = Utils.getStream('power', this.deviceStreams);
    if (power) {
      this.power_stream_id = power.id;
      this.power_level = power.value;
    }
  }

  async updateWaterLevel(water_level, check_modified){
    if (this.sensor_height){
      let water_level_modified = this.getNow(), water_level_value = null;
      if (check_modified){
        water_level_modified = await this.getStreamChangeTime(water_level);
        water_level_value = water_level.hasOwnProperty('value') && water_level.value || water_level;
      }else {
        if (water_level.timestamp) {
          water_level_modified = Utils.convertTimestamp(water_level.timestamp, 'DD.MM.YYYY HH:mm', this.projectTimezone);
        }
        water_level_value = water_level.content;
      }
      let height = (this.sensor_height - +water_level_value) / 10;
      height = height < 0 ? 0 : height;
      this.water_level = `${height} ${this.$t('cm')} ${this.$t('since')} ${water_level_modified}`;
    }
  }

  updateWaterHeightTriggered(triggered){
    this.water_height_trigger = triggered ? 'Yes' : 'No';
  }

  async updateBottomFloatStatus(bottom_float, check_modified){
    let bottom_float_modified = this.getNow(), bottom_float_value = null;
    if (check_modified) {
      bottom_float_modified = await this.getStreamChangeTime(bottom_float);
      bottom_float_value = bottom_float.hasOwnProperty('value') && bottom_float.value || bottom_float;
    }else {
      if (bottom_float.timestamp) {
        bottom_float_modified = Utils.convertTimestamp(bottom_float.timestamp, 'DD.MM.YYYY HH:mm', this.projectTimezone);
      }
      bottom_float_value = bottom_float.content;
    }
    this.bottom_float_status = `${+bottom_float_value === 1 ? this.$t('Wet') : this.$t('Dry')} ${this.$t('since')} ${bottom_float_modified}`;
  }

  async updateTopFloatStatus(top_float, check_modified){
    let top_float_modified = this.getNow(), top_float_value = null;
    if (check_modified) {
      top_float_modified = await this.getStreamChangeTime(top_float);
      top_float_value = top_float.hasOwnProperty('value') && top_float.value || top_float;
    }else {
      if (top_float.timestamp) {
        top_float_modified = Utils.convertTimestamp(top_float.timestamp, 'DD.MM.YYYY HH:mm', this.projectTimezone);
      }
      top_float_value = top_float.content;
    }

    this.top_float_status = `${+top_float_value === 1 ? this.$t('Wet') : this.$t('Dry')} ${this.$t('since')} ${top_float_modified}`;
  }

  getNow(){
    return Utils.convertTimestamp(moment().utcOffset(0).valueOf(), 'DD.MM.YYYY HH:mm', this.projectTimezone);
  }

  getStream(streamName, streams) {
    const stream = streams && streams.find((stream) => stream.name === streamName);
    return stream || null;
  }

  async getStreamChangeTime(stream) {
    if (!stream) return 'N/A';
    const response = await API.get(Types.DEVICES, `${this.device.id}/streams/${stream.id}/latest/`);
    if (!response['timestamp']) return 'N/A';
    return Utils.convertTimestamp(response.timestamp, 'DD.MM.YYYY HH:mm', this.projectTimezone);
  }

  beforeDestroy() {
    this.setDeviceStreams([]);
    this.mqtt.unlisten(this.mqtt_subscribe_topic);
  }
}
