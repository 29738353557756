import Vue from 'vue';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import router from '../../router';
import some from 'lodash/some';

import API, { Types } from '@/modules/API';
import Utils from '@/modules/Utils';
import vuex from '..';
import { i18n } from '@/main';
import PropUtils from '@/modules/PropUtils';
import store from 'store';

export interface Prop {
  objectType: Types;
  objectID: string;
  id: string;
  name: string;
  value: any;
  version: string;
  signature: string;
  zorder: number;
  classname: string;
}

@Module({ namespaced: true })
export default class Props extends VuexModule {
  company_properties = null;
  list: Prop[] = [];
  project_default_location: object = null;
  cabinet_meta_device_options: {[key: string]: string[]} = {};
  fixture_meta_device_options: {[key: string]: string[]} = {};
  
  @Action load(data) {
    vuex.commit('Props/flush');
    if (!data) return;
    const that = this;
    if (data.projectId !== 'allProjects') {
      return API.get(
        data.objectType || Types.PROJECTS,
        'properties',
        {},
        { itemID: data.objectID || data.projectId }
      ).then((res) => {
        const user = store.get('user') || {};
        if (res && res.results) {
          res.results.forEach((prop) => vuex.commit('Props/add', {...prop}));
          const readOnlyUsers =
            that['state'] && that['state']['list']
              ? PropUtils.getProperty(
                  that['state']['list'],
                  'dashboard.read_only_users'
                )
              : null;
          if (user.role === 'PR' || 
            user.role === 'CR' || 
            (
              readOnlyUsers &&
              user.username &&
              readOnlyUsers['value'] &&
              Array.isArray(readOnlyUsers['value']) &&
              readOnlyUsers['value'].includes(user.username)
            )
          ) {
            vuex.commit('Global/setIsReadonlyUser', true);
          }else {
            vuex.commit('Global/setIsReadonlyUser', false);
          }
          
          const forbiddenUsers =
            that['state'] && that['state']['list']
              ? PropUtils.getProperty(
                  that['state']['list'],
                  'dashboard.Forbidden_users'
                )
              : null;
          if (
            forbiddenUsers &&
            user.username &&
            forbiddenUsers['value'] &&
            Array.isArray(forbiddenUsers['value']) &&
            forbiddenUsers['value'].includes(user.username)
          ) {
            alert(`Access denied. Please contact an admin for clarifications.`);
            vuex.commit('Props/flush');
            vuex.commit('Projects/flush');
            vuex.commit('User/unsetProject');
            router.push('/projects');
          }
        }
      });
    }
  }

  @Action({ commit: 'edit' })
  async editProp({ _notify_msg, objectID, objectType, name, value }) {
    if (!objectType || !objectID || !name) return;
    if (!value) value = {};

    return await API.patch(objectType, `properties`, value, {
      itemID: objectID,
      query: { name }
    })
      .then((res) => {
        if (_notify_msg) {
          vuex.dispatch('Global/throwNotify', {
            type: 'success',
            title: `${i18n.t('Success')!}`,
            text: i18n.t(_notify_msg || 'Data successfully updated')
          });
        }

        return { ...res, objectType, objectID };
      })
      .catch((e) => {
        vuex.dispatch('Global/throwNotify', {
          type: 'error',
          title: `${i18n.t('Error')!}`,
          text: i18n.t(`Couldn't update data`)
        });
      });
  }

  @Action
  async fetchCompanyProperties(company_id) {
    const response = await API.get(Types.COMPANIES, `${company_id}/properties/`);
    const company_properties = response && response.results || [];
    vuex.commit('Props/setCompanyProperties', company_properties);
  }

  @Mutation
  setCompanyProperties(properties){
    this.company_properties = properties;
  }

  @Mutation
  updateCompanyProperty({property_name, value}){
    const property = this.company_properties.find((property) => property.name === property_name);
    if (property) {
      property.value = value;
    }
  }

  @Mutation
  setFixtureMetaDeviceOptions(fields_options){
    [...fields_options.entries()].forEach(([field, values]) => {
      vuex.commit('Props/setMetaDeviceOptions', {type: 'fixture_meta_device_options', field, values});
    });
    vuex.commit('Props/sortMetaDeviceFields', 'fixture_meta_device_options');
  }
 
  @Mutation
  setCabinetMetaDeviceOptions(fields_options){
    [...fields_options.entries()].forEach(([field, values]) => {
      vuex.commit('Props/setMetaDeviceOptions', {type: 'cabinet_meta_device_options', field, values});
    });
    vuex.commit('Props/sortMetaDeviceFields', 'cabinet_meta_device_options');
  }

  @Mutation
  setMetaDeviceOptions({type, field, values}){
    const string_values: string[] = values.filter((value) => value).map((value) => value.toString());
    if (string_values.length === 0) return;
    
    string_values.sort((value1, value2) => value1.localeCompare(value2, undefined, {numeric: true, sensitivity: 'base'}));

    let unique_values: string[] = [];

    if (this[type][field]){
      unique_values = [...new Set([...string_values, ...this[type][field]])];
    }else {
      unique_values = [...new Set(string_values)];
    }
    this[type][field] = unique_values;
  }

  @Mutation
  clearMetaDeviceOptions(){
    this.cabinet_meta_device_options = {};
    this.fixture_meta_device_options = {};
  }

  @Mutation
  sortMetaDeviceFields(type){
    Object.entries(this[type]).sort((a, b) => a[0].localeCompare(b[0]));
  }

  @Mutation
  defineProjectDefaultLocation(project_id){
    const default_location = { lat: 32.08816963237704, lng: 34.79698850793454};
    let project_default_location = null;

    if (project_id === 'allProjects') {
      project_default_location = default_location;
    }else {
      project_default_location = PropUtils.getProperty((vuex.state as any).Props.list, 'meta.default_location');
      project_default_location = project_default_location ? project_default_location.value : null;
      if (!Utils.isValidLocation(project_default_location)) {
        project_default_location = null;
      }else {
        try {
          project_default_location.lat = parseFloat(project_default_location.lat);
          project_default_location.lng = parseFloat(project_default_location.lng);
        }catch (e){
          project_default_location = null;
        }
      }
    }

    if (project_default_location){
      vuex.commit('Props/setProjectDefaultLocation', project_default_location);
    }else {
      vuex.commit('Props/setProjectDefaultLocation', default_location);
      console.log('invalid project default location');
    }
  }

  @Mutation
  setProjectDefaultLocation(location){
    this.project_default_location = location;
  }

  @Mutation flush() {
    this.list = [];
  }

  @Mutation add(data: Prop) {
    if (!data || !data.id || some(this.list, data)) return;
    if (data.name === 'dashboard.form_meta_device' || data.name === 'dashboard.form_meta_device_hub'){
      if (data.value && data.value.fields && data.value.fields.length){
        data.value.fields = data.value.fields.map((field) => ({...field, name: PropUtils.getFieldUpperToLower(field.name)}));
        data.value.fields.sort((field1, field2) => field1.name.localeCompare(field2.name, undefined, {numeric: true, sensitivity: 'base'}));
      }
    }else if (data.name === 'supported_field_names_of_units'){
      if (data.value && typeof data.value === 'object' && data.value !== null){
        const lowercase_fields = {};
        Object.entries(data.value).forEach(([field_name, value]) => {
          lowercase_fields[PropUtils.getFieldUpperToLower(field_name)] = value;
        });
        data.value = lowercase_fields;
      }
    }

    this.list.push(data);
  }

  @Mutation edit(data: Prop) {
    if (!data || !data.id) {
      return;
    }
    const index = Utils.find(this.list, data.id);

    if (index === -1) {
      this.list.push(data);
    } else {
      Vue.set(this.list, index, data);
    }
  }
}
