































































































import Component from 'vue-class-component';
import TabsInfoMixin from '../../devices/TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { Global, DeviceStreams } from '@/store';
import Utils from '@/modules/Utils';
import LineChart from '@/components/LineChart.vue';
import { FloodSensor } from '@/modules/Types';
import AreaAmchart from '@/components/AreaAmchart.vue';

@Component({
  components: {
    LineChart,
    AreaAmchart
  }
})

export default class WaterLevelTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
    @DeviceStreams.State('deviceStreams') deviceStreams;
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;

    // @ts-ignore
    @Prop() device;
    @Prop() metaDevice;

    filterEnum = {
        'Last Day': '1days',
        'Last 2 Days': '2days',
        'Last Week': '1weeks',
        'Last Month': '1months'
    };

    pickedPeriod = '1weeks';
    loading = true;
    sensor_height = 0;
    water_yaxis_max = 0;
    tooltip_formatter = null;
    yaxis_formatter = null;
    bottom_float_height = null;
    top_float_height = null;
    water_series = [];
    bottom_float_series = [{
        name: 'Bottom Float',
        data: null
    }];
    top_float_series = [{
        name: 'Top Float',
        data: null
    }];
    threshold = 0;
    show_sensor_data_error = false;
    data_error_msg = 'Opps, something went wrong with the data';

    mounted() {
        this.setSensorData();
        this.tooltip_formatter = (val) => +val === 0 ? this.$t('Dry') : this.$t('Wet');
        this.yaxis_formatter = (val) => +val === 0 ? this.$t('Dry') : +val === 1 ? this.$t('Wet') : '';

        if (!this.sensor_height) {
            this.show_sensor_data_error = true;
        }
      
        this.filterSelect(this.pickedPeriod);
    }

    getDatetime(timestamp){
        return Utils.convertTimestamp(timestamp, 'DD/MM/YYYY HH:mm:ss', this.projectTimezone);
    }

    filterSelect(value) {
        this.pickedPeriod = value || '1days';
        this.updateStreams();
    }

    get items(){
        return Object.entries(this.filterEnum).map(([text, period]) => ({text: this.$t(text), value: period}));
    }

    async updateStreams() {
        try {
            this.loading = true;
            if (!this.show_sensor_data_error) {
                await this.updateWaterLevelChartData();
            }
            await this.updateFloatsData();
            this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
    }

    async updateWaterLevelChartData(){
        const water_level_stream = Utils.getStream('water_mark', this.deviceStreams);
        let waterLevelHistory = [];
        if (water_level_stream) {
            waterLevelHistory = await Utils.fetchSingleStreamHistory({
                device: this.device,
                stream: water_level_stream,
                period: this.pickedPeriod
            });
            if (!waterLevelHistory) waterLevelHistory = [];
        }
        
        this.water_series = waterLevelHistory.map(([timestamp, value]) => {
            let height = (this.sensor_height - value) / 10;
            height = height < 0 ? 0 : height;
            return {
                date: timestamp,
                value: height
            };
        });
    }

    async updateFloatsData(){
        const bottom_float_trigger = Utils.getStream(FloodSensor.BOTTOM_FLOAT_TRIGGER, this.deviceStreams);
        const top_float_trigger = Utils.getStream(FloodSensor.TOP_FLOAT_TRIGGER, this.deviceStreams);
        let bottomFloatTriggerHistory = [], topFloatTriggerHistory = [];
        
        if (bottom_float_trigger) {
            bottomFloatTriggerHistory = await Utils.fetchSingleStreamHistory({
                device: this.device,
                stream: bottom_float_trigger,
                period: this.pickedPeriod
            });
            bottomFloatTriggerHistory = bottomFloatTriggerHistory ? bottomFloatTriggerHistory.map(([timestamp, value]) => [timestamp, +value]) : [];
        }

        this.bottom_float_series = [{ name: this.$t('Bottom Float'), data: bottomFloatTriggerHistory }];

        if (top_float_trigger){
            topFloatTriggerHistory = await Utils.fetchSingleStreamHistory({
                device: this.device,
                stream: top_float_trigger,
                period: this.pickedPeriod
            });
            topFloatTriggerHistory = topFloatTriggerHistory ? topFloatTriggerHistory.map(([timestamp, value]) => [timestamp, +value]) : [];
        }

        this.top_float_series = [{ name: this.$t('Top Float'), data: topFloatTriggerHistory }];
    }

    setSensorData(){
        this.threshold = this.metaDevice.hasOwnProperty('threshold') ? +this.metaDevice['threshold'] : 0;
        this.top_float_height = this.metaDevice.hasOwnProperty('top_float_height') && this.metaDevice['top_float_height'] !== ''
            ? `${this.metaDevice['top_float_height']} ${this.$t('cm')}`
            : null;
        this.bottom_float_height = this.metaDevice.hasOwnProperty('bottom_float_height') && this.metaDevice['bottom_float_height'] !== '' 
            ? `${this.metaDevice['bottom_float_height']} ${this.$t('cm')}` 
            : null;
        this.sensor_height = this.metaDevice.hasOwnProperty('sensor_height') && this.metaDevice['sensor_height'] !== '' 
            ? +this.metaDevice['sensor_height']
            : null;
    }
}
