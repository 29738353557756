import { Types } from '@/modules/API';
import { i18n } from '@/main';
import Utils from './Utils';
import vuex from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import API from './API';

export default class PropUtils {
  private static get properties() {
    return (vuex.state as any).Props.list;
  }

  static getPropIndex(objectType: Types, objectID, name) {
    let index = -1;
    this.properties.forEach(
      (p, i) =>
        p.objectType === objectType &&
        p.objectID === objectID &&
        p.name === name &&
        p.id !== 'virtual' &&
        (index = i)
    );
    return index;
  }

  static getProp(objectType: Types, objectID, name, value = {} as any): any {
    if (name.toString().indexOf(':') !== -1) name = name.split(':')[0];
    const prop = {
      pseudo: true,
      objectType,
      objectID,
      value,
      name
    };

    return (
      this.properties[this.getPropIndex(objectType, objectID, name)] || prop
    );
  }

  static getPropValue(
    objectType: Types,
    objectID,
    name,
    value = {} as any
  ): any {
    value = this.getProp(objectType, objectID, name, value).value || value;
    if (name.toString().indexOf(':') !== -1) {
      value = name
        .split(':')[1]
        .replace(' ', '')
        .split(',')
        .map((key) => value[key])
        .filter((v) => v)
        .join(', ');
    }

    return value;
  }

  static parseProperty(propName, device) {
    const deafultPropsValues = {
      'meta.location': { lat: '', lng: '' },
      'meta.commission': {
        date: 'none',
        hubConnection: 'unset',
        commissioner: 'none',
        commissioned: false
      },
      'meta.device': {},
      'meta.stream': {},
      'meta.schedule_curve': {},
      'meta.communication_monitoring': {},
      'meta.install_location': {
        gps_location: { lat: '', lng: '' },
        app_location: { lat: '', lng: '' },
        gis_location: { lat: '', lng: '' },
        manual_location: { lat: '', lng: '' }
      },
      'services.config': {},
      'device.hardwareid': { id: '' },
      'meta.hardware_ids': { eth0: '' },
      'device.schedule': device.class_name && Utils.hasCabinetClass(device.class_name) && Utils.hasCabinetClassWithNewFormat(device['device.version']) 
        ? { schedules: {}, days_schedules: {}} 
        : !device.class_name || Utils.hasCabinetClass(device.class_name) || Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']) || Utils.hasExtentionCardClass(device.class_name)
          ? {} 
          : { schedule: [] },
      'dashboard.connection_display': {
        enable_outputs: 1,
        enable_inputs: 1,
        display_outputs: 1,
        display_inputs: 1
      },
      'live_data': {},
      'meta.devices_schedule': {}
    };

    if (device[propName] === null || device[propName] === undefined) {
      return deafultPropsValues[propName];
    }

    try {
        if (typeof device[propName] === 'string'){
          if (propName === 'live_data') {
            device[propName] = device[propName].replaceAll(`'`, `"`);
          }
          return JSON.parse(device[propName]);
        }
        return JSON.parse(JSON.stringify(device[propName]));
    } catch (error) {
      console.log('%c' + `error in property ${propName} parsing for device ${device.id}`, 'color: red');
      console.log('error:', error);
      return deafultPropsValues[propName];
    }
  }

  static getProperty(properties, property_name) {
    return properties.find((result) => result.name === property_name);
  }

  static getProjectPropertyData(properties, property_name) {
    const property = PropUtils.getProperty(properties, property_name);
    if (!property) console.log(`There is no ${property_name} project property`);
    return property && property['value'] ? property['value'] : null;
  }

  static getDeviceStream(stream_name, streams_list) {
    return streams_list.find((stream) => stream.name === stream_name);
  }

  static getMetaDeviceField(meta_device, field_name) {
    if (!meta_device) return `${i18n.t(field_name)}: ${i18n.t('N/A')}`;
    return `${i18n.t(PropUtils.getFieldLowerToUpper(field_name))}: ${meta_device[field_name] || i18n.t('N/A')}`;
  }

  static getMetaDeviceFieldValue(meta_device, field_name) {
    return !meta_device ? '' : meta_device[field_name];
  }

  static getFieldLowerToUpper(field) {
    if (!field.includes('_') && !field.includes(' ')) return field.slice(0, 1).toUpperCase().concat(field.slice(1));
    let formatted_field = field.replaceAll('_', ' ');
    formatted_field = formatted_field
      .split(' ')
      .map((word) =>
        word
          .slice(0, 1)
          .toUpperCase()
          .concat(word.slice(1))
      )
      .join(' ');
    return formatted_field;
  }

  static getFieldUpperToLower(field){
    const formatted_field = field.toLowerCase();
    if (!field.includes(' ')) return formatted_field;
    return formatted_field.replaceAll(' ', '_');
  }

  static getFieldValue(meta_device, field){
    if (!meta_device || !field) return '';
    const lower = PropUtils.getFieldUpperToLower(field);

    return meta_device.hasOwnProperty(field) 
      ? meta_device[field]
      : meta_device.hasOwnProperty(lower)
        ? meta_device[lower]
        : '';
  }

  static getUppercaseFields(fields){
    return Object.entries(fields).filter(([field_name, value]) => field_name.includes(' ') || /[A-Z]/.test(field_name));
  }

  static getLowercaseFields(fields){
    return Object.entries(fields).filter(([field_name, value]) => !field_name.includes(' ') && !/[A-Z]/.test(field_name));
  }

  static getUppercaseFieldsList(fields){
    return Object.entries(fields).map(([field_name, options]) => ({
      name: i18n.t(PropUtils.getFieldLowerToUpper(field_name)),
      value: field_name,
      options
    })).sort((field_data1, field_data2) => field_data1.name.localeCompare(field_data2.name, undefined, {numeric: true, sensitivity: 'base'}));
  }

  static getUpdatedUppercaseFields(meta_device){
    const meta_device_copy = cloneDeep(meta_device);
    const lowercase_fields = PropUtils.getLowercaseFields(meta_device_copy);
    lowercase_fields.forEach(([field_name, value]) => {
      if (typeof value !== 'string') meta_device_copy[field_name] = value.toString();
      const uppercase_field = PropUtils.findMatchedUppercaseField(meta_device_copy, field_name);
      if (uppercase_field) meta_device_copy[uppercase_field] = meta_device_copy[field_name];
    });

    return meta_device_copy;
  }

  static reorderMetaDevice(meta_device){
    const uppercase_fields = PropUtils.getUppercaseFields(meta_device).map(([field, value]) => field);
    uppercase_fields.forEach((field_name) => {
      const lowercase_field = PropUtils.getFieldUpperToLower(field_name);
      if (!meta_device.hasOwnProperty(lowercase_field) || meta_device[field_name] !== meta_device[lowercase_field]){
        meta_device[lowercase_field] = meta_device[field_name];
      }
    });
  }

  static findMatchedUppercaseField(meta_device, lowercase_field){
    const uppercase_fields = PropUtils.getUppercaseFields(meta_device).map(([field, value]) => field);
    return uppercase_fields.find((field_name) => {
      const formatted_field = PropUtils.getFieldUpperToLower(field_name);
      return formatted_field === lowercase_field;
    });
  }

  static hasAstronomicalClock(device_id){
    let is_astronomical = false;
    const timezone = (vuex.state as any).Global.timezone;
    const today = timezone ? moment().tz(timezone).format('DD.MM.YYYY') : moment().format('DD.MM.YYYY');
    const device = vuex.getters['Reports/commissionedFixtures_map'].get(device_id);
    const device_schedule = PropUtils.parseProperty('device.schedule', device);

    if (
      Object.entries(device_schedule).length &&
      device_schedule.hasOwnProperty('cabinet_schedule') &&
      device_schedule.cabinet_schedule
    ){
      const meta_device = device['meta.device'];
      let related_cabinet = meta_device.cabinet_id && 
        vuex.getters['Reports/commissionedCabinets'].filter((cabinet) => 
          cabinet['meta.device'].cabinet_id && cabinet['meta.device'].cabinet_id === meta_device.cabinet_id
        );
      related_cabinet = related_cabinet.length && related_cabinet[0] || null;
      if (related_cabinet) {
        const schedule = PropUtils.parseProperty('meta.devices_schedule', related_cabinet);
        is_astronomical = Object.entries(schedule).length && schedule.is_astronomical || false;
      }
    }else {
      const schedule_curves = PropUtils.parseProperty('meta.schedule_curve', device);
      if (schedule_curves && schedule_curves.curves && schedule_curves.curves[today]) {
        const today_curve = schedule_curves.curves[today];
        is_astronomical = (today_curve && today_curve.astronomical) || false;
      }
    }

    return is_astronomical;
  }

  static async fetchSingleProperty(type, property_name, format, prefix){
    try {
      const property = await API.get(type, `${prefix}/properties/`, { name: property_name });
      return property && property.results && property.results[0] && property.results[0].value ? property.results[0].value : format === 'json' ? {} : '';
    }catch (e) {
      return format === 'json' ? {} : '';
    }
  }

  static getTeltonicaHardwareid(device){
    const meta_hardwareid = PropUtils.parseProperty('meta.hardware_ids', device);
    const device_hardwareid = PropUtils.parseProperty('device.hardwareid', device);
    
    return meta_hardwareid && meta_hardwareid.eth0 
      ? meta_hardwareid.eth0.replaceAll(':', '').toUpperCase()
      : device_hardwareid && device_hardwareid.id 
        ? device_hardwareid.id 
        : '';
  }
}
