








































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import json2xls from 'json2xls';
import VuePapaParse from 'vue-papa-parse';
import API, { Types } from '@/modules/API';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import cloneDeep from 'lodash/cloneDeep';
import { Global } from '@/store';

Vue.use(VuePapaParse);
@Component({})
export default class ExportData extends Vue {
  @Global.State('timezone') timezone;

  @Prop({ default: () => [] }) data;
  @Prop() color;
  @Prop() text;
  @Prop() sourceType: string;
  @Prop() sourceTitle?: string;
  @Prop() isGroups?: boolean;
  @Prop() isDisabled: boolean;
  @Prop() noParsing: boolean;
  @Prop() removeDate;

  formats = ['csv', 'xlsx', 'json'];
  exportedData = [];
  loading = false;
  headersMap = {
    'id': 'Cloud ID',
    'rssi': 'RSSI [dBm]',
    'power': 'Dali power',
    'name': 'Name',
    'class_name': 'Device class',
    'meta.category.category': 'Category',
    'sys___active': 'Active',
    'sys___connected': 'Connected',
    'sys___online': 'Online',
    'power_consumption': 'Last power consumption [Watt]',
    'lng': 'Longitude',
    'lat': 'Latitude'
  };

  async exportData(format) {
    this.loading = true;
    this.exportedData = [];
    await this.createExportedData();
    let filename;
    if (!this.removeDate) {
      filename = this.sourceTitle + ' - ' + Utils.getDateString();
    } else {
      filename = this.sourceTitle;
    }
    let data;

    switch (format) {
      case 'json':
        data = this.sourceTitle
          ? JSON.stringify(
              [{ source: this.sourceTitle }, ...this.exportedData],
              null,
              '\t'
            )
          : JSON.stringify(this.exportedData, null, '\t');
        break;

      case 'csv':
        // @ts-ignore
        data = this.$papa.unparse(this.exportedData);
        break;

      case 'xlsx':
        data = json2xls(this.exportedData);
        break;
    }

    this.download(filename, format, data);
    this.loading = false;
  }

  download(filename, format, data) {
    if (data) {
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8;' });
      const link = document.createElement('a');
      let url = null;
      if (format === 'xlsx') {
        url =
          'data:application/octet-stream;charset=utf-16le;base64,' + btoa(data);
      } else {
        url = URL.createObjectURL(blob);
      }
      link.setAttribute('href', url);
      link.setAttribute('download', filename + '.' + format);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async createExportedData() {
    if (this.noParsing) {
      this.exportedData = cloneDeep(this.data).map((device_data) => {
        const uppercase_fields = {};
        Object.entries(device_data).forEach(([field_name, value]) => uppercase_fields[PropUtils.getFieldLowerToUpper(field_name)] = value);
        return uppercase_fields;
      });
    } else {
      this.data.map((report) => {
        let dataToExport = {};
        try {
          const meta_device = report['meta.device'];
          const lastSysActiveDate = Utils.convertTimestamp(report.sys___active__modified * 1000, 'DD/MM/YYYY HH:mm', this.timezone);
          const info = {
            'Cabinet ID': meta_device.cabinet_id,
            'Circuit Number': meta_device.circuit_number,
            'Pole Number': meta_device.pole_number,
            'Status':
              report['sys___active'] === null
                ? 'N/A'
                : report.sys___active === 0
                  ? `Inactive since ${lastSysActiveDate}`
                    : report.sys___active === -1
                      ? 'Maintenance'
                      : report.sys___active === -3
                        ? 'In Installation'
                        : report.sys___active === -9
                          ? 'Fault In Care'
                          : 'Active'
          };
          if (this.isGroups) {
            dataToExport = {
              ID: report.id,
              Name: report.name,
              Location: Utils.deviceLocation(report),
              ...info
            };
          } else {
            const commissionProp = report['meta.commission'];
            const extendedInfo = {
              'Commission': commissionProp.commissioned,
              'Commission time': commissionProp.date,
              'Cabinet ID': report['meta.device'].cabinet_id,
              'Address': Utils.deviceLocation(report)
            };
            if (Utils.hasCabinetClass(report.class_name) || Utils.hasVirtualCabinetClass(report.class_name, report['meta.device'])){
              let mac_id = '';
              if (report.class_name.includes('sbc_cabinet')){
                mac_id = PropUtils.getTeltonicaHardwareid(report);
              }else {
                const hardwareid_prop = PropUtils.parseProperty('device.hardwareid', report);
                mac_id = hardwareid_prop && hardwareid_prop.id || '';
              }
              extendedInfo['CPU ID'] = '';
              extendedInfo['MAC'] = mac_id;
              extendedInfo['IMEI'] = '';
            }else {
              const hardwareidProp = PropUtils.parseProperty('device.hardwareid', report);
              if (hardwareidProp){
                extendedInfo['CPU ID'] = hardwareidProp.id ? hardwareidProp.id : '';
                extendedInfo['MAC'] = hardwareidProp.mac ? hardwareidProp.mac : '';
                extendedInfo['IMEI'] = hardwareidProp.imei ? hardwareidProp.imei : '';
              }
            }
            Object.keys(this.headersMap).forEach((key) => {
              dataToExport = {
                ...dataToExport,
                [this.headersMap[key]]: report[key]
              };
            });
            dataToExport = { ...dataToExport, ...info, ...extendedInfo };
          }
          this.exportedData.push(dataToExport);
        } catch (err) {
          console.log('error:', err);
        }
      });
    }
  }

  async getStreamChangeTime(deviceId, streamName) {
    const response = await API.get(
      Types.DEVICES,
      `${deviceId}/streams/${deviceId}.${streamName}/latest/`
    );
    if (!response['timestamp']) {
      return 'N/A';
    }
    const date = new Date(response['timestamp']);
    return Utils.getDateString(date);
  }
}
