



































import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Watch } from 'vue-property-decorator';
import TabsInfoMixin from './TabsInfoMixin';
import { Global } from '@/store';
import OverviewTab from '../information/weaterStationTabs/OverviewTab.vue';
import MQTT from '@/modules/MQTT';

@Component({
  components: {
    OverviewTab
  }
})

export default class WeatherStationInfoTabs extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @Global.State('mqtt_version_by_class') mqtt_version_by_class;

  loadingTabs = true;
  tab = 'tab-overview';
  mqtt_subscribe_topic = '';
  mqtt_version = 3;
  mqtt = MQTT.instance;
  mqtt_msg_data = null;

  async mounted() {
    await this.deviceChanged();
  }

  setTabItem(tabIndex) {
    this.tab = tabIndex;
  }

  @Watch('device')
  async deviceChanged() {
    if (this.mqtt_subscribe_topic){
      this.mqtt.unlisten(this.mqtt_subscribe_topic);
    }
    this.mqtt_subscribe_topic = '';
    try {
      this.active = 'tab-overview';
      this.tab = 'tab-overview';
      this.loadingTabs = true;
      if (this.mqtt_version_by_class.has(this.device.class_name)) {
        this.mqtt_version = this.mqtt_version_by_class.get(this.device.class_name).mqtt_version;
      }
      this.mqtt_subscribe_topic = this.mqtt.getSingleDeviceSubscribe(this.device.id, this.mqtt_version);
      this.initMqtt();
      this.loadingTabs = false;
    }
    catch (error) {
      console.log('ERROR while watch device:', error);
      this.loadingTabs = false;
    }
  }
  
  initMqtt() {
    this.mqtt.init();
    this.listenToMqtt();
  }

  listenToMqtt() {
    this.mqtt.listen(this.mqtt_subscribe_topic,
      (msg, match) => {
        this.mqtt_msg_data = this.mqtt.getMqttMsgData(msg, match, this.mqtt_version);
      }
    );
  }

}
